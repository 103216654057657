import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { passwordValidator } from "../../helpers/regex-data";
import { RegisterComponent } from "../../register/register.component";
import { SettingsService } from "../../services/settings.service";
import { NotificationService } from "../../services/notification.service";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent implements OnInit {
  @Input() modal;

  changePasswordForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group(
      {
        oldPassword: ["", [Validators.required]],
        newPassword: ["", [Validators.required, passwordValidator]],
        newPasswordConfirm: ["", [Validators.required, passwordValidator]]
      },
      {
        validator: RegisterComponent.checkMatchingPasswords("newPassword", "newPasswordConfirm")
      }
    );
  }

  updatePassword() {
    this.settingsService
      .updatePassword({
        oldPassword: this.changePasswordForm.controls["oldPassword"].value,
        newPassword: this.changePasswordForm.controls["newPassword"].value
      })
      .subscribe(
        () => {
          this.notificationService.success("Password successfully changed.");
          AuthService.clearStorageData();
          this.router.navigate(["/login"]);
        },
        (error) => this.notificationService.error(error)
      );
  }
}
