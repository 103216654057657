import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs/Rx";

@Injectable()
export class NotifyParentService {
  private subject = new Subject();

  constructor() {
  }

  sendData(data: string) {
    this.subject.next(data);
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }

}
