import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'secondsToTime'})

export class SecondsToTimePipe implements PipeTransform {

  /**
   * Formats seconds into a readable time format.
   *
   * @param {number} seconds the number of seconds
   * @returns {string} the formatted time
   */
  transform(seconds: number): string {
    return new Date(seconds * 1000).toISOString().substr(15, 4);
  }

}
