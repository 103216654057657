import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { TokenInterceptor } from "./services/token.interceptor";
import { AthletesComponent } from "./athletes/athletes.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReCaptchaModule } from "angular2-recaptcha";
import { RegisterComponent } from "./register/register.component";
import { LoginComponent } from "./login/login.component";
import { SettingsComponent } from "./settings/settings.component";
import { CodeGuard } from "./guards/code.guard";
import { NotUserGuard } from "./guards/notuser.guard";
import { UserGuard } from "./guards/user.guard";
import { EndpointService } from "./services/endpoint.service";
import { LoggedGuard } from "./guards/logged.guard";
import { AuthService } from "./services/auth.service";
import { FormatPipe } from "./pipes/format.pipe";
import { SuiModule } from "ng2-semantic-ui";
import { FileUploadModule } from "ng2-file-upload";
import { PlaylistsComponent } from "./playlists/playlists.component";
import { DragulaModule } from "ng2-dragula";
import { ExercisesComponent } from "./exercises/exercises.component";
import { WorkoutsComponent } from "./workouts/workouts.component";
import { SaveAthleteComponent } from "./athletes/save-athlete.component";
import { SaveExerciseComponent } from "./exercises/save-exercise.component";
import { SaveWorkoutComponent } from "./workouts/save-workout.component";
import { SecondsToTimePipe } from "./pipes/seconds-to-time.pipe";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ToasterModule } from "angular2-toaster";
import { NotificationService } from "./services/notification.service";
import { ResultsComponent } from "./results/results.component";
import { ChartModule } from "angular-highcharts";
import { NotifyParentService } from "./services/notify-parent.service";
import { ReportsComponent } from "./reports/reports.component";
import { VgCoreModule } from "videogular2/core";
import { VgControlsModule } from "videogular2/controls";
import { VgOverlayPlayModule } from "videogular2/overlay-play";
import { VgBufferingModule } from "videogular2/buffering";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ActivityDataComponent } from "./activity-data/activity-data.component";
import { GlobalPreferences } from "./helpers/global-data";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { MixpanelService } from "./services/mixpanel.service";
import { ResultNumberPipe } from "./pipes/result-number.pipe";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { UserActionComponent } from "./settings/user-action/user-action.component";
import { LocationActionComponent } from "./settings/location-action/location-action.component";
import { SelectLocationsComponent } from "./select-locations/select-locations.component";
import { AccountInfoComponent } from "./settings/account-info/account-info.component";
import { ChangePasswordComponent } from "./settings/change-password/change-password.component";
import { SafePipe } from "./pipes/safe.pipe";
import { OverviewComponent } from "./overview/overview.component";
import { ClinicAdminGuard } from "./guards/clinicadmin.guard";
import { AdminService } from "./services/admin.service";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ModalModule } from "ngx-bootstrap/modal";

import { SyncWorkoutComponent } from "./workouts/sync-workout/sync-workout.component";
import { SavePairedExerciseComponent } from "./exercises/save-paired-exercise/save-paired-exercise.component";
import { SyncExerciseComponent } from "./exercises/sync-exercise/sync-exercise.component";
import { MaterialModule } from "./material.module";
import { MessageService } from "./services/message-service";
import { AgilityRatingComponent } from "./athletes/agility-rating/agility-rating.component";
import { UnsavedChangesGuard } from "./guards/can-deactivate.guard";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { HighlightDirective } from "./directives/highlight.directive";

@NgModule({
  declarations: [
    AppComponent,
    AthletesComponent,
    RegisterComponent,
    LoginComponent,
    SettingsComponent,
    FormatPipe,
    SecondsToTimePipe,
    PlaylistsComponent,
    ExercisesComponent,
    WorkoutsComponent,
    SyncWorkoutComponent,
    SyncExerciseComponent,
    SaveAthleteComponent,
    SaveExerciseComponent,
    AgilityRatingComponent,
    SavePairedExerciseComponent,
    SaveWorkoutComponent,
    DashboardComponent,
    ResultsComponent,
    ReportsComponent,
    ActivityDataComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ResultNumberPipe,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    UserActionComponent,
    LocationActionComponent,
    SelectLocationsComponent,
    AccountInfoComponent,
    ChangePasswordComponent,
    FormatPipe,
    SafePipe,
    OverviewComponent,
    HighlightDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReCaptchaModule,
    FileUploadModule,
    SuiModule,
    DragulaModule,
    ChartModule,
    ToasterModule.forRoot(),
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    MaterialModule,
    MatTableModule,
    MatPaginatorModule
  ],
  providers: [
    CodeGuard,
    UserGuard,
    NotUserGuard,
    LoggedGuard,
    ClinicAdminGuard,
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    NotificationService,
    EndpointService,
    AuthService,
    NotifyParentService,
    GlobalPreferences,
    MixpanelService,
    AdminService,
    UnsavedChangesGuard,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
