import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { ResultsService } from "../services/results.service";
import { NotificationService } from "../services/notification.service";
import { DatePipe, DecimalPipe } from "@angular/common";
import { Chart } from "angular-highcharts";
import { colorArray } from "../helpers/chart-data";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs/Rx";
import { GlobalPreferences } from "../helpers/global-data";
import { MixpanelService } from "../services/mixpanel.service";

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
  styleUrls: ["./results.component.scss", "../styles.common.scss", "../athletes/athletes.component.scss"],
  providers: [ResultsService, DatePipe, DecimalPipe]
})
export class ResultsComponent implements OnInit, OnDestroy {
  resultsList = [];
  possibleFilters = [];
  selectedFilters = [];
  filtering = false;
  filters = [];
  currentPage = 0;
  tabular = true;
  colorArray = colorArray;
  loading = false;
  sub: Subscription;
  exporting = false;
  fromDate = 0;
  collapse: boolean[][] = [];

  static outputExerciseType(exerciseType: number): string {
    switch (exerciseType) {
      case 0:
        return "Count";
      case 1:
        return "React";
      case 2:
        return "Sequence";
      case 3:
        return "Agility";
      case 4:
        return "Vertical";
      default:
        return "Unknown exercise.";
    }
  }

  constructor(
    private resultsService: ResultsService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private route: ActivatedRoute,
    public global: GlobalPreferences,
    private elementRef: ElementRef,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.mixpanelService.track("results_screen", {});
    this.sub = this.route.queryParams.subscribe(
      (params) => {
        const exerciseId = params["exerciseId"];
        const exerciseName = params["exerciseName"];
        if (exerciseId !== undefined && exerciseName !== undefined) {
          this.updateFilters("exerciseId", exerciseId, exerciseName, 0);
        }
        this.getResultData();
        this.getPossibleFilters();
      },
      (error) => this.notificationService.error(error)
    );
  }

  getResultData(shouldFilter: boolean = false, page = null) {
    this.loading = true;
    const currentPage = page !== null && page !== undefined ? page : this.currentPage;
    if (shouldFilter) {
      this.resultsList = [];
      sessionStorage.setItem(
        "resultFilters",
        JSON.stringify({
          selectedFilters: this.selectedFilters,
          filters: this.filters
        })
      );
    }

    this.resultsService.getResultData(currentPage, 5, this.constructFilterString(true), this.fromDate).subscribe(
      (response) => {
        for (let i = 0; i < response.length; i++) {
          this.collapse[i] = [];
          const canLoadLength = response[i].results.length;
          if (canLoadLength > 0) {
            if (response[i].results[0]["@type"] === "paired") {
              let firstScore;
              if (response[i].results[0].result1 && response[i].results[0].result2) {
                if (response[i].results[0].result1.date < response[i].results[0].result2.date) {
                  firstScore = response[i].results[0].result1.score;

                  response[i].results[0].result2.color = response[i].results[0].result2.percentChange < 0 ? -1 : 1;
                } else {
                  firstScore = response[i].results[0].result2.score;

                  response[i].results[0].result1.color = response[i].results[0].result1.percentChange < 0 ? -1 : 1;
                }
                response[i].results[0].result1.side = response[i].results[0].result1.shortenedName.replace(
                  new RegExp(response[i].results[0].result2.shortenedName.replace(new RegExp(" ", "g"), "|"), "g"),
                  ""
                );
                response[i].results[0].result2.side = response[i].results[0].result2.shortenedName.replace(
                  new RegExp(response[i].results[0].result1.shortenedName.replace(new RegExp(" ", "g"), "|"), "g"),
                  ""
                );
              } else if (response[i].results[0].result1) {
                firstScore = response[i].results[0].result1.score;
              } else {
                firstScore = response[i].results[0].result2.score;
              }
              for (let j = 0; j < canLoadLength; j++) {
                this.collapse[i][j] = true;
                if (response[i].results[j].result1 && response[i].results[j].result2) {
                  response[i].results[j].result2.color = response[i].results[j].percentChange < 0 ? -1 : 1;

                  response[i].results[j].color = response[i].results[j].result2.color;
                  response[i].results[j].result1.side = response[i].results[j].result1.shortenedName.replace(
                    new RegExp(response[i].results[j].result2.shortenedName.replace(new RegExp(" ", "g"), "|"), "g"),
                    ""
                  );
                  response[i].results[j].result2.side = response[i].results[j].result2.shortenedName.replace(
                    new RegExp(response[i].results[j].result1.shortenedName.replace(new RegExp(" ", "g"), "|"), "g"),
                    ""
                  );
                }
              }

              response[i].overview = this.fromDate;
              response[i].canLoad = canLoadLength % 20 === 0 && canLoadLength !== 0;
              response[i].scoreUnit = response[i].scoreType === "Correct touches" ? "touches" : "seconds";
              const pairedCharts = this.updatePairedCharts(response[i], response[i].results, true);
              response[i].chart1 = pairedCharts.chart1;
              response[i].chart2 = pairedCharts.chart2;
            } else {
              this.collapse[i][0] = true;
              for (let j = 1; j < canLoadLength; j++) {
                this.collapse[i][j] = true;

                response[i].results[j].color = response[i].results[j].percentChange < 0 ? -1 : 1;
              }
              response[i].overview = this.fromDate;
              response[i].canLoad = canLoadLength % 20 === 0 && canLoadLength !== 0;
              response[i].scoreUnit = response[i].scoreType === "Correct touches" ? "touches" : "seconds";
              response[i].chart = this.updateChart(response[i], response[i].results, true);
            }
          }
        }
        this.resultsList = response;
        this.loading = false;
      },
      (error) => {
        this.notificationService.error(error);
        this.loading = false;
      }
    );
  }

  updateSelectedResults(data, initialize: boolean = false) {
    data.loading = true;
    const noOfResults = data.results.length;
    let page;
    page = noOfResults > 0 ? Math.floor(noOfResults / 20) : 0;
    this.resultsService
      .getResults(
        page,
        20,
        data.overview,
        data.exerciseId,
        data.endType,
        data.endValue,
        data.inputType,
        data.delay,
        data.flash,
        data.promptType,
        data.name,
        this.constructFilterString(true)
      )
      .subscribe(
        (response) => {
          if (response.length > 0) {
            if (data.paired) {
              let i = 0;
              let firstResult;
              if (noOfResults > 0) {
                firstResult = data.results[0].result1 ? data.results[0].result1 : data.results[0].result2;
                i = data.results.length;
                data.results = data.results.concat(response);
              } else {
                data.results = response;
                if (response[0].result1) {
                  firstResult = response[0].result1;
                } else if (response[0].result2) {
                  firstResult = response[0].result2;
                }
                i = 1;
              }
              for (i; i < data.results.length; i++) {
                if (data.results[i].result1) {
                  firstResult.color = data.results[i].result1.percentChange < 0 ? -1 : 1;
                }
                if (data.results[i].result2) {
                  firstResult.color = data.results[i].result2.percentChange < 0 ? -1 : 1;
                }
              }
              const canLoadLength = response.length;
              data.canLoad = canLoadLength % 20 === 0 && canLoadLength !== 0;
              data.scoreUnit = data.scoreType === "Correct touches" ? "touches" : "seconds";
              const pairedCharts = this.updatePairedCharts(data, response, initialize);
              data.chart1 = pairedCharts.chart1;
              data.chart2 = pairedCharts.chart2;
            } else {
              let i = 0;
              let firstScore;
              if (noOfResults > 0) {
                firstScore = data.results[0].score;
                i = data.results.length;
                data.results = data.results.concat(response);
              } else {
                data.results = response;
                if (response[0]) {
                  firstScore = response[0].score;
                }
                i = 1;
              }
              for (i; i < data.results.length; i++) {
                data.results[i].color = data.results[i].percentChange < 0 ? -1 : 1;
              }
              const canLoadLength = response.length;
              data.canLoad = canLoadLength % 20 === 0 && canLoadLength !== 0;
              data.scoreUnit = data.scoreType === "Correct touches" ? "touches" : "seconds";
              data.chart = this.updateChart(data, response, initialize);
            }
          }
          data.loading = false;
        },
        (error) => {
          this.notificationService.error(error);
          data.loading = false;
        }
      );
  }

  updateResults() {
    this.currentPage = 0;
    this.getResultData(false, 0);
  }

  updateChart(data, results, initialize: boolean) {
    let series = [];
    let xAxis = [];
    if (!initialize) {
      series = data.chart.options.series;
      xAxis = data.chart.options.xAxis[0].categories;
    }
    let found;
    for (let i = 0; i < results.length; i++) {
      // const formatType = data.overview === 0 ? 'short' : data.overview === 1 ? 'M/d/yy, h a' : 'mediumDate';
      const formatType = "MMM d y, h:mm a";
      const date = this.datePipe.transform(results[i].date, formatType);
      const dateIndex = xAxis.indexOf(date);
      if (dateIndex > -1) {
        found = false;
        for (const element of series) {
          if (element.name === results[i].firstName + " " + results[i].lastName) {
            element.data[0] = element.data[0] != -1 ? (element.data[0] + results[i].score) / 2 : results[i].score;
            found = true;
            break;
          }
        }
      } else {
        xAxis.unshift(date);
        found = false;
        for (const element of series) {
          if (element.name === results[i].firstName + " " + results[i].lastName) {
            element.data.unshift(results[i].score);
            found = true;
          } else {
            element.data.unshift(-1);
          }
        }
      }

      if (!found) {
        const seriesArray = [results[i].score];
        // for (let i = 1; i < xAxis.length; i++) {
        //     seriesArray.push(0);
        // }
        series.push({
          name: results[i].firstName + " " + results[i].lastName,
          data: seriesArray,
          color: this.colorArray[series.length]
        });
      }
    }

    let minValue;

    series.forEach((serie) => {
      serie.data.forEach((result) => {
        if (!minValue && result > 0) {
          minValue = result;
        }
        if (result > 0 && result < minValue) {
          minValue = result;
        }
      });
    });

    minValue = minValue || 0;

    for (const element of series) {
      for (let i = 0; i < element.data.length; i++) {
        if (element.data[i] === -1) {
          element.data[i] = 0;
        }
      }
    }

    const shortDates = [xAxis[0]];
    for (let i = 1; i < xAxis.length; i++) {
      shortDates[i] = xAxis[i];
      if (
        new Date(xAxis[i - 1]).getMonth() == new Date(xAxis[i]).getMonth() &&
        new Date(xAxis[i - 1]).getDate() == new Date(xAxis[i]).getDate()
      ) {
        shortDates[i] = this.datePipe.transform(xAxis[i], "h:mm a");
      }
    }
    return new Chart({
      chart: {
        type: "scatter",
        events: {
          load: function () {
            const self = this;
            setTimeout(function () {
              self.reflow();
            }, 100);
          }
        }
      },
      title: { text: "" },
      credits: { enabled: false },
      legend: { enabled: false },
      tooltip: {
        pointFormat: data.scoreType + "<br/><b>{point.y:,.3f}</b> " + data.scoreUnit,
        headerFormat: ""
      },
      xAxis: [{ categories: shortDates }],
      series: series,
      yAxis: { title: { text: data.scoreType }, min: minValue }
    });
  }

  updatePairedCharts(data, results, initialize: boolean): any {
    let series1 = [];
    let xAxis1 = [];
    let chartTitle1;
    const toAddInSecondChart = [];
    if (!initialize) {
      series1 = data.chart1.options.series;
      xAxis1 = data.chart1.options.xAxis[0].categories;
    }
    let found1;
    for (let i = 0; i < results.length; i++) {
      if (results[i].result1 == null) {
        continue;
      }
      const formatType = "MMM d y, h:mm a";
      chartTitle1 = results[i].result1.shortenedName;
      // const formatType = data.overview === 0 ? 'short' : data.overview === 1 ? 'M/d/yy, h a' : 'mediumDate';
      const date = this.datePipe.transform(results[i].date, formatType);
      if (results[i].result2 == null) {
        toAddInSecondChart.push({
          name: results[i].firstName + " " + results[i].lastName,
          color: this.colorArray[series1.length],
          date: date
        });
      }
      const dateIndex = xAxis1.indexOf(date);
      if (dateIndex > -1) {
        found1 = false;
        for (const element of series1) {
          if (element.name === results[i].firstName + " " + results[i].lastName) {
            element.data[0] = element.data[0] != -1 ? (element.data[0] + results[i].result1.score) / 2 : results[i].result1.score;
            found1 = true;
            break;
          }
        }
      } else {
        xAxis1.unshift(date);
        found1 = false;
        for (const element of series1) {
          if (element.name === results[i].firstName + " " + results[i].lastName) {
            element.data.unshift(results[i].result1.score);
            found1 = true;
          } else {
            element.data.unshift(-1);
          }
        }
      }

      if (!found1) {
        const seriesArray = [results[i].result1.score];
        // for (let i = 1; i < xAxis1.length; i++) {
        //     seriesArray.push(0);
        // }
        series1.push({
          name: results[i].firstName + " " + results[i].lastName,
          data: seriesArray,
          color: this.colorArray[series1.length]
        });
      }
    }

    let minValue1;

    series1.forEach((serie) => {
      serie.data.forEach((result) => {
        if (!minValue1 && result > 0) {
          minValue1 = result;
        }
        if (result > 0 && result < minValue1) {
          minValue1 = result;
        }
      });
    });

    minValue1 = minValue1 || 0;

    for (const element of series1) {
      for (let i = 0; i < element.data.length; i++) {
        if (element.data[i] === -1) {
          element.data[i] = 0;
        }
      }
    }

    let series2 = [];
    let xAxis2 = [];
    let chartTitle2;
    if (!initialize) {
      series2 = data.chart2.options.series;
      xAxis2 = data.chart2.options.xAxis[0].categories;
    }
    let found2;
    for (let i = 0; i < results.length; i++) {
      if (results[i].result2 === null) {
        continue;
      }
      chartTitle2 = results[i].result2.shortenedName;
      // const formatType = data.overview === 0 ? 'short' : data.overview === 1 ? 'M/d/yy, h a' : 'mediumDate';
      const formatType = "MMM d y, h:mm a";
      const date = this.datePipe.transform(results[i].date, formatType);
      if (results[i].result1 == null) {
        const chart1DateIndex = xAxis1.indexOf(date);
        if (chart1DateIndex > -1) {
          found2 = false;
          for (const element of series1) {
            if (element.name === results[i].firstName + " " + results[i].lastName) {
              found2 = true;
              break;
            }
          }
          if (!found2) {
            const seriesArray = [];
            // for (let i = 0; i < xAxis1.length; i++) {
            //     seriesArray.push(0);
            // }
            series1.push({
              name: results[i].firstName + " " + results[i].lastName,
              data: seriesArray,
              color: this.colorArray[series1.length]
            });
          }
        } else {
          found2 = false;
          let positionToInsert = null;
          for (let i = 0; i < xAxis1.length; i++) {
            if (new Date(xAxis1[i]) > new Date(date)) {
              xAxis1.splice(i, 0, date);
              positionToInsert = i;
              found2 = true;
              break;
            }
          }
          if (!found2) {
            positionToInsert = xAxis1.length;
            xAxis1.push(date);
          }
          found2 = false;
          for (const element of series1) {
            element.data.splice(positionToInsert, 0, 0);
            if (element.name === results[i].firstName + " " + results[i].lastName) {
              found2 = true;
            }
          }
          if (!found2) {
            const seriesArray = [];
            // for (let i = 0; i < xAxis1.length; i++) {
            //     seriesArray.push(0);
            // }
            series1.push({
              name: results[i].firstName + " " + results[i].lastName,
              data: seriesArray,
              color: this.colorArray[series1.length]
            });
          }
        }
      }
      const dateIndex = xAxis2.indexOf(date);
      if (dateIndex > -1) {
        found2 = false;
        for (const element of series2) {
          if (element.name === results[i].firstName + " " + results[i].lastName) {
            element.data[0] = element.data[0] != -1 ? (element.data[0] + results[i].result2.score) / 2 : results[i].result2.score;
            found2 = true;
            break;
          }
        }
      } else {
        xAxis2.unshift(date);
        found2 = false;
        for (const element of series2) {
          if (element.name === results[i].firstName + " " + results[i].lastName) {
            element.data.unshift(results[i].result2.score);
            found2 = true;
          } else {
            element.data.unshift(-1);
          }
        }
      }

      if (!found2) {
        const name = results[i].firstName + " " + results[i].lastName;
        let color = null;
        for (const series of series1) {
          if (series.name === name) {
            color = series.color;
            break;
          }
        }
        const seriesArray = [results[i].result2.score];
        // for (let i = 1; i < xAxis2.length; i++) {
        //     seriesArray.push(0);
        // }
        series2.push({
          name: name,
          data: seriesArray,
          color: color === null ? this.colorArray[series2.length + series1.length] : color
        });
      }
    }

    for (const toAdd of toAddInSecondChart) {
      const chart2DateIndex = xAxis2.indexOf(toAdd.date);
      if (chart2DateIndex > -1) {
        found2 = false;
        for (const element of series2) {
          if (element.name === toAdd.name) {
            found2 = true;
            break;
          }
        }
        if (!found2) {
          const seriesArray = [];
          // for (let i = 0; i < xAxis2.length; i++) {
          //     seriesArray.push(0);
          // }
          series2.push({
            name: toAdd.name,
            data: seriesArray,
            color: toAdd.color
          });
        }
      } else {
        found2 = false;
        let positionToInsert = null;
        for (let i = 0; i < xAxis2.length; i++) {
          if (new Date(xAxis2[i]) > new Date(toAdd.date)) {
            xAxis2.splice(i, 0, toAdd.date);
            positionToInsert = i;
            found2 = true;
            break;
          }
        }
        if (!found2) {
          positionToInsert = xAxis2.length;
          xAxis2.push(toAdd.date);
        }
        found2 = false;
        for (const element of series2) {
          element.data.splice(positionToInsert, 0, 0);
          if (element.name === toAdd.name) {
            found2 = true;
          }
        }
        if (!found2) {
          const seriesArray = [];
          // for (let i = 0; i < xAxis2.length; i++) {
          //     seriesArray.push(0);
          // }
          series2.push({
            name: toAdd.name,
            data: seriesArray,
            color: toAdd.color,
            dashStyle: "longdash"
          });
        }
      }
    }

    let minValue2;

    series2.forEach((serie) => {
      serie.data.forEach((result) => {
        if (!minValue2 && result > 0) {
          minValue2 = result;
        }
        if (result > 0 && result < minValue2) {
          minValue2 = result;
        }
      });
    });
    minValue2 = minValue2 || 0;

    for (const element of series2) {
      for (let i = 0; i < element.data.length; i++) {
        if (element.data[i] === -1) {
          element.data[i] = 0;
        }
      }
    }

    const shortDates1 = [xAxis1[0]];
    const shortDates2 = [xAxis2[0]];

    for (let i = 1; i < xAxis1.length; i++) {
      shortDates1[i] = xAxis1[i];
      if (
        new Date(xAxis1[i - 1]).getMonth() == new Date(xAxis1[i]).getMonth() &&
        new Date(xAxis1[i - 1]).getDate() == new Date(xAxis1[i]).getDate()
      ) {
        shortDates1[i] = this.datePipe.transform(xAxis1[i], "h:mm a");
      }
    }

    for (let i = 1; i < xAxis2.length; i++) {
      shortDates2[i] = xAxis2[i];
      if (
        new Date(xAxis2[i - 1]).getMonth() == new Date(xAxis2[i]).getMonth() &&
        new Date(xAxis2[i - 1]).getDate() == new Date(xAxis2[i]).getDate()
      ) {
        shortDates2[i] = this.datePipe.transform(xAxis2[i], "h:mm a");
      }
    }

    return {
      chart1: new Chart({
        chart: {
          className: "paired-exercise",
          type: "scatter",
          events: {
            load: function () {
              const self = this;

              setTimeout(function () {
                self.reflow();
              }, 100);
            }
          }
        },
        title: { text: chartTitle1 },
        credits: { enabled: false },
        legend: { enabled: false },
        tooltip: { pointFormat: "{series.name}<br/>" + data.scoreType + "<br/><b>{point.y:,.2f}</b> " + data.scoreUnit },
        xAxis: [{ categories: shortDates1, type: "category" }],
        series: series1,
        yAxis: { title: { text: data.scoreType }, min: minValue1 }
      }),
      chart2: new Chart({
        chart: {
          className: "paired-exercise",
          type: "scatter",
          events: {
            load: function () {
              const self = this;

              setTimeout(function () {
                self.reflow();
              }, 100);
            }
          }
        },
        title: { text: chartTitle2 },
        credits: { enabled: false },
        legend: { enabled: false },
        tooltip: { pointFormat: "{series.name}<br/>" + data.scoreType + "<br/><b>{point.y:,.2f}</b> " + data.scoreUnit },
        xAxis: [
          {
            categories: shortDates2,
            type: "category",
            labels: {
              style: {
                whiteSpace: "nowrap"
              }
            }
          }
        ],
        series: series2,
        yAxis: { title: { text: data.scoreType }, min: minValue2 }
      })
    };
  }

  checkSelectedFilters() {
    this.selectedFilters.forEach((filterId) => {
      for (const el in this.possibleFilters) {
        if (el) {
          let foundExercise = false;

          this.possibleFilters[el].forEach((possibleFilter) => {
            if (possibleFilter.id == filterId) {
              for (const filteredExercise of possibleFilter.elements || possibleFilter.positions) {
                if (this.selectedFilters.indexOf(filteredExercise.id) != -1) {
                  foundExercise = true;
                  break;
                }
              }

              if (!foundExercise) {
                this.selectedFilters.splice(this.selectedFilters.indexOf(filterId), 1);
                return;
              }
            }
          });
        }
      }
    });
  }

  updateFilters(key: string, value: any, name: string, type: number) {
    const filter = { key: key, value: value, name: name, type: type };

    for (let i = 0; i < this.filters.length; ++i) {
      if (this.filters[i] && this.filters[i].value === filter.value) {
        this.filters.splice(i, 1);
        this.selectedFilters.splice(this.selectedFilters.indexOf(value), 1);
        this.checkSelectedFilters();
        return;
      }
    }
    this.selectedFilters.push(name === "No Team" ? name : value);
    this.filters.push(filter);
  }

  updateSelectedFilters(value: string) {
    const index = this.selectedFilters.indexOf(value);
    index == -1 ? this.selectedFilters.push(value) : this.selectedFilters.splice(index, 1);
  }

  selectAllFilters(key: string, array, onlyPush: boolean) {
    if (onlyPush) {
      for (const element of array) {
        this.selectedFilters.push(element.id);
        this.filters.push({ key: key, value: element.id, name: element.name, type: 0 });
      }
    } else {
      for (const element of array) {
        for (let i = 0; i < this.filters.length; ++i) {
          if (this.filters[i].value === element.id) {
            this.filters.splice(i, 1);
            this.selectedFilters.splice(this.selectedFilters.indexOf(element.id), 1);
            break;
          }
        }
      }
    }
    this.getResultData(true);
  }

  constructFilterString(existingFilters: boolean): string {
    let filterString = `&`;
    if (!existingFilters) filterString = `?`;
    for (const filter of this.filters) {
      filterString += `${filter.key}${filter.type}=${filter.value}&`;
    }
    return filterString.substring(0, filterString.length - 1);
  }

  getPossibleFilters() {
    this.resultsService.getPossibleFilters().subscribe(
      (response) => {
        this.possibleFilters = response;
      },
      (error) => this.notificationService.error(error)
    );
  }

  massExport() {
    this.exporting = true;
    this.resultsService.massExport(this.constructFilterString(false), this.fromDate).subscribe(
      (response) => {
        for (let i = 0; i < response.length; i++) {
          if (response[i] != "") {
            this.downloadReport(
              ResultsComponent.outputExerciseType(i) + " Full " + this.datePipe.transform(new Date(), "MMM d yy"),
              response[i]
            );
          }
        }
        this.exporting = false;
      },
      (error) => {
        this.notificationService.error(error);
        this.exporting = false;
      }
    );
  }

  exportResultData(data: any) {
    data.exporting = true;
    const dataCopy = Object.assign({}, data);
    if (data.paired) {
      dataCopy.chart1 = null;
      dataCopy.chart2 = null;
      dataCopy["@type"] = "paired";
    } else {
      dataCopy.chart = null;
      dataCopy["@type"] = "normal";
    }
    this.resultsService.exportResultData(dataCopy).subscribe(
      (response) => {
        this.downloadReport(data.name.substring(0, 20) + " " + this.datePipe.transform(new Date(), "MMM d yy"), response.body);
        data.exporting = false;
      },
      (error) => {
        this.notificationService.error(error);
        data.exporting = false;
      }
    );
  }

  downloadReport(filename: string, data: string) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display:none");
    const url = window.URL.createObjectURL(new Blob([data], { type: "octet/stream" }));
    a.href = url;
    a.download = filename + ".csv";
    a.click();
    window.URL.revokeObjectURL(url);
  }

  deleteResult(id: string) {
    //todo: complete result deletion
    this.resultsService.deleteResult(id).subscribe(
      () => {
        this.notificationService.success("Result deleted successfully.");
      },
      (error) => this.notificationService.error(error)
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  clearFilters() {
    this.getResultData(true, 0);
    this.selectedFilters = [];
    this.filters = [];
  }
}
