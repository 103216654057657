import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { PlaylistsService } from "../services/playlists.service";
import { ActivatedRoute } from "@angular/router";
import { DragulaService } from "ng2-dragula";
import { ModalTemplate, SuiModalService, TemplateModalConfig } from "ng2-semantic-ui";
import { SharedExerciseService } from "../services/shared-exercise.service";
import { SharedPlaylistService } from "../services/shared-playlist.service";
import { delayArray, flashArray, possibleRestTime, possibleSets } from "../helpers/exercise-data";
import { NotificationService } from "../services/notification.service";
import { FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { SaveExerciseComponent } from "../exercises/save-exercise.component";
import { SharedAthleteService } from "../services/shared-athlete.service";
import { GlobalPreferences } from "../helpers/global-data";
import { MixpanelService } from "../services/mixpanel.service";
import { Subject, Observable } from "rxjs";
import { CanComponentDeactivate, UnsavedChangesGuard } from "../guards/can-deactivate.guard";
import { ExerciseService } from "../services/exercise.service";

type NewPlaylistProps = {
  creationDate?: number;
  type: number;
  restTime: number;
  circuitSets: number;
  exerciseList: any[];
};

@Component({
  selector: "app-playlists",
  templateUrl: "./playlists.component.html",
  styleUrls: ["./playlists.component.scss", "../styles.common.scss"],
  providers: [PlaylistsService, SharedExerciseService, SharedPlaylistService, SharedAthleteService, ExerciseService]
})
export class PlaylistsComponent implements OnInit, OnDestroy, UnsavedChangesGuard {
  @ViewChild("athleteModalTemplate")
  athleteModalTemplate: ModalTemplate<string, string, string>;
  @ViewChild("exerciseModalTemplate")
  exerciseModalTemplate: ModalTemplate<string, string, string>;
  @ViewChild("exerciseEditTemplate")
  exerciseEditTemplate: ModalTemplate<string, string, string>;
  @ViewChild("confirmModalTemplate")
  confirmModalTemplate: ModalTemplate<string, string, string>;
  @ViewChild("deleteModalTemplate")
  deleteModalTemplate: ModalTemplate<string, string, string>;
  @ViewChild("deleteExerciseModalTemplate")
  deleteExerciseModalTemplate: ModalTemplate<string, string, string>;
  playlistData = [];
  athleteToDelete;
  selectedPlaylist = null;
  copySelectedPlaylist = null;

  possibleSets = possibleSets;
  possibleRestTime = possibleRestTime;
  possibleTypes = ["Count", "React", "Sequence", "Agility", "Vertical"];
  selectedType;
  playlistType = 0;
  playlistCircuitSets = 1;
  playlistRestTime;
  athletesSelected = [];
  addAthletesSelected = [];
  selectedIndex;
  newPlaylist: NewPlaylistProps = {
    type: 0,
    restTime: this.possibleRestTime[0],
    circuitSets: 1,
    exerciseList: []
  };
  exerciseList = [];
  copyExerciseList = [];
  exercisesSelected = [];
  addExercisesSelected = [];
  modal;
  react = [];
  dontReact = [];
  reactSensors = [];
  sequenceSensors = [];
  agilitySensors = [];
  categoryInput;
  categoryOptions = [];
  exerciseCategories = [];
  isolatedReact = 0;
  delayArray = delayArray;
  flashArray = flashArray;
  modifiedSettings = [];
  filteredExerciseList = [];
  filteredAthleteList = [];
  modified = false;
  updateSelected = PlaylistsComponent.updateSelected;
  updateAthletesSelected = PlaylistsComponent.updateAthletesSelected;
  editing = true;
  exerciseEditForm: FormGroup;
  selectedExercise;
  toggle = true;
  canLoadPlaylistData = false;
  canLoadExerciseList = false;
  canLoadSelectedPlaylist = false;
  canLoadAthleteList = false;
  searchAddExercise = "";
  searchAddAthlete = "";
  loading = false;
  playlistsLoaded;
  searching = false;
  filterTimeout;
  automated = false;
  circuit = false;
  firstName: string;
  lastName: string;
  notSaved = false;

  exerciseCategoriesNew = [];
  exercisesNew = [];

  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

  static updateSelected(value, array) {
    const newValue = {
      categoryId: value.categoryId,
      id: value.id,
      exerciseUUID: value.uuid,
      name: value.name,
      paired: value.paired,
      sets: 1
    };
    let index = -1;
    const foundValue = array.some((el, i) => {
      if (el.id === value.id) {
        index = i;
        return true;
      }
      return false;
    });
    index >= 0 && foundValue ? array.splice(index, 1) : array.push(newValue);
  }

  static updateAthletesSelected(value, array) {
    const index = array.indexOf(value);
    index >= 0 ? array.splice(index, 1) : array.push(value);
  }

  constructor(
    private playlistService: PlaylistsService,
    private modalService: SuiModalService,
    private route: ActivatedRoute,
    private dragulaService: DragulaService,
    private sharedExerciseService: SharedExerciseService,
    private sharedPlaylistService: SharedPlaylistService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private sharedAthleteService: SharedAthleteService,
    public global: GlobalPreferences,
    private mixpanelService: MixpanelService,
    private exerciseService: ExerciseService
  ) {
    dragulaService.setOptions("exerciseBag", {});
    dragulaService.drop.subscribe(() => (this.modified = true));
  }

  ngOnInit() {
    this.mixpanelService.track("playlist_screen", {});
    this.getAthleteData();
  }

  checkIfSelected(id) {
    return this.exercisesSelected.some((el) => el.id === id);
  }

  canDeactivate(component: CanComponentDeactivate): boolean | Observable<boolean> | Promise<boolean> {
    if (this.modified) {
      this.openConfirmModal(this.selectedPlaylist, this.selectedIndex);

      return this.navigateAwaySelection$;
    }
    return true;
  }

  choose(choice: boolean): void {
    this.navigateAwaySelection$.next(choice);
  }

  getAthleteData() {
    this.playlistsLoaded = false;
    const playlistDataLength = this.playlistData.length;
    const page = playlistDataLength > 0 ? Math.floor(playlistDataLength / 20) : 0;
    const size = 20;
    this.playlistService
      .getPlaylistData(page, size, this.searchAddAthlete, this.searchAddAthlete != "" ? this.playlistData[this.selectedIndex].id : "")
      .subscribe(
        (playlistData) => {
          const dataLength = playlistData.length;
          if (this.searchAddAthlete == "") {
            this.playlistData = playlistDataLength > 0 ? this.playlistData.concat(playlistData) : playlistData;
            this.canLoadPlaylistData = dataLength % 20 === 0 && dataLength !== 0;
            if (page === 0 && this.playlistData.length > 0) {
              this.updateSelectedPlaylist(this.playlistData[0].playlist, 0);
            }
          } else {
            this.canLoadAthleteList = dataLength % 20 === 0 && dataLength !== 0;
            for (const athlete of playlistData) {
              this.filteredAthleteList.push(athlete);
            }
          }
          this.playlistsLoaded = true;
        },
        (error) => {
          this.notificationService.error(error);
          this.playlistsLoaded = true;
        }
      );
  }

  getExerciseData() {
    const lengthToAdd = this.editing ? this.selectedPlaylist.exerciseList.length : this.newPlaylist.exerciseList.length;
    const length = this.exerciseList.length + lengthToAdd;
    const page = length > 0 ? Math.floor(length / 20) : 0;
    const size = 20;
    const exercisesToFilter = this.editing ? this.selectedPlaylist.exerciseList.map((value) => value.exerciseId) : [];
    this.sharedPlaylistService.getExerciseDetails(page, size, exercisesToFilter, this.searchAddExercise).subscribe(
      (response) => {
        if (this.searchAddExercise == "") {
          for (const exercise of response) {
            this.exerciseList.push(exercise);
          }
          this.filteredExerciseList = Object.assign([], this.exerciseList);
        } else {
          for (const exercise of response) {
            this.filteredExerciseList.push(exercise);
          }
        }
        const exercises = response.length;
        this.canLoadExerciseList = exercises % 20 === 0 && exercises !== 0;
      },
      (error) => this.notificationService.error(error)
    );
  }

  checkModifiedSets(event) {
    if (event) {
      this.modified = true;
    }
  }

  onChangeAutomated() {
    this.modified = true;
    if (this.automated) {
      this.selectedPlaylist.type = 2;
      this.playlistType = 2;
    } else {
      this.selectedPlaylist.type = 0;
      this.playlistType = 0;
    }
  }

  onChangeCircuit() {
    this.modified = true;
    if (this.circuit) {
      this.selectedPlaylist.type = 1;
      this.playlistType = 1;
    } else {
      this.selectedPlaylist.type = 2;
      this.playlistType = 2;
    }
  }

  updateSelectedPlaylist(playlist, index: number) {
    this.selectedIndex = index;
    this.filteredAthleteList = Object.assign([], this.filterCurrentlySelected());
    this.selectedPlaylist = playlist;
    if (!this.selectedPlaylist) return;
    this.copySelectedPlaylist = JSON.parse(JSON.stringify(this.selectedPlaylist));

    if (this.selectedPlaylist.type == 1) {
      this.automated = true;
      this.circuit = true;
      this.playlistType = 1;
    } else if (this.selectedPlaylist.type == 2) {
      this.automated = true;
      this.circuit = false;
      this.playlistType = 2;
    } else {
      this.automated = false;
      this.circuit = false;
      this.playlistType = 0;
    }
    const playlistLength = this.selectedPlaylist.length;
    this.canLoadSelectedPlaylist = playlistLength % 20 === 0 && playlistLength !== 0;
    this.playlistCircuitSets = playlist.circuitSets;
    this.playlistRestTime = playlist.restTime;
    // this.playlistType = playlist.type;
    this.exerciseList = [];
    this.getExerciseData();
  }

  filterCurrentlySelected() {
    return this.playlistData.slice(0, this.selectedIndex).concat(this.playlistData.slice(this.selectedIndex + 1));
  }

  filterAthleteList(value) {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(() => {
      if (value.length === 0) {
        const filteredSelected = this.filterCurrentlySelected();
        this.filteredAthleteList = Object.assign([], filteredSelected);
        const length = filteredSelected.length;
        this.canLoadAthleteList = length % 20 === 0 && length !== 0;
        return;
      }
      if (value.length < 2) {
        return;
      }
      this.searching = true;
      this.playlistService.getPlaylistData(0, 20, value, this.playlistData[this.selectedIndex].id).subscribe(
        (response: any) => {
          this.filteredAthleteList = response;
          const length = response.length;
          this.canLoadAthleteList = length % 20 === 0 && length !== 0;
          this.searching = false;
        },
        (error) => {
          this.notificationService.error(error);
          this.searching = false;
        }
      );
    }, 350);
  }

  isAlreadyAdded(exercise: any): boolean {
    for (const addedExercise of this.addExercisesSelected) {
      if (addedExercise.id === exercise.id) {
        return true;
      }
    }
    return false;
  }

  isAlreadyAddedOld(exercise: any): boolean {
    for (const addedExercise of this.addExercisesSelected) {
      if (addedExercise.exerciseId === exercise.exerciseId) {
        return true;
      }
    }
    return false;
  }

  filterExerciseList(value) {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(() => {
      if (value.length === 0) {
        this.filteredExerciseList = Object.assign([], this.exerciseList);
        const length = this.exerciseList.length;
        this.canLoadExerciseList = length % 20 === 0 && length !== 0;
        return;
      }
      if (value.length < 2) {
        return;
      }
      this.searching = true;
      this.sharedPlaylistService
        .getExerciseDetails(
          0,
          20,
          this.selectedPlaylist.exerciseList.map((value) => value.exerciseId),
          value
        )
        .subscribe(
          (response: any) => {
            this.filteredExerciseList = response;
            const length = response.length;
            this.canLoadExerciseList = length % 20 === 0 && length !== 0;
            this.searching = false;
          },
          (error) => {
            this.notificationService.error(error);
            this.searching = false;
          }
        );
    }, 350);
  }

  selectAllAthletes() {
    this.athletesSelected = [];
    for (const athlete of this.playlistData) {
      this.athletesSelected.push(athlete.id);
    }
  }

  onChangeAutomatedNew() {
    if (this.automated) {
      this.newPlaylist.type = 2;
    } else {
      this.newPlaylist.type = 0;
    }
  }

  onChangeCircuitNew() {
    if (this.circuit) {
      this.newPlaylist.type = 1;
    } else {
      this.newPlaylist.type = 2;
    }
  }

  // onClick() {
  //     console.log('new p : ', this.newPlaylist)
  //     console.log('ath ' , this.athletesSelected, ' sel index ', this.selectedIndex);
  // }

  createMode() {
    this.athletesSelected = [];
    this.exercisesSelected = [];
    this.newPlaylist = {
      type: 0,
      restTime: this.possibleRestTime[0],
      circuitSets: 1,
      exerciseList: []
    };
    this.automated = false;
    this.circuit = false;
    this.playlistCircuitSets = 1;
    this.playlistRestTime = this.possibleRestTime[0];
    this.editing = false;
    this.exerciseList = [];
    this.getExerciseData();
  }

  editMode() {
    this.athletesSelected = [];
    this.editing = true;
    this.exerciseList = [];
    this.getExerciseData();
  }

  openExerciseModal() {
    this.addExercisesSelected = [];
    this.searchAddExercise = "";
    this.filteredExerciseList = Object.assign([], this.exerciseList);
    const config = new TemplateModalConfig<string, string, string>(this.exerciseModalTemplate);
    config.mustScroll = true;
    this.modalService.open(config);
    this.getCategories();
  }

  getCategories() {
    this.sharedExerciseService.getCategories().subscribe(
      (response) => {
        this.exerciseCategoriesNew = response;
        this.exercisesNew = [];
        // this.categoriesLoaded = true;
        this.exerciseCategoriesNew.forEach((value, index) => {
          this.getExercisesByCategory(value, index);
        });
      },
      (error) => {
        this.notificationService.error(error);
        // this.categoriesLoaded = true;
      }
    );
  }

  // onClickeeer() {
  //     console.log(this.addExercisesSelected);
  //     console.log(this.selectedPlaylist);
  //     console.log(this.newPlaylist);
  //     console.log(this.exercisesNew);

  // }

  getExercisesByCategory(category, index: number) {
    const exercisesForCategory = this.exercisesNew[category.id];
    const page = exercisesForCategory && exercisesForCategory.length > 0 ? Math.floor(exercisesForCategory.length / 20) : 0;
    const size = 20;

    this.exerciseService.findAllByCategory(category.id, page, size, true, "0").subscribe(
      (response: any) => {
        this.exercisesNew[category.id] =
          exercisesForCategory && exercisesForCategory.length > 0 ? this.exercisesNew[category.id].concat(response) : response;
        const index = this.exerciseCategories.indexOf(category);
        if (this.exercisesNew[category.id].length === 0) {
          this.exerciseCategories.splice(index, 1);
        }
        const exerciseLength = response.length;
        // this.canLoadExercises[category.id] = exerciseLength % 20 === 0 && exerciseLength !== 0;
      },
      (error) => this.notificationService.error(error)
    );
    if (index === this.exerciseCategories.length - 1) {
      this.loading = false;
    }
  }

  // initialClick() {
  //     console.log(this.firstName);
  // }

  checkModified(playlist, index, event, firstName, lastName) {
    if (this.modified) {
      event.preventDefault();
      this.openConfirmModal(playlist, index);
      return;
    }
    this.firstName = firstName;
    this.lastName = lastName;
    this.switchSelectedAthlete(playlist, index);
  }

  updateModified() {
    if (!this.modified && this.newPlaylist.exerciseList.length > 0) this.modified = true;
  }

  openConfirmDeleteExerciseModal() {
    const config = new TemplateModalConfig<string, string, string>(this.deleteExerciseModalTemplate);
    config.isClosable = false;
    this.modalService.open(config).onApprove(() => {
      // console.log('approve;');

      for (const exercise of this.exercisesSelected) {
        this.removeExercise(exercise);
      }
      this.exercisesSelected = [];
      this.savePlaylist();
    });
  }

  openConfirmModal(playlist, index) {
    const config = new TemplateModalConfig<string, string, string>(this.confirmModalTemplate);
    config.isClosable = false;
    this.modalService.open(config).onApprove(() => {
      this.notSaved = true;
      this.switchSelectedAthlete(playlist, index);
    });
  }

  switchSelectedAthlete(playlist, index) {
    this.modified = false;
    this.modifiedSettings = [];
    // this.toggleTab();
    if (this.notSaved) {
      this.playlistData[this.selectedIndex].playlist = this.copySelectedPlaylist;
    }
    this.updateSelectedPlaylist(playlist, index);
  }

  toggleTab() {
    this.toggle = !this.toggle;
  }

  // onClickSave() {
  //     console.log(this.selectedPlaylist);
  //     console.log(this.exercisesSelected);
  //     console.log(this.playlistData);
  //     console.log(this.notSaved);
  // }

  openEditModal(exerciseId: string) {
    this.sharedExerciseService.getCategories().subscribe(
      (categories) => {
        this.exerciseCategories = categories;
        this.categoryOptions = categories.map((category) => category.name);
        const playlistId = this.selectedPlaylist.id;

        this.playlistService.getExercise(exerciseId, playlistId).subscribe(
          (response: any) => {
            const exercise = response.exercise;
            const exerciseType = exercise.exerciseType;
            this.selectedExercise = {
              type: exerciseType,
              name: exercise.name,
              active: exercise.active,
              videoUrl: exercise.videoUrl
            };
            this.initExerciseForm(exerciseType, this.settingsAlreadyModified(response.id) || response.exerciseSettings);
            this.selectedType = this.possibleTypes[this.selectedExercise.type];
            const categoryId = exercise.categoryId;
            for (const category of categories) {
              if (category.id === categoryId) {
                this.categoryInput = category.name;
                break;
              }
            }
            const config = new TemplateModalConfig<string, string, string>(this.exerciseEditTemplate);
            config.mustScroll = true;
            this.modal = this.modalService.open(config);
          },
          (error) => this.notificationService.error(error)
        );
      },
      (error) => this.notificationService.error(error)
    );
  }

  initExerciseForm(type: number, settings: any) {
    const validators: ValidatorFn[] = [SaveExerciseComponent.startValueValidation() as ValidatorFn];
    switch (type) {
      case 0:
        this.exerciseEditForm = this.formBuilder.group({
          countType: [settings.countType as number, Validators.required]
        });
        break;
      case 1:
        let isolatedReact = 0;
        if (settings.inactiveSensors !== null) {
          Object.assign(this.reactSensors, settings.inactiveSensors);
          isolatedReact = 1;
        }
        this.exerciseEditForm = this.formBuilder.group({
          delay: [settings.delay as number, Validators.required],
          flashTime: [settings.flashTime as number, Validators.required],
          cognitiveReact: [settings.cognitiveReact as number, Validators.required],
          isolatedReact: [isolatedReact as number, Validators.required]
        });
        Object.assign(this.dontReact, settings.dontReactTo);
        Object.assign(this.react, settings.reactTo);
        validators.push(SaveExerciseComponent.neurocognitiveValidation(this.react, this.dontReact, this.reactSensors) as ValidatorFn);
        validators.push(SaveExerciseComponent.activeSensorValidation(this.reactSensors) as ValidatorFn);
        break;
      case 2:
        this.exerciseEditForm = this.formBuilder.group({
          delay: [settings.delay as number, Validators.required],
          flashTime: [settings.flashTime as number, Validators.required]
        });
        Object.assign(this.sequenceSensors, settings.sequence);
        break;
      case 3:
        this.exerciseEditForm = this.formBuilder.group({
          delay: [settings.delay as number, Validators.required],
          agilityCustomType: [settings.agilityCustomType as number, Validators.required]
        });
        Object.assign(this.agilitySensors, settings.sequence);
        validators.push(SaveExerciseComponent.arrowValidation(this.agilitySensors) as ValidatorFn);
        break;
      default:
        this.notificationService.error("Invalid exercise type.");
        break;
    }
    this.exerciseEditForm.addControl("startType", this.formBuilder.control(settings.startType, Validators.required));
    this.exerciseEditForm.addControl("startValue", this.formBuilder.control(settings.startValue));
    this.exerciseEditForm.addControl("endType", this.formBuilder.control(settings.endType, Validators.required));
    this.exerciseEditForm.addControl("endValue", this.formBuilder.control(settings.endValue, Validators.required));
    this.exerciseEditForm.setValidators(validators);
  }

  settingsAlreadyModified(exerciseId: number) {
    for (const settings of this.modifiedSettings) {
      if (settings.exerciseId === exerciseId) {
        return settings;
      }
    }
    return false;
  }

  saveExercise() {
    this.modified = true;
    const exerciseSettings: any = this.getExerciseSettings(this.selectedExercise.type);
    exerciseSettings.startType = this.exerciseEditForm.controls["startType"].value;
    exerciseSettings.startValue = this.exerciseEditForm.controls["startValue"].value;
    exerciseSettings.endType = this.exerciseEditForm.controls["endType"].value;
    exerciseSettings.endValue = this.exerciseEditForm.controls["endValue"].value;
    this.modifiedSettings.push(exerciseSettings);
    this.modal.approve("");
  }

  getExerciseSettings(exerciseType: number): object {
    switch (exerciseType) {
      case 0:
        return { countType: this.exerciseEditForm.controls["countType"].value };
      case 1:
        return {
          delay: this.exerciseEditForm.controls["delay"].value,
          flashTime: this.exerciseEditForm.controls["flashTime"].value,
          cognitiveReact: this.exerciseEditForm.controls["cognitiveReact"].value,
          dontReactTo: this.dontReact,
          inactiveSensors: this.reactSensors,
          reactTo: this.react
        };
      case 2:
        return {
          delay: this.exerciseEditForm.controls["delay"].value,
          flashTime: this.exerciseEditForm.controls["flashTime"].value,
          sequence: this.sequenceSensors
        };
      case 3:
        return {
          delay: this.exerciseEditForm.controls["delay"].value,
          agilityCustomType: this.exerciseEditForm.controls["agilityCustomType"].value,
          sequence: this.agilitySensors
        };
      default:
        this.notificationService.error("Invalid exercise type.");
        this.exerciseEditForm.controls["type"].setValue(0);
        return;
    }
  }

  removeSelectedPlaylist() {
    const config = new TemplateModalConfig<string, string, string>(this.deleteExerciseModalTemplate);
    config.isClosable = false;
    this.copyExerciseList = JSON.parse(JSON.stringify(this.selectedPlaylist.exerciseList));
    this.modalService.open(config).onApprove(() => {
      for (const exercise of this.copyExerciseList) {
        this.removeExercise(exercise);
      }
      this.savePlaylist();
    });
  }

  // deletePlaylist() {
  //     console.log('dasdas');
  // }

  removeSelectedExercises() {
    const { exerciseList } = this.newPlaylist;
    const exercisesSelectedArr = this.exercisesSelected.slice();
    if (this.selectedPlaylist.exerciseList.length === 1) {
      this.openConfirmDeleteExerciseModal();
    } else {
      for (let i = 0; i < this.exercisesSelected.length; i++) {
        this.newPlaylist.exerciseList.splice(
          exerciseList.findIndex((item) => item.id === this.exercisesSelected[i].id),
          1
        );
        exercisesSelectedArr.splice(i, 1);
      }

      this.exercisesSelected = exercisesSelectedArr;
    }
  }

  removeExercise(selected) {
    for (let i = 0; i < this.selectedPlaylist.exerciseList.length; i++) {
      if (selected.id === this.selectedPlaylist.exerciseList[i].id) {
        this.selectedPlaylist.exerciseList.splice(i, 1);
      }
    }

    // const array = this.editing ? this.selectedPlaylist.exerciseList : this.newPlaylist.exerciseList;
    // this.modified = true;
    // for (let i = 0; i < this.modifiedSettings.length; i++) {
    //   if (this.modifiedSettings[i].exerciseId === exercise.exerciseId) {
    //     this.modifiedSettings.splice(i, 1);
    //     break;
    //   }
    // }
    // let indexToPush;
    // const nameToCompare = exercise.name;
    // for (let i = 0; i < this.exerciseList.length; i++) {
    //   if (nameToCompare < this.exerciseList[i].name) {
    //     indexToPush = i;
    //     break;
    //   }
    // }
    // indexToPush === undefined ? this.exerciseList.push(exercise) : this.exerciseList.splice(indexToPush, 0, exercise);
    // array.splice(array.indexOf(exercise), 1);
  }

  savePlaylist() {
    this.loading = true;
    this.selectedPlaylist.type = this.playlistType;
    this.selectedPlaylist.restTime = this.playlistRestTime;
    this.selectedPlaylist.circuitSets = this.playlistCircuitSets;

    if (this.playlistType === 1) {
      for (const exerciseItem of this.selectedPlaylist.exerciseList) {
        exerciseItem.sets = this.playlistCircuitSets;
      }
    } else if (this.playlistType === 0) {
      for (const exerciseItem of this.selectedPlaylist.exerciseList) {
        exerciseItem.sets = 1;
      }
      this.selectedPlaylist.restTime = this.possibleRestTime[0];
      this.selectedPlaylist.circuitSets = 1;
    }
    // console.log(this.selectedPlaylist);
    // console.log(this.modifiedSettings);
    this.playlistService
      .savePlaylist({
        playlist: this.selectedPlaylist,
        settings: this.modifiedSettings
      })
      .subscribe(
        () => {
          this.loading = false;
          this.modified = false;
          this.notificationService.success("Playlist successfully saved.");
          this.updateSelectedPlaylist(this.selectedPlaylist, this.selectedIndex);
          this.toggleTab();
        },
        (error) => {
          this.notificationService.error(error);
          this.loading = false;
        }
      );
  }

  initAthleteModal() {
    this.searchAddAthlete = "";
    this.canLoadAthleteList = this.canLoadPlaylistData;
    this.filteredAthleteList = Object.assign([], this.filterCurrentlySelected());
    this.modalService.open(new TemplateModalConfig<string, string, string>(this.athleteModalTemplate));
    this.athletesSelected = [];
  }

  hasAthletes() {
    if (this.newPlaylist.exerciseList.length == 0 && this.athletesSelected.length === 0) {
      return true;
    } else if (this.newPlaylist.exerciseList.length > 0 && this.athletesSelected.length > 0) {
      return true;
    }
    return false;
  }

  // onNewClick() {
  //     console.log(this.newPlaylist.exerciseList.length);
  // }

  createNewPlaylist() {
    this.newPlaylist.creationDate = Date.now();
    this.newPlaylist.circuitSets = this.playlistCircuitSets;
    if (this.newPlaylist.type === 1) {
      const newExerciseList = [];
      for (const exerciseItem of this.newPlaylist.exerciseList) {
        newExerciseList.push({
          exerciseId: exerciseItem.exerciseId,
          exerciseUUID: exerciseItem.exerciseUUID,
          name: exerciseItem.name,
          sets: this.newPlaylist.circuitSets,
          paired: exerciseItem.paired
        });
      }
      this.newPlaylist.exerciseList = newExerciseList;
      // console.log('newp ', this.newPlaylist)
    }
    for (let i = 0; i < this.newPlaylist.exerciseList.length; i++) {
      this.newPlaylist.exerciseList[i].index = i;
    }
    // console.log(this.newPlaylist);
    this.clonePlaylist(this.newPlaylist);
    this.editing = true;
    this.firstName = undefined;
    this.lastName = undefined;
    this.toggleTab();
  }

  // onClickSecond() {
  //     console.log(this.newPlaylist);
  //     console.log(this.athletesSelected);
  // }

  clonePlaylist(playlist, manualClone: boolean = false) {
    this.loading = true;
    const athleteList = manualClone ? this.addAthletesSelected : this.athletesSelected;

    this.playlistService
      .clonePlaylist({
        playlist: playlist,
        athleteList: athleteList
      })
      .subscribe(
        () => {
          this.loading = false;
          this.notificationService.success("Playlist successfully added.");
          this.modified = false;
          for (const athlete of this.playlistData) {
            if (athleteList.indexOf(athlete.id) !== -1) {
              athlete.playlist.exerciseList = playlist.exerciseList;
              athlete.playlist.type = playlist.type;
              athlete.playlist.restTime = playlist.restTime;
              athlete.playlist.circuitSets = playlist.circuitSets;
              athlete.playlist.lastModified = Date.now();
              if (athlete.playlist.id === this.selectedPlaylist.id) {
                this.updateSelectedPlaylist(athlete.playlist, this.selectedIndex);
              }
            }
          }
        },
        (error) => {
          this.notificationService.error(error);
          this.loading = false;
        }
      );
  }

  addExercises() {
    this.modified = true;
    this.editing
      ? (this.selectedPlaylist.exerciseList = this.selectedPlaylist.exerciseList.concat(this.addExercisesSelected))
      : (this.newPlaylist.exerciseList = this.newPlaylist.exerciseList.concat(this.addExercisesSelected));
    this.exerciseList = this.exerciseList.filter((el) => this.addExercisesSelected.indexOf(el) < 0);
  }

  addExercisesOld() {
    this.modified = true;
    this.editing
      ? (this.selectedPlaylist.exerciseList = this.selectedPlaylist.exerciseList.concat(this.addExercisesSelected))
      : (this.newPlaylist.exerciseList = this.newPlaylist.exerciseList.concat(this.addExercisesSelected));
    this.exerciseList = this.exerciseList.filter((el) => this.addExercisesSelected.indexOf(el) < 0);
  }

  resetReact() {
    this.reactSensors.splice(0, this.reactSensors.length);
    this.exerciseEditForm.controls["isolatedReact"].updateValueAndValidity();
  }

  setReact(color: number, react: boolean) {
    let array;
    let otherArray;

    if (react) {
      array = this.react;
      otherArray = this.dontReact;
    } else {
      array = this.dontReact;
      otherArray = this.react;
    }

    let index = array.indexOf(color);
    index > -1 ? array.splice(index, 1) : array.push(color);

    index = otherArray.indexOf(color);
    if (index > -1) {
      otherArray.splice(index, 1);
    }
    this.exerciseEditForm.controls["cognitiveReact"].updateValueAndValidity();
  }

  setSensor(sensor: number) {
    const index = this.reactSensors.indexOf(sensor);
    index > -1 ? this.reactSensors.splice(index, 1) : this.reactSensors.push(sensor);

    this.exerciseEditForm.controls["isolatedReact"].updateValueAndValidity();
  }

  resetSequence() {
    this.sequenceSensors.splice(0, this.sequenceSensors.length);
    this.exerciseEditForm.controls["delay"].updateValueAndValidity();
  }

  addSequence(sensor: number) {
    const length = this.sequenceSensors.length;
    if (length < 26) {
      this.sequenceSensors.push(sensor);
      this.exerciseEditForm.controls["delay"].updateValueAndValidity();
    }
  }

  addArrows(arrow: number) {
    if (this.exerciseEditForm.controls["agilityCustomType"].value === 0) {
      const length = this.agilitySensors.length;
      if (length < 26) {
        this.agilitySensors.push(arrow);
        this.exerciseEditForm.controls["agilityCustomType"].updateValueAndValidity();
      }
    } else {
      const index = this.agilitySensors.indexOf(arrow);
      index > -1 ? this.agilitySensors.splice(index, 1) : this.agilitySensors.push(arrow);

      this.exerciseEditForm.controls["agilityCustomType"].updateValueAndValidity();
    }
  }

  arrowsColorCondition(arrow: number) {
    return this.exerciseEditForm.controls["agilityCustomType"].value === 0 || this.agilitySensors.indexOf(arrow) === -1;
  }

  openDeleteModal(athlete) {
    this.athleteToDelete = {
      id: athlete.id,
      name: athlete.firstName + " " + athlete.lastName
    };
    this.modalService.open(new TemplateModalConfig<string, string, string>(this.deleteModalTemplate));
  }

  deleteAthlete(athleteId: string) {
    this.sharedAthleteService.deleteAthlete(athleteId).subscribe(
      () => {
        this.playlistData.splice(this.findAthleteIndex(athleteId), 1);
      },
      (error) => this.notificationService.error(error)
    );
  }

  findAthleteIndex(athleteId: string): number {
    for (let i = 0; i < this.playlistData.length; i++) {
      if (athleteId === this.playlistData[i].id) {
        return i;
      }
    }
    return -1;
  }

  ngOnDestroy() {
    this.dragulaService.destroy("exerciseBag");
  }
}
