import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EndpointService} from './endpoint.service';
import {AuthService} from './auth.service';
import {Observable} from "rxjs/Rx";

@Injectable()
export class SharedAthleteService {

  constructor(private http: HttpClient) {
  }

  deleteAthlete(athleteId: string): Observable<any> {
    return this.http.delete(EndpointService.athleteUrl + `?id=${athleteId}`, AuthService.defaultTokenHeader());
  }

}
