import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { AdminService } from "../services/admin.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NotificationService } from "../services/notification.service";

@Component({
  selector: "app-clinic-admin",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss", "../styles.common.scss"]
})
export class OverviewComponent implements OnInit {
  public filter = false;

  public injuryForm: FormGroup;
  public performanceForm: FormGroup;
  public locationAdmins: any[];
  public injuryTypes: any[];
  public injurySides: any[];
  public levels: any[];
  public injuryLocations: any[];
  public injuries: any;
  public injuriesToShow: any[];

  sportOptions: any[];
  positionOptions: any[];
  copyPositionOptions: any[];
  levelOptions: any[];

  positionForSportName: any[];

  @ViewChildren("rehabDatePicker") rehabDatePicker: QueryList<any>;
  @ViewChildren("practiceDatePicker") practiceDatePicker: QueryList<any>;
  @ViewChildren("playDatePicker") playDatePicker: QueryList<any>;
  selectedDatePicker = "report";

  overviewLoade = false;

  newStartDate;
  newEndDate;

  public startTimestamp: any;
  public endTimestamp: any;
  datePickers: any = -1;
  aMonthAgo = this.getAMonthAgo();
  @ViewChildren("datepicker") datePicker: any;

  activityLevels: any[];
  injuriesNew: any[];
  injuryGrades: any[];
  selectedGender;
  selectedAge: any;
  selectedActivityLevel;
  selectedInjuryType;
  selectedInjury;
  selectedInjuryGrade;
  filters = [];
  type;

  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private notificationService: NotificationService) {
    this.injuryForm = this.formBuilder.group({
      gender: [""],
      age: [""],
      activityLevel: [""],
      injuryType: [""],
      injury: [""],
      injuryGrade: [""],
      startDate: [""],
      endDate: [""]
    });
    this.performanceForm = this.formBuilder.group({
      gender: [""],
      age: [""],
      sport: [""],
      position: [""],
      level: [""],
      startDate: [""],
      endDate: [""]
    });
  }

  ngOnInit() {
    this.getOverviewData();
    this.getAMonthAgo();

    this.adminService.getInjuriesSettings().subscribe(
      (value) => {
        // console.log(value);
        this.injuryTypes = value.types;
        this.injurySides = value.sides;
        this.levels = value.levels;
        this.injuryLocations = value.locations;
        this.injuries = value.injuries;
      },
      () => {
        // console.log(error);
      }
    );
  }

  selectSport() {
    this.positionOptions = this.copyPositionOptions;
    // console.log(this.performanceForm.controls['sport'].value)
    this.getPositionBySportName(this.performanceForm.controls["sport"].value);
  }

  getPositionBySportName(sportName) {
    this.adminService.getPositionBySportName(sportName).subscribe((data) => {
      this.positionForSportName = data.positionList;
      // console.log(this.positionForSportName);
      const intersection = this.positionOptions.filter((x) => this.positionForSportName.includes(x));
      this.positionOptions = intersection;
      // console.log(this.positionOptions);
    });
  }

  clearSelections() {
    this.performanceForm.reset();
  }

  getOverviewData() {
    this.adminService.getOverviewData().subscribe(
      (data) => {
        this.type = data.type;
        if (this.type == 1) {
          this.activityLevels = data.activityLevels;
          this.injuriesNew = data.injuries;
          this.injuryGrades = data.injuryGradeList;
        } else if (this.type == 0) {
          this.sportOptions = data.sportList;
          this.positionOptions = data.positionList;
          this.levelOptions = data.levelList;

          this.copyPositionOptions = JSON.parse(JSON.stringify(data.positionList));
        }

        if (this.type == 1) {
          const gender = this.injuryForm.controls["gender"].value;
          const age = this.injuryForm.controls["age"].value;
          const activityLevel = this.injuryForm.controls["activityLevel"].value;
          const injuryType = this.injuryForm.controls["injuryType"].value;
          const injury = this.injuryForm.controls["injury"].value;
          const injuryGrade = this.injuryForm.controls["injuryGrade"].value;
          const startDate = Date.parse(this.injuryForm.controls["startDate"].value);
          const endDate = Date.parse(this.injuryForm.controls["endDate"].value);
          this.adminService.getClinicDetails(startDate, endDate, gender, age, activityLevel, injuryType, injury, injuryGrade).subscribe(
            (value) => {
              this.overviewLoade = true;
              // console.log(value);
              this.locationAdmins = value;
            },
            (error) => this.notificationService.error(error)
          );
        } else if (this.type == 0) {
          const gender = this.performanceForm.controls["gender"].value;
          const age = this.performanceForm.controls["age"].value;
          const sport = this.performanceForm.controls["sport"].value;
          const position = this.performanceForm.controls["position"].value;
          const level = this.performanceForm.controls["level"].value;
          const startDate = Date.parse(this.injuryForm.controls["startDate"].value);
          const endDate = Date.parse(this.injuryForm.controls["endDate"].value);
          this.adminService.getPerformanceDetails(startDate, endDate, gender, age, sport, position, level).subscribe(
            (value) => {
              this.overviewLoade = true;
              // console.log(value);
              this.locationAdmins = value;
            },
            (error) => this.notificationService.error(error)
          );
        }
      },
      (error) => this.notificationService.error(error)
    );
  }

  public doFilter() {
    this.overviewLoade = false;

    if (this.type == 1) {
      const gender = this.injuryForm.controls["gender"].value;
      const age = this.injuryForm.controls["age"].value;
      const activityLevel = this.injuryForm.controls["activityLevel"].value;
      const injuryType = this.injuryForm.controls["injuryType"].value;
      const injury = this.injuryForm.controls["injury"].value;
      const injuryGrade = this.injuryForm.controls["injuryGrade"].value;
      const startDate = Date.parse(this.injuryForm.controls["startDate"].value);
      const endDate = Date.parse(this.injuryForm.controls["endDate"].value);
      this.adminService.getClinicDetails(startDate, endDate, gender, age, activityLevel, injuryType, injury, injuryGrade).subscribe(
        (value) => {
          this.overviewLoade = true;
          // console.log(value);
          this.locationAdmins = value;
        },
        (error) => this.notificationService.error(error)
      );
    } else if (this.type == 0) {
      const gender = this.performanceForm.controls["gender"].value;
      const age = this.performanceForm.controls["age"].value;
      const sport = this.performanceForm.controls["sport"].value;
      const position = this.performanceForm.controls["position"].value;
      const level = this.performanceForm.controls["level"].value;
      const startDate = Date.parse(this.performanceForm.controls["startDate"].value);
      const endDate = Date.parse(this.performanceForm.controls["endDate"].value);
      this.adminService.getPerformanceDetails(startDate, endDate, gender, age, sport, position, level).subscribe(
        (value) => {
          this.overviewLoade = true;
          // console.log(value);
          this.locationAdmins = value;
        },
        (error) => this.notificationService.error(error)
      );
    }
  }

  public getAMonthAgo() {
    const monthAgo = new Date();
    monthAgo.setMonth(monthAgo.getMonth() - 1);
    // console.log(monthAgo);
    return monthAgo;
  }

  changeInjury() {
    this.injuriesToShow = this.injuries[this.injuryForm.controls["location"].value];
  }
}
