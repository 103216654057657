import { Component, OnInit, ViewChild } from "@angular/core";
import { SettingsService } from "../services/settings.service";
import { FileUploader } from "ng2-file-upload";
import { DomSanitizer } from "@angular/platform-browser";
import { NotificationService } from "../services/notification.service";
import { NotifyParentService } from "../services/notify-parent.service";
import { GlobalPreferences } from "../helpers/global-data";
import { ModalTemplate, SuiModalService, TemplateModalConfig } from "ng2-semantic-ui";
import { cardValidator, nameValidator } from "../helpers/regex-data";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RegisterComponent } from "../register/register.component";
import { SharedRegisterService } from "../services/shared-register.service";
import { CountryService } from "../services/country.service";
import { monthVector, yearVector } from "../helpers/calendar-data";
import { Subscription } from "rxjs/Rx";
import { MixpanelService } from "../services/mixpanel.service";
import { MessageService } from "../services/message-service";
import { AuthService } from "../services/auth.service";

enum DeleteLocationUsersAction {
  NoAction,
  DeleteUsers,
  MoveUsers
}

enum TransactionType {
  FullPurchase,
  Subscription,
  RefundFullPurchase,
  RefundSubscription,
  Invoice
}

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss", "../styles.common.scss", "../athletes/athletes.component.scss"],
  providers: [SettingsService, SharedRegisterService, CountryService]
})
export class SettingsComponent implements OnInit {
  imageUploader: FileUploader;
  image = null;
  imagePreviewUrl = null;
  profilePicture;
  selected = "team";
  settingsData;
  settingsLoaded;
  imageNeedsUpdate = false;
  invoices = [];
  @ViewChild("passwordModalTemplate")
  passwordModalTemplate: ModalTemplate<string, string, string>;
  @ViewChild("accountInformationModal")
  accountInformationModal: ModalTemplate<string, string, string>;
  @ViewChild("userActionModal")
  userActionModal: ModalTemplate<string, string, string>;
  @ViewChild("deleteUserModal")
  deleteUserModal: ModalTemplate<string, string, string>;
  @ViewChild("locationActionModal")
  locationActionModal: ModalTemplate<string, string, string>;
  @ViewChild("locationUsersPickActionModal")
  locationUsersPickActionModal: ModalTemplate<string, string, string>;
  @ViewChild("locationMoveUsersModal")
  locationMoveUsersModal: ModalTemplate<string, string, string>;
  @ViewChild("deleteLocationModal")
  deleteLocationModal: ModalTemplate<string, string, string>;

  showQuickbooks = false;

  childSub: Subscription;
  @ViewChild("subscriptionPaymentTemplate")
  subscriptionPaymentTemplate: ModalTemplate<string, string, string>;
  subscriptionPaymentForm: FormGroup;
  yearVector: string[];
  currentMonthVector: string[];
  useMonthVector = false;
  selectedMonth: string;
  selectedYear: string;
  paymentData;
  subscriptionPaymentModal;
  attemptingPurchase = false;
  users = [];
  currentUser: any;
  userAction: any;
  locationAction: any;
  locations = [];
  usersOnlyOnThisLocation = [];
  mandatoryEmail: boolean;
  isPaymentHistoryLoading: boolean = true;
  paymentHistoryFilters: any = { pagination: { page: 0, size: 20 } };
  paymentHistoryDefaultFilters = { pagination: { page: 0, size: 20 } };
  paymentHistoryTransactions = [];
  paymentHistoryTotalPages: number = 0;
  paymentHistoryCurrentPage: number = 0;
  paymentHistoryForm: FormGroup;
  paymentHistoryTransactionType = TransactionType;
  paymentHistoryTransactionTypeValues = ["Full Purchase", "Subscription", "Refund Full Purchase", "Refund Subscription", "Invoice"];

  get monthVector() {
    return monthVector;
  }

  constructor(
    public settingsService: SettingsService,
    private parentNotifier: NotifyParentService,
    private sanitizer: DomSanitizer,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
    private modalService: SuiModalService,
    private formBuilder: FormBuilder,
    private mixpanelService: MixpanelService,
    private messageService: MessageService,
    private authService: AuthService
  ) {
    this.paymentHistoryForm = formBuilder.group({
      username: "",
      organizationName: "",
      hasFailed: "", // Payment Status
      transactionType: "",
      invoiceGenerated: "", // Invoice Status
      dateTimeInterval: formBuilder.group({
        startDate: "",
        endDate: ""
      })
    });
  }

  ngOnInit() {
    this.mixpanelService.track("settings_screen", {});
    this.getData();
    this.getInvoices();
    this.getLocations();
    this.imageUploader = new FileUploader({
      queueLimit: 1
    });
    this.imageUploader.onAfterAddingFile = (file) => {
      this.imageNeedsUpdate = true;
      this.image = file.file.type.indexOf("image") >= 0 && file.file.size <= 2000000 ? file.file : null;
      this.imagePreviewUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file._file));
    };

    this.messageService.accessMandatoryEmail().subscribe((data) => {
      this.mandatoryEmail = data;
    });

    if (this.isSuperAdmin()) {
      this.fetchPaymentHistory();
    }
  }

  getInvoices() {
    this.settingsService.getInvoices().subscribe(
      (response) => (this.invoices = response),
      (error) => this.notificationService.error(error)
    );
  }

  updateMandatoryEmail() {
    this.settingsService.updateUserPreferences(this.mandatoryEmail).subscribe(
      () => {
        this.messageService.sendMandatoryEmail(this.mandatoryEmail);
      },
      (error) => this.notificationService.error(error)
    );
  }

  getUsers() {
    this.settingsService.getUsers().subscribe(
      (response) => {
        this.users = response.body;
        this.users.forEach((value) => {
          const locationsName = [];
          value.activeLocations.forEach((location) => {
            locationsName.push(this.getLocationNameById(location));
          });
          value.locationsName = locationsName;
        });
      },
      (error) => this.notificationService.error(error)
    );
  }

  getLocations() {
    this.settingsService.getAllLocations().subscribe(
      (response) => {
        this.locations = response.body;
        this.getUsers();
      },
      (error) => this.notificationService.error(error)
    );
  }

  getLocationNameById(location) {
    let name = null;
    this.locations.forEach((value) => {
      if (value.id == location) {
        name = value.name;
      }
    });
    return name;
  }

  getData() {
    this.settingsLoaded = false;
    this.settingsService.getData().subscribe(
      (response) => {
        this.settingsData = response;
        if (this.settingsData.username == "kevin") {
          this.showQuickbooks = true;
        }
        this.profilePicture = response.profilePicture;
        this.settingsLoaded = true;
      },
      (error) => this.notificationService.error(error)
    );
  }

  updateProfilePicture() {
    const data = new FormData();
    data.append("file", this.image.rawFile);
    this.settingsService.updateProfilePicture(data).subscribe(
      () => {
        this.notificationService.success("Profile picture updated successfully.");
        this.profilePicture = this.imagePreviewUrl;
        this.imagePreviewUrl = null;
        this.parentNotifier.sendData("update");
      },
      (error) => this.notificationService.error(error)
    );
  }

  openInvoice(invoiceUrl: string) {
    window.open(invoiceUrl, "_blank");
  }

  findIndexById(arr, id) {
    let foundIndex = null;
    arr.forEach((value, index) => {
      if (value.id == id) {
        foundIndex = index;
      }
    });
    return foundIndex;
  }

  openPasswordModal() {
    const config = new TemplateModalConfig<string, string, string>(this.passwordModalTemplate);
    config.size = "mini";
    config.mustScroll = true;
    this.modalService.open(config);
  }

  openSubscriptionPaymentModal() {
    this.yearVector = yearVector();
    this.currentMonthVector = Object.assign([], monthVector);
    this.selectedMonth = this.currentMonthVector[0];
    this.selectedYear = this.yearVector[0];

    this.subscriptionPaymentForm = this.formBuilder.group(
      {
        firstName: ["", [Validators.required, nameValidator]],
        lastName: ["", [Validators.required, nameValidator]],
        cardNumber: ["", [Validators.required, cardValidator]],
        CVV: ["", [Validators.required]]
      },
      { validator: RegisterComponent.variableCvvValidator() }
    );

    const config = new TemplateModalConfig<string, string, string>(this.subscriptionPaymentTemplate);
    config.mustScroll = true;
    config.isFullScreen = true;
    this.subscriptionPaymentModal = this.modalService.open(config);
  }

  updatePaymentInfo() {
    this.attemptingPurchase = true;
    this.settingsService
      .updatePaymentInfo({
        cardNo: this.subscriptionPaymentForm.controls["cardNumber"].value,
        firstName: this.subscriptionPaymentForm.controls["firstName"].value.trim(),
        lastName: this.subscriptionPaymentForm.controls["lastName"].value.trim(),
        expiration: this.selectedMonth.toString().substring(0, 2) + this.selectedYear.toString().substring(2),
        cvv: this.subscriptionPaymentForm.controls["CVV"].value
      })
      .subscribe(
        () => {
          this.attemptingPurchase = false;
          this.subscriptionPaymentModal.approve(null);
          this.paymentData = undefined;
        },
        (error) => {
          this.attemptingPurchase = false;
          this.notificationService.error(error);
        }
      );
  }

  validateDate() {
    if (this.selectedYear !== this.yearVector[0]) {
      this.useMonthVector = true;
    } else {
      this.useMonthVector = false;
      this.selectedMonth = this.currentMonthVector[0];
    }
  }

  connectQuickbooks() {
    this.settingsService.connectQuickbooks().subscribe(
      (response) => {
        this.notificationService.success("Quickbooks connection in progress");
        window.open(response.body, "Quickbooks connect");
      },
      (error) => {
        this.notificationService.error(error);
      }
    );
  }

  editUser(currentUser: any) {
    this.settingsService.currentUser = currentUser;
    this.settingsService.userAction = "Edit";
    this.userAction = "Edit";
    const config = new TemplateModalConfig<string, string, string>(this.userActionModal);
    config.mustScroll = true;
    this.settingsService.userActionModal = this.modalService.open(config).onApprove(() => {
      this.getUsers();
    });
  }

  editLocation(currentLocation: any) {
    this.settingsService.currentLocation = currentLocation;
    this.settingsService.locationAction = "Edit";
    this.locationAction = "Edit";
    const config = new TemplateModalConfig<string, string, string>(this.locationActionModal);
    config.mustScroll = true;
    this.settingsService.locationActionModal = this.modalService.open(config).onApprove(() => {
      this.getLocations();
    });
  }

  handleDeleteLocationModal(locationId) {
    const config = new TemplateModalConfig<string, string, string>(this.deleteLocationModal);

    this.modalService.open(config).onApprove(() => {
      this.deleteLocation(locationId);
    });
  }

  deleteLocation(locationId, action = DeleteLocationUsersAction.NoAction, newLocationId?) {
    this.settingsService
      .deleteLocation(locationId, action, newLocationId)
      .then(({ usersOnlyOnThisLocation }: any) => {
        if (usersOnlyOnThisLocation.length && action === DeleteLocationUsersAction.NoAction) {
          this.usersOnlyOnThisLocation = usersOnlyOnThisLocation;
          this.handleLocationUsersPickActionModal(locationId);
        } else {
          this.getLocations();

          this.notificationService.success(
            "Location has been deleted" + (action === DeleteLocationUsersAction.MoveUsers ? " and users have been moved." : ".")
          );
        }
      })
      .catch((error) => this.notificationService.error(error));
  }

  handleLocationUsersPickActionModal(locationId) {
    const config = new TemplateModalConfig<string, string, string>(this.locationUsersPickActionModal);

    this.modalService.open(config).onApprove((action) => {
      if (action === DeleteLocationUsersAction.MoveUsers) {
        this.handleLocationMoveUsersModal(locationId);
      } else {
        this.deleteLocation(locationId, DeleteLocationUsersAction.DeleteUsers);
      }
    });
  }

  handleLocationMoveUsersModal(locationId) {
    let currentLocation;
    this.locations = this.locations.filter((location) => {
      if (location.id === locationId) {
        currentLocation = location;
        return false;
      }
      return true;
    });

    const config = new TemplateModalConfig<string, string, string>(this.locationMoveUsersModal);

    this.modalService
      .open(config)
      .onApprove((newLocationId) => {
        this.deleteLocation(locationId, DeleteLocationUsersAction.MoveUsers, newLocationId);
      })
      .onDeny(() => {
        this.locations.push(currentLocation);
      });
  }

  addLocation() {
    this.settingsService.currentLocation = {};
    this.settingsService.locationAction = "Add";
    this.locationAction = "Add";
    const config = new TemplateModalConfig<string, string, string>(this.locationActionModal);
    config.mustScroll = true;
    this.settingsService.locationActionModal = this.modalService.open(config).onApprove(() => {
      this.getLocations();
    });
  }

  addUser() {
    this.settingsService.currentUser = {};
    this.settingsService.userAction = "Add";
    this.userAction = "Add";
    const config = new TemplateModalConfig<string, string, string>(this.userActionModal);
    config.mustScroll = true;
    this.settingsService.userActionModal = this.modalService.open(config).onApprove(() => {
      this.getUsers();
    });
  }

  addTherapist() {
    this.settingsService.currentUser = {};
    this.settingsService.userAction = "Add";
    this.userAction = "Add";
    const config = new TemplateModalConfig<string, string, string>(this.userActionModal);
    config.mustScroll = true;
    this.settingsService.userActionModal = this.modalService.open(config).onApprove(() => {
      this.getUsers();
    });
  }

  deleteUser(currentUser: any) {
    this.currentUser = currentUser;
    const config = new TemplateModalConfig<string, string, string>(this.deleteUserModal);
    config.mustScroll = true;
    this.settingsService.userActionModal = this.modalService.open(config).onApprove(() => {
      this.getUsers();
    });
  }

  openAccountInformationModal() {
    const config = new TemplateModalConfig<string, string, string>(this.accountInformationModal);
    config.mustScroll = true;
    this.modalService.open(config);
  }

  confirmDeleteUser() {
    this.settingsService.deleteUser(this.currentUser.id).subscribe(
      (response) => {
        if (response.body) {
          this.notificationService.success("User deleted");
        } else {
          this.notificationService.error("We could not delete this user");
        }
        this.currentUser = {};
        this.settingsService.userActionModal.approve("done");
      },
      (error) => {
        this.attemptingPurchase = false;
        this.notificationService.error(error);
      }
    );
  }

  cancelDeleteUser() {
    this.currentUser = {};
    this.settingsService.userActionModal.approve("done");
  }

  handleToggleUserStatus(id) {
    this.settingsService.putUserToggleStatus(id).subscribe(
      () => {
        this.notificationService.success("Success");
        this.toggleUserStatus(id);
      },
      (res) => {
        if (res.error.text) {
          this.notificationService.success(res.error.text);
          this.toggleUserStatus(id);
        }

        if (res.error.error_description) {
          this.notificationService.error(res.error.error_description);
        }
      }
    );
  }

  toggleUserStatus(id) {
    this.users.forEach((user) => {
      if (user.id === id) {
        user.active = !user.active;
      }
    });
  }

  fetchPaymentHistory() {
    const { dateTimeInterval } = this.paymentHistoryFilters;

    if (dateTimeInterval) {
      this.paymentHistoryFilters = {
        ...this.paymentHistoryFilters,
        dateTimeInterval: {
          startDate: dateTimeInterval.startDate ? dateTimeInterval.startDate.toDateString() : dateTimeInterval.startDate,
          endDate: dateTimeInterval.endDate ? dateTimeInterval.endDate.toDateString() : dateTimeInterval.endDate
        }
      };
    }

    this.isPaymentHistoryLoading = true;

    this.settingsService.getPaymentHistory(this.paymentHistoryFilters).subscribe(({ transactions, totalPages }: any) => {
      this.paymentHistoryTransactions = transactions;
      this.paymentHistoryTotalPages = totalPages;
      this.isPaymentHistoryLoading = false;
    });
  }

  handlePaymentHistoryPageSelect(page) {
    this.paymentHistoryFilters.pagination = { ...this.paymentHistoryFilters.pagination, page };

    this.fetchPaymentHistory();
  }

  handlePaymentHistoryPageChange(page) {
    this.paymentHistoryCurrentPage = page;
    this.handlePaymentHistoryPageSelect(page);
  }

  handlePaymentHistoryFilters() {
    this.paymentHistoryFilters = { ...this.paymentHistoryFilters, ...this.paymentHistoryForm.value };

    this.fetchPaymentHistory();
  }

  handlePaymentHistoryResetForm() {
    this.paymentHistoryForm.reset();
    this.paymentHistoryFilters = this.paymentHistoryDefaultFilters;
    this.paymentHistoryCurrentPage = 0;

    this.fetchPaymentHistory();
  }

  isSuperAdmin() {
    return this.authService.checkTokenAccess("SUPER_ADMIN");
  }
}
