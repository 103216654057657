import { Component, OnInit, ViewChild } from "@angular/core";
import { ExerciseService } from "../services/exercise.service";
import { ModalTemplate, SuiModalService, TemplateModalConfig } from "ng2-semantic-ui";
import { SharedExerciseService } from "../services/shared-exercise.service";
import { NotificationService } from "../services/notification.service";
import { PlaylistsComponent } from "../playlists/playlists.component";
import "rxjs/add/observable/fromEvent";
import { SharedCategoryService } from "../services/shared-category.service";
import { MixpanelService } from "../services/mixpanel.service";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-exercises",
  templateUrl: "./exercises.component.html",
  styleUrls: ["./exercises.component.scss", "../styles.common.scss"],
  providers: [ExerciseService, SharedExerciseService, SharedCategoryService]
})
export class ExercisesComponent implements OnInit {
  exerciseCategories: any;
  exercises: any;
  @ViewChild("deleteModal")
  deleteModal: ModalTemplate<string, string, string>;
  @ViewChild("moveCategoryModal")
  moveCategoryModal: ModalTemplate<string, string, string>;
  @ViewChild("viewVideoModal")
  viewVideoModal: ModalTemplate<string, string, string>;
  exerciseToDelete;
  active = true;
  arEnabled = false;
  loading = true;
  toggle = true;
  canLoadExercises = [];
  categoriesLoaded = false;
  updateSelected = PlaylistsComponent.updateSelected;
  exercisesSelected = [];
  destinationCategory;
  toView;
  isActive = true;
  showArExercise = false;
  selectedCategories = [];

  constructor(
    private exerciseService: ExerciseService,
    private sharedExerciseService: SharedExerciseService,
    private modalService: SuiModalService,
    private notificationService: NotificationService,
    private sharedCategoryService: SharedCategoryService,
    private mixpanelService: MixpanelService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.mixpanelService.track("exercises_screen", {});
    this.getCategories();
    this.countArExercises();
  }

  // onClicckcer() {
  //     console.log(this.loading);
  // }
  getCategories(arEnabled?: boolean) {
    this.sharedExerciseService.getCategories().subscribe(
      (response) => {
        this.exerciseCategories = response;
        this.exercises = [];
        this.categoriesLoaded = true;
        this.exerciseCategories.forEach((value, index) => {
          if (arEnabled) {
            this.getExercisesByCategory(value, index, arEnabled);
          } else {
            this.getExercisesByCategory(value, index);
          }
        });
      },
      (error) => {
        this.notificationService.error(error);
        this.categoriesLoaded = true;
      }
    );
  }

  playVideoLeftExercise(exercise) {
    this.toView = exercise.firstExerciseVideoUrl;
    const config = new TemplateModalConfig<string, string, string>(this.viewVideoModal);
    config.size = "large";
    this.modalService.open(config);
  }

  playVideoRightExercise(exercise) {
    this.toView = exercise.secondExerciseVideoUrl;
    const config = new TemplateModalConfig<string, string, string>(this.viewVideoModal);
    config.size = "large";
    this.modalService.open(config);
  }

  getExercisesByCategory(category, index: number, arEnabled?: boolean) {
    const exercisesForCategory = this.exercises[category.id];
    const page = exercisesForCategory && exercisesForCategory.length > 0 ? Math.floor(exercisesForCategory.length / 20) : 0;
    const size = 20;
    if (arEnabled) {
      this.exerciseService.findAllByCategory(category.id, page, size, this.active, "2").subscribe(
        (response: any) => {
          this.exercises[category.id] =
            exercisesForCategory && exercisesForCategory.length > 0 ? this.exercises[category.id].concat(response) : response;
          const index = this.exerciseCategories.indexOf(category);
          if (this.exercises[category.id].length === 0) {
            this.exerciseCategories.splice(index, 1);
          }
          const exerciseLength = response.length;
          this.canLoadExercises[category.id] = exerciseLength % 20 === 0 && exerciseLength !== 0;
        },
        (error) => this.notificationService.error(error)
      );
      if (index == this.exerciseCategories.length - 1) {
        this.loading = false;
      }
    } else {
      this.exerciseService.findAllByCategory(category.id, page, size, this.active, "0").subscribe(
        (response: any) => {
          this.exercises[category.id] =
            exercisesForCategory && exercisesForCategory.length > 0 ? this.exercises[category.id].concat(response) : response;
          const index = this.exerciseCategories.indexOf(category);
          if (this.exercises[category.id].length === 0) {
            this.exerciseCategories.splice(index, 1);
          }
          const exerciseLength = response.length;
          this.canLoadExercises[category.id] = exerciseLength % 20 === 0 && exerciseLength !== 0;
        },
        (error) => this.notificationService.error(error)
      );
      if (index === this.exerciseCategories.length - 1) {
        this.loading = false;
      }
    }
  }

  countArExercises() {
    this.exerciseService.countArExercises().subscribe(
      (data) => {
        if (data.body > 0) {
          this.showArExercise = true;
        }
      },
      (error) => this.notificationService.error(error)
    );
  }

  openDeleteModal(exercise) {
    this.exerciseToDelete = {
      id: exercise.id,
      name: exercise.name,
      categoryId: exercise.categoryId
    };
    this.modalService.open(new TemplateModalConfig<string, string, string>(this.deleteModal));
  }

  findActiveExercises(active: boolean = false) {
    if (this.active !== active) {
      this.active = active;
      this.categoriesLoaded = false;
      this.getCategories();
    }
  }

  setActiveAll() {
    let index: number;
    let categoryId;
    for (const exercise of this.exercisesSelected) {
      for (const category of this.exerciseCategories) {
        index = this.exercises[category.id].indexOf(exercise);
        if (index > -1) {
          categoryId = category.id;
          break;
        }
      }
      this.setActive(exercise, categoryId);
    }
    this.finishAction(`Exercises successfully ${this.active ? "disabled" : "enabled"}.`);
  }

  favoriteAll() {
    for (const exercise of this.exercisesSelected) {
      if (!exercise.favorited) {
        this.setFavorited(exercise);
      }
    }
    this.finishAction("Exercises successfully favorited.");
  }

  unfavoriteAll() {
    for (const exercise of this.exercisesSelected) {
      if (exercise.favorited) {
        this.setFavorited(exercise);
      }
    }
    this.finishAction("Exercises successfully unfavorited.");
  }

  removeAll() {
    let index: number;
    let categoryId;
    for (const exercise of this.exercisesSelected) {
      if (!exercise.userCreated) {
        continue;
      }
      for (const category of this.exerciseCategories) {
        index = this.exercises[category.id].indexOf(exercise);
        if (index > -1) {
          categoryId = category.id;
          break;
        }
      }
      this.deleteExercise(exercise.id, categoryId);
    }
    this.finishAction(`Exercises successfully deleted.`);
  }

  finishAction(message: string) {
    this.notificationService.success(message);
    this.exercisesSelected = [];
  }

  setActive(exercise, categoryId) {
    this.exerciseService.setField({ id: exercise.id, field: "active", value: !exercise.active }).subscribe(
      () => {
        if (this.exercises[categoryId].length === 1) {
          this.removeExerciseCategory(categoryId);
        } else {
          this.exercises[categoryId].splice(this.exercises[categoryId].indexOf(exercise), 1);
        }
        this.notificationService.success(`Exercise successfully ${this.active ? "disabled" : "enabled"}.`);
      },
      (error) => this.notificationService.error(error)
    );
  }

  setFavorited(exercise) {
    this.exerciseService.setField({ id: exercise.id, field: "favorited", value: !exercise.favorited }).subscribe(
      () => {
        exercise.favorited = !exercise.favorited;
      },
      (error) => this.notificationService.error(error)
    );
  }

  openMoveModal() {
    this.destinationCategory = [];
    this.modalService.open(new TemplateModalConfig<string, string, string>(this.moveCategoryModal));
  }

  moveToCategory() {
    const exercises = [];
    for (let i = 0; i < this.exercisesSelected.length; i++) {
      const currentExercise = this.exercisesSelected[i];
      if (currentExercise.categoryId !== this.destinationCategory.id) {
        exercises.push(currentExercise.id);
        this.exercises[currentExercise.categoryId].splice(this.exercises[currentExercise.categoryId].indexOf(currentExercise), 1);
        let indexToPush;
        const nameToCompare = currentExercise.name;
        for (let i = 0; i < this.exercises[this.destinationCategory.id].length; i++) {
          if (nameToCompare < this.exercises[this.destinationCategory.id][i].name) {
            indexToPush = i;
            break;
          }
        }
        indexToPush === undefined
          ? this.exercises[this.destinationCategory.id].push(currentExercise)
          : this.exercises[this.destinationCategory.id].splice(indexToPush, 0, currentExercise);
        currentExercise.categoryId = this.destinationCategory.id;
        currentExercise.categoryUUID = this.destinationCategory.uuid;
      }
    }
    this.sharedCategoryService
      .moveToCategory({
        type: 1,
        categoryId: this.destinationCategory.id,
        categoryUUID: this.destinationCategory.uuid,
        objects: exercises
      })
      .subscribe(
        () => {
          this.exercisesSelected = [];
          this.notificationService.success("Successfully moved exercises.");
        },
        (error) => this.notificationService.error(error)
      );
  }

  deleteExercise(exerciseId, categoryId) {
    let exerciseToDeleteIndex = -1;
    const currentExercise = this.exercises[categoryId];
    this.exerciseService.deleteExercise(exerciseId).subscribe(() => {
      currentExercise.map((value, index) => {
        if (value.id === exerciseId) {
          exerciseToDeleteIndex = index;
        }
      });
      if (exerciseToDeleteIndex > -1) {
        if (this.exercises[categoryId].length === 1) {
          this.removeExerciseCategory(categoryId);
        } else {
          this.exercises[categoryId].splice(exerciseToDeleteIndex, 1);
        }
      }
    });
  }

  removeExerciseCategory(categoryId) {
    for (let i = 0; i < this.exerciseCategories.length; i++) {
      if (this.exerciseCategories[i].id === categoryId) {
        this.exerciseCategories.splice(i, 1);
        break;
      }
    }
  }

  playVideo(videoUrl: string) {
    this.toView = videoUrl;
    const config = new TemplateModalConfig<string, string, string>(this.viewVideoModal);
    config.size = "large";
    this.modalService.open(config);
  }

  toggleActive() {
    if (this.isActive) {
      this.findActiveExercises();
      this.isActive = false;
    } else {
      this.findActiveExercises(true);
      this.isActive = true;
    }
  }

  isSuperAdmin() {
    return this.authService.checkTokenAccess("SUPER_ADMIN");
  }

  addExerciseToAll(exercise) {
    const exId = exercise.id;

    this.exerciseService.addExerciseToAll(exId).subscribe(
      (data) => {
        this.notificationService.success(data);
      },
      (error) => {
        this.notificationService.error(error);
      }
    );
  }

  syncExercise(exercise) {
    this.router.navigate([`sync-exercise/${exercise.id}`]);
  }

  onUpdateExerciseVideo(exercise) {
    this.exerciseService.updateExerciseVideo(exercise.id).subscribe(
      (data) => {
        this.notificationService.success("Video will be updated in a few moments");
      },
      (error) => this.notificationService.error(error)
    );
  }

  addToSingleLocation(exercise, categoryId, updateVideos) {
    const queryParams = {};

    if (exercise) {
      Object.assign(queryParams, { exerciseId: exercise.id, exercisePaired: exercise.paired });
    }

    if (categoryId) {
      const currentSelectedExercises = this.exercisesSelected.filter((item) => item.categoryId === categoryId);
      if (!currentSelectedExercises.length) {
        return;
      }

      Object.assign(queryParams, { exerciseId: currentSelectedExercises.map((item) => item.id) });
    }

    if (updateVideos) {
      Object.assign(queryParams, { updateVideos });
    }

    this.router.navigate(["/sync-exercise"], { queryParams });
  }

  addCategory(categoryId) {
    this.exercises[categoryId].forEach((item) => {
      const intersection = this.exercisesSelected.find((exercise) => exercise.id === item.id);
      if (intersection) {
        this.exercisesSelected.splice(this.exercisesSelected.indexOf(intersection), 1);
      } else {
        this.exercisesSelected.push(item);
      }
    });

    if (this.selectedCategories.includes(categoryId)) {
      this.selectedCategories.splice(this.selectedCategories.indexOf(categoryId), 1);
    } else {
      this.selectedCategories.push(categoryId);
    }
  }
}
