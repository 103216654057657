import { NativeDateAdapter } from "@angular/material";
import { MatDateFormats } from "@angular/material/core";
import * as moment from "moment";

export class AppDateAdapter extends NativeDateAdapter {
    
  format(date: Date, displayFormat: Object): string {
    const mom: moment.Moment = moment(date);
    return mom.format("MMM D YYYY");
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: "short", year: "numeric", day: "numeric" },
  },
  display: {
    dateInput: "input",
    monthYearLabel: { year: "numeric", month: "numeric" },
    dateA11yLabel: { year: "numeric", month: "long", day: "numeric" },
    monthYearA11yLabel: { year: "numeric", month: "long" },
  },
};
