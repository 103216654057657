import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs/Rx";
import {EndpointService} from './endpoint.service';
import {AuthService} from './auth.service';

@Injectable()
export class PlaylistsService {

  constructor(private http: HttpClient) {
  }

  getPlaylistData(page: number, size: number, search: string = '', athleteId: string = ''): Observable<any> {
    const details = search == '' ? `?page=${page}&size=${size}` : `?page=${page}&size=${size}&search=${search}&id=${athleteId}`;
    return this.http.get(EndpointService.playlistUrl + details, AuthService.defaultTokenHeader());
  }

  savePlaylist(data): Observable<any> {
    return this.http.post(EndpointService.playlistUrl, data, AuthService.defaultTokenHeader());
  }

  clonePlaylist(data): Observable<any> {
    return this.http.post(EndpointService.playlistCloneUrl, data, AuthService.defaultTokenHeader());
  }

  getExercise(exerciseId: string, playlistId: string) {
    return this.http.get(EndpointService.exerciseUrl + `?id=${exerciseId}&playlist=${playlistId}`, AuthService.defaultTokenHeader());
  }

}
