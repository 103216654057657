import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EndpointService } from "./endpoint.service";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";

@Injectable()
export class AddWorkoutService {
  constructor(private http: HttpClient) {}

  addWorkoutIndividually(body) {
    return this.http.post(EndpointService.workoutAddIndividually, body, AuthService.defaultTokenHeader());
  }

  getOrganizations(page: number, size: number, org: string, workoutId: string): Observable<any> {
    return this.http.get(
      EndpointService.getOrgs + `?page=${page}&size=${size}&org=${org}&workoutId=${workoutId}`,
      AuthService.defaultTokenHeader()
    );
  }

  syncForFull(adminWorkoutId: string, locationId: string) {
    let body = {};
    return this.http.post(
      EndpointService.syncForFull + `?adminId=${adminWorkoutId}&locId=${locationId}`,
      body,
      AuthService.defaultTokenHeader()
    );
  }

  syncForExercises(adminWorkoutId: string, locationId: string) {
    let body = {};
    return this.http.post(
      EndpointService.syncForExercises + `?adminId=${adminWorkoutId}&locId=${locationId}`,
      body,
      AuthService.defaultTokenHeader()
    );
  }

  getWorkoutById(adminWorkoutId: string) {
    return this.http.get(EndpointService.workoutUrl + `/${adminWorkoutId}`, AuthService.defaultTokenHeader());
  }

  checkLocationsWorkout(adminWorkoutId: string) {
    return this.http.get(EndpointService.checkLocationsWorkout + `?workoutId=${adminWorkoutId}`, AuthService.defaultTokenHeader());
  }

  addWorkoutToAll(adminWorkoutId: string) {
    return this.http.get(EndpointService.addWorkoutToAll + `?workoutId=${adminWorkoutId}`, AuthService.defaultTokenHeader());
  }

  syncForPlaylists(adminWorkoutId: string) {
    return this.http.get(EndpointService.addPlaylistToAll + `?workoutId=${adminWorkoutId}`, AuthService.defaultTokenHeader());
  }

  addMultipleWorkouts(locationId: string, workoutIds: string[]) {
    return fetch(EndpointService.addMultipleWorkouts, {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + AuthService.getOauthTokenFromStorage() },
      body: JSON.stringify({ locationId, workoutIds })
    });
  }
}
