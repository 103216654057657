import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EndpointService} from './endpoint.service';
import {AuthService} from './auth.service';
import {Observable} from "rxjs/Rx";

@Injectable()
export class SharedCategoryService {

  constructor(private http: HttpClient) {
  }

  moveToCategory(data): Observable<any> {
    return this.http.post(EndpointService.moveToCategoryUrl, data, AuthService.defaultTokenHeader());
  }

}

