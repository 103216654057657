import { Injectable } from "@angular/core";
import { EndpointService } from "./endpoint.service";
import { AuthService } from "./auth.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class AdminService {
  constructor(private http: HttpClient) {}

  public getClinicDetails(startTimestamp, endTimestamp, gender, age, activityLevel, injuryType, injury, injuryGrade): Observable<any> {
    let query = "";
    if (startTimestamp) {
      if (query != "") query = query + "&startTimestamp=" + startTimestamp;
      else query = "?startTimestamp=" + startTimestamp;
    }
    if (endTimestamp) {
      if (query != "") query = query + "&endTimestamp=" + endTimestamp;
      else query = "?endTimestamp=" + endTimestamp;
    }
    if (gender) {
      if (query != "") query = query + "&gender=" + gender;
      else query = "?gender=" + gender;
    }
    if (age) {
      if (query != "") query = query + "&age=" + age;
      else query = "?age=" + age;
    }
    if (activityLevel) {
      if (query != "") query = query + "&activityLevel=" + activityLevel;
      else query = "?activityLevel=" + activityLevel;
    }
    if (injuryType) {
      if (query != "") query = query + "&injuryType=" + injuryType;
      else query = "?injuryType=" + injuryType;
    }
    if (injury) {
      if (query != "") query = query + "&injury=" + injury;
      else query = "?injury=" + injury;
    }
    if (injuryGrade) {
      if (query != "") query = query + "&injuryGrade=" + injuryGrade;
      else query = "?injuryGrade=" + injuryGrade;
    }
    return this.http.get(EndpointService.adminUrl + query, AuthService.defaultTokenHeader());
  }

  public getPerformanceDetails(startTimestamp, endTimestamp, gender, age, sport, position, level): Observable<any> {
    let query = "";
    if (startTimestamp) {
      if (query != "") query = query + "&startTimestamp=" + startTimestamp;
      else query = "?startTimestamp=" + startTimestamp;
    }
    if (endTimestamp) {
      if (query != "") query = query + "&endTimestamp=" + endTimestamp;
      else query = "?endTimestamp=" + endTimestamp;
    }
    if (gender) {
      if (query != "") query = query + "&gender=" + gender;
      else query = "?gender=" + gender;
    }
    if (age) {
      if (query != "") query = query + "&age=" + age;
      else query = "?age=" + age;
    }
    if (sport) {
      if (query != "") query = query + "&sport=" + sport;
      else query = "?sport=" + sport;
    }
    if (position) {
      if (query != "") query = query + "&position=" + position;
      else query = "?position=" + position;
    }
    if (level) {
      if (query != "") query = query + "&level=" + level;
      else query = "?level=" + level;
    }
    return this.http.get(EndpointService.adminUrl + "/performanceData" + query, AuthService.defaultTokenHeader());
  }

  public getOverviewData(): Observable<any> {
    return this.http.get(EndpointService.overviewData, AuthService.defaultTokenHeader());
  }

  public getInjuriesSettings(): Observable<any> {
    return this.http.get(EndpointService.injuriesUrl, AuthService.defaultTokenHeader());
  }

  getPositionBySportName(sportName: string): Observable<any> {
    return this.http.get(environment.baseURL + `/position-sport?sportName=${sportName}`, AuthService.defaultTokenHeader());
  }
}
