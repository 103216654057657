import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { EndpointService } from "./endpoint.service";

@Injectable()
export class SharedRegisterService {
  constructor(private http: HttpClient) {}

  validUser(username: string): Promise<any> {
    return this.http.get(EndpointService.checkIfExistsUrl + `?user=${username}`).toPromise();
  }

  validCoupon(data): Observable<any> {
    return this.http.post(EndpointService.validCouponUrl, data);
  }
}
