import {Injectable} from '@angular/core';
import {Observable} from "rxjs/Rx";
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {EndpointService} from './endpoint.service';

@Injectable()
export class ResultsService {

    constructor(private http: HttpClient) {
    }

    getResults(page: number, size: number, overview: number, exerciseId: string, endType: number, endValue: number,
               inputType: number, delay: number, flash: number, promptType: number, exerciseName: string, filterString: string): Observable<any> {
        let parameterString = `?page=${page}&size=${size}&overview=${overview}&id=${exerciseId}&endType=${endType}&endValue=${endValue}&inputType=${inputType}`;
        if (delay != null) {
            parameterString += `&delay=${delay}`;
        }
        if (flash != null) {
            parameterString += `&flash=${flash}`;
        }
        if (promptType != null) {
            parameterString += `&promptType=${promptType}`;
        }
        parameterString += `&exerciseName=${exerciseName}${filterString}`;
        return this.http.get(EndpointService.resultUrl + parameterString, AuthService.defaultTokenHeader());
    }

    getResultData(page: number, size: number, filterString: string, overview): Observable<any> {
        return this.http.get(EndpointService.resultsUrl + `?page=${page}&size=${size}${filterString}&overview=${overview}`,
            AuthService.defaultTokenHeader());
    }

    getPossibleFilters(): Observable<any> {
        return this.http.get(EndpointService.resultFiltersUrl, AuthService.defaultTokenHeader());
    }

    exportResultData(data): Observable<any> {
        return this.http.post(EndpointService.exportUrl, data, AuthService.defaultTokenHeader());
    }

    massExport(filterString: string, overview): Observable<any> {
        let param = "";
        if (filterString.length == 0) {
            param = "?";
        } else {
            param = "&";
        }
        return this.http.get(EndpointService.massExportUrl + filterString + param + `overview=${overview}`, AuthService.defaultTokenHeader());
    }

    deleteResult(id: string): Observable<any> {
        return this.http.delete(EndpointService.resultUrl + `?id=${id}`, AuthService.defaultTokenHeader());
    }

}
