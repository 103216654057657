import {Component, OnInit} from '@angular/core';
import {LocationsService} from "../services/locations.service";
import {Router} from "@angular/router";
import {AuthService} from "../services/auth.service";
import {NotifyParentService} from "../services/notify-parent.service";

@Component({
    selector: 'app-select-locations',
    templateUrl: './select-locations.component.html',
    styleUrls: ['./select-locations.component.scss']
})
export class SelectLocationsComponent implements OnInit {

    constructor(private locationService: LocationsService, private router: Router,
                private authService: AuthService, private childNotifier: NotifyParentService) {
    }

    locations = [];

    ngOnInit() {
        this.locationService.selectUserLocations().subscribe(resp => {
            this.locations = resp.body;
        })
    }

    selectLocation(id) {
        this.locationService.selectLocation(id).subscribe(resp => {
            this.router.navigate(['/']);
            this.childNotifier.sendData("update");
        })
    }

    logout() {
        this.authService.logout();
    }

}
