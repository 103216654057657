import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import { EndpointService } from "./endpoint.service";
import { AuthService } from "./auth.service";
import { environment } from "../../environments/environment";
import * as moment from "moment";

@Injectable()
export class DashboardService {
  constructor(private http: HttpClient) {}

  countActiveAthletes(fromDate: number): Observable<any> {
    return this.http.get(EndpointService.dashboardUrl + "/count-active-athletes?fromDate=" + fromDate, AuthService.defaultTokenHeader());
  }

  getSubscription(): Observable<any> {
    return this.http.get(EndpointService.dashboardUrl + "/get-subscription", AuthService.defaultTokenHeader());
  }

  getMostPopularExercise(fromDate: number): Observable<any> {
    return this.http.get(
      EndpointService.dashboardUrl + "/get-most-popular-exercise?fromDate=" + fromDate,
      AuthService.defaultTokenHeader()
    );
  }

  getTotalTime(fromDate: number): Observable<any> {
    return this.http.get(EndpointService.dashboardUrl + "/get-total-time?fromDate=" + fromDate, AuthService.defaultTokenHeader());
  }

  getFavoriteExercises(fromDate: number): Observable<any> {
    return this.http.get(EndpointService.dashboardUrl + "/get-favorite-exercises?fromDate=" + fromDate, AuthService.defaultTokenHeader());
  }

  refreshOneFavoriteExercise(page: number, data, id: string, fromDate: number): Observable<any> {
    return this.http.post(
      EndpointService.dashboardUrl + `/refresh-favorite-exercise?fromDate=${fromDate}&id=${id}&page=${page}`,
      data,
      AuthService.defaultTokenHeader()
    );
  }

  getMostActiveAthletes(fromDate): Observable<any> {
    return this.http.get(EndpointService.dashboardUrl + "/get-most-active-athletes?fromDate=" + fromDate, AuthService.defaultTokenHeader());
  }

  getActiveExercises(timeframe: string): Observable<any> {
    return this.http.get(environment.baseURL + `/active-exercises?timeframe=${timeframe}`, AuthService.defaultTokenHeader());
  }

  getLeaderboardForExercise(exerciseId: string, timeframe: string, gmtOffset: string = moment().format("Z")): Observable<any> {
    return this.http.get(
      environment.baseURL + `/leaderboard?exerciseId=${exerciseId}&timeframe=${timeframe}&gmtOffset=${gmtOffset}`,
      AuthService.defaultTokenHeader()
    );
  }

  getMostActiveExerciseLeaderboard(): Observable<any> {
    return this.http.get(EndpointService.mostActiveExercise, AuthService.defaultTokenHeader());
  }

  getUsers(searchText: string, usersPage: number, usersSize: number): Observable<any> {
    return this.http.get(
      environment.baseURL + `/user/list?page=${usersPage}&size=${usersSize}&searchText=${searchText}`,
      AuthService.defaultTokenHeader()
    );
  }

  updateUsers(users): Observable<any> {
    return this.http.put(environment.baseURL + `/user/list`, users, AuthService.defaultTokenHeader());
  }

  deleteCreditCard(organizationId): Observable<any> {
    return this.http.delete(EndpointService.deleteCreditCard + `?organizationId=${organizationId}`, AuthService.defaultTokenHeader());
  }

  getOrganizationsActiveCount(): Observable<any> {
    return this.http.get(EndpointService.organizationsActiveCount, AuthService.defaultTokenHeader());
  }
}
