import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RegisterComponent } from "./register/register.component";
import { AthletesComponent } from "./athletes/athletes.component";
import { LoginComponent } from "./login/login.component";
import { SettingsComponent } from "./settings/settings.component";
import { CodeGuard } from "./guards/code.guard";
import { UserGuard } from "./guards/user.guard";
import { NotUserGuard } from "./guards/notuser.guard";
import { LoggedGuard } from "./guards/logged.guard";
import { PlaylistsComponent } from "./playlists/playlists.component";
import { ExercisesComponent } from "./exercises/exercises.component";
import { WorkoutsComponent } from "./workouts/workouts.component";
import { SaveAthleteComponent } from "./athletes/save-athlete.component";
import { SaveWorkoutComponent } from "./workouts/save-workout.component";
import { SaveExerciseComponent } from "./exercises/save-exercise.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ResultsComponent } from "./results/results.component";
import { ReportsComponent } from "./reports/reports.component";
import { ActivityDataComponent } from "./activity-data/activity-data.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { SelectLocationsComponent } from "./select-locations/select-locations.component";
import { OverviewComponent } from "./overview/overview.component";
// import { ClinicAdminGuard } from "./guards/clinicadmin.guard";
import { SyncWorkoutComponent } from "./workouts/sync-workout/sync-workout.component";
import { SavePairedExerciseComponent } from "./exercises/save-paired-exercise/save-paired-exercise.component";
import { AgilityRatingComponent } from "./athletes/agility-rating/agility-rating.component";
import { SyncExerciseComponent } from "./exercises/sync-exercise/sync-exercise.component";
import { UnsavedChangesGuard } from "./guards/can-deactivate.guard";

const routes: Routes = [
  { path: "", component: DashboardComponent, canActivate: [CodeGuard, UserGuard], data: { name: "dashboard" } },
  { path: "overview", component: OverviewComponent, canActivate: [CodeGuard, UserGuard], data: { name: "admin" } },
  {
    path: "locations",
    component: SelectLocationsComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "locations" }
  },
  { path: "login", component: LoginComponent, canActivate: [NotUserGuard], data: { name: "login" } },
  { path: "register", component: RegisterComponent, canActivate: [CodeGuard, LoggedGuard], data: { name: "register" } },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    canActivate: [CodeGuard, LoggedGuard],
    data: { name: "forgot-password" }
  },
  {
    path: "forgot-password/m",
    component: ForgotPasswordComponent,
    canActivate: [CodeGuard, LoggedGuard],
    data: { name: "forgot-password/m" }
  },
  {
    path: "reset-password/:token",
    component: ResetPasswordComponent,
    canActivate: [CodeGuard, LoggedGuard],
    data: { name: "reset-password" }
  },
  { path: "settings", component: SettingsComponent, canActivate: [CodeGuard, UserGuard], data: { name: "settings" } },
  { path: "athletes", component: AthletesComponent, canActivate: [CodeGuard, UserGuard], data: { name: "athletes" } },
  { path: "athletes/:id", component: AgilityRatingComponent, canActivate: [CodeGuard, UserGuard], data: { name: "agility" } },
  { path: "patients", component: AthletesComponent, canActivate: [CodeGuard, UserGuard], data: { name: "patients" } },
  {
    path: "save-athlete",
    component: SaveAthleteComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "save-athlete" }
  },
  {
    path: "save-patient",
    component: SaveAthleteComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "save-patient" }
  },
  {
    path: "save-athlete/:id",
    component: SaveAthleteComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "save-athlete" }
  },
  {
    path: "save-patient/:id",
    component: SaveAthleteComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "save-patient" }
  },
  { path: "exercises", component: ExercisesComponent, canActivate: [CodeGuard, UserGuard], data: { name: "exercises" } },
  {
    path: "save-paired-exercise",
    component: SavePairedExerciseComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "save-paired-exercise" }
  },
  {
    path: "save-exercise",
    component: SaveExerciseComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "save-exercise" }
  },
  {
    path: "save-exercise/:id",
    component: SaveExerciseComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "save-exercise" }
  },
  {
    path: "sync-exercise",
    component: SyncExerciseComponent,
    canActivate: [CodeGuard, UserGuard]
  },

  {
    path: "playlists",
    component: PlaylistsComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [CodeGuard, UserGuard],
    data: { name: "playlists" }
  },
  { path: "results", component: ResultsComponent, canActivate: [CodeGuard, UserGuard], data: { name: "results" } },
  { path: "workouts", component: WorkoutsComponent, canActivate: [CodeGuard, UserGuard], data: { name: "workouts" } },
  { path: "protocols", component: WorkoutsComponent, canActivate: [CodeGuard, UserGuard], data: { name: "protocols" } },
  {
    path: "workouts/sync-workout",
    component: SyncWorkoutComponent,
    canActivate: [CodeGuard, UserGuard]
  },
  {
    path: "save-workout",
    component: SaveWorkoutComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "save-workout" }
  },
  {
    path: "save-protocol",
    component: SaveWorkoutComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "save-protocol" }
  },
  {
    path: "save-workout/:id",
    component: SaveWorkoutComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "save-protocol" }
  },
  {
    path: "save-protocol/:id",
    component: SaveWorkoutComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "save-protocol" }
  },
  { path: "reports", component: ReportsComponent, canActivate: [CodeGuard, UserGuard], data: { name: "activity-data" } },
  {
    path: "activity-data",
    component: ActivityDataComponent,
    canActivate: [CodeGuard, UserGuard],
    data: { name: "locations" }
  },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "terms-and-conditions", component: TermsAndConditionsComponent },
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
