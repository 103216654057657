import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ToasterConfig } from "angular2-toaster";
import { AuthService } from "./services/auth.service";
import { NotifyParentService } from "./services/notify-parent.service";
import { NotificationService } from "./services/notification.service";
import { Subscription, Subject } from "rxjs/Rx";
import { GlobalPreferences } from "./helpers/global-data";
import { ModalTemplate, SuiModalService, TemplateModalConfig } from "ng2-semantic-ui";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { monthVector, yearVector } from "./helpers/calendar-data";
import { cardValidator, nameValidator } from "./helpers/regex-data";
import { RegisterComponent } from "./register/register.component";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { MixpanelService } from "./services/mixpanel.service";
import { SettingsService } from "./services/settings.service";
import { CountryService } from "./services/country.service";
import { FileUploader } from "ng2-file-upload";
import { DomSanitizer } from "@angular/platform-browser";
import { MessageService } from "./services/message-service";

var mixpanel: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss", "./styles.common.scss", "./register/register.component.scss"],
  providers: [SettingsService, CountryService, NotificationService]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  userRoles = [];

  imageUploader: FileUploader;
  imageNeedsUpdate = false;
  image = null;
  imagePreviewUrl = null;
  hidden = true;
  profilePicture;
  hasMultipleLocations = false;
  name;
  currentLocation;
  toasterConfig: ToasterConfig = new ToasterConfig({
    positionClass: "toast-bottom-right",
    showCloseButton: true,
    animation: "slideDown",
    tapToDismiss: false,
    timeout: 5000,
    limit: 6
  });
  childSub: Subscription;
  @ViewChild("subscriptionPaymentTemplate")
  subscriptionPaymentTemplate: ModalTemplate<string, string, string>;
  @ViewChild("accountInformationModal")
  accountInformationModal: ModalTemplate<string, string, string>;
  @ViewChild("passwordModalTemplate")
  passwordModalTemplate: ModalTemplate<string, string, string>;
  subscriptionPaymentForm: FormGroup;
  yearVector: string[];
  currentMonthVector: string[];
  useMonthVector = false;
  selectedMonth: string;
  selectedYear: string;
  paymentData;
  subscriptionPaymentModal;
  attemptingPurchase = false;
  blankPage = false;

  subscription: Subscription;

  get monthVector() {
    return monthVector;
  }

  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private childNotifier: NotifyParentService,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
    private modalService: SuiModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private mixpanelService: MixpanelService,
    private sanitizer: DomSanitizer,
    public settingsService: SettingsService,
    private parentNotifier: NotifyParentService
  ) {
    this.childSub = this.childNotifier.getData().subscribe((data) => {
      if (data === "fullUpdate") {
        this.updateUserSettings();
        this.getPreferences();
      }
      if (data === "update") {
        this.updateUserSettings();
      }
    });

    router.events.subscribe((val) => {
      // if (val instanceof NavigationEnd) {
      //     this.blankPage = router.url == '/privacy-policy' || router.url == '/terms-and-conditions';
      // }
    });

    this.subscription = this.messageService.accessMessage().subscribe((msg) => {
      if (msg) {
        this.hasMultipleLocations = true;
      }
    });
  }

  ngOnInit() {
    if (this.fullyLoggedIn()) {
      this.updateUserSettings();
      this.getPreferences();
    }
    this.imageUploader = new FileUploader({
      queueLimit: 1
    });
    this.imageUploader.onAfterAddingFile = (file) => {
      this.imageNeedsUpdate = true;
      this.image = file.file.type.indexOf("image") >= 0 && file.file.size <= 2000000 ? file.file : null;
      this.imagePreviewUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file._file));

      this.updateProfilePicture();
    };
  }

  updateProfilePicture() {
    const data = new FormData();
    data.append("file", this.image.rawFile);
    this.settingsService.updateProfilePicture(data).subscribe(
      () => {
        this.notificationService.success("Profile picture updated successfully.");
        this.profilePicture = this.imagePreviewUrl;
        this.imagePreviewUrl = null;
        this.parentNotifier.sendData("update");
      },
      (error) => this.notificationService.error(error)
    );
  }
  updateUserSettings() {
    this.authService.getUserSettings().subscribe(
      (response) => {
        this.userRoles = response.roles;
        this.name = response.name;
        if (response.defaultLocation) {
          this.currentLocation = response.defaultLocation.name;
        }
        if (response.locations.length > 1) {
          this.hasMultipleLocations = true;
        } else {
          this.hasMultipleLocations = false;
        }
        this.mixpanelService.identify(response.username);
        this.profilePicture = response.profilePicture;
        if (!response.subscriptionStatusIsGood) {
          this.openSubscriptionPaymentModal(response);
        }
        this.messageService.sendMandatoryEmail(response.emailMandatory);
      },
      (error) => {
        this.notificationService.error(error);
        this.logout();
      }
    );
  }

  openSubscriptionPaymentModal(response: any) {
    this.router.navigate(["/"]);
    this.yearVector = yearVector();
    this.currentMonthVector = Object.assign([], monthVector);
    this.selectedMonth = this.currentMonthVector[0];
    this.selectedYear = this.yearVector[0];

    this.subscriptionPaymentForm = this.formBuilder.group(
      {
        firstName: ["", [Validators.required, nameValidator]],
        lastName: ["", [Validators.required, nameValidator]],
        cardNumber: ["", [Validators.required, cardValidator]],
        CVV: ["", [Validators.required]]
      },
      { validator: RegisterComponent.variableCvvValidator() }
    );

    const { amount, annuallySubscription, annuallyDiscount } = response;

    this.paymentData = {
      amount: annuallySubscription ? amount * 12 - annuallyDiscount : amount,
      subscriptionName: response.subscriptionName,
      triesToReBill: response.triesToReBill
    };

    const config = new TemplateModalConfig<string, string, string>(this.subscriptionPaymentTemplate);
    config.mustScroll = true;
    config.isClosable = false;
    config.size = "large";
    this.subscriptionPaymentModal = this.modalService.open(config);
  }

  attemptPayment() {
    this.attemptingPurchase = true;
    this.authService
      .attemptPayment({
        cardNo: this.subscriptionPaymentForm.controls["cardNumber"].value,
        firstName: this.subscriptionPaymentForm.controls["firstName"].value.trim(),
        lastName: this.subscriptionPaymentForm.controls["lastName"].value.trim(),
        expiration: this.selectedMonth.toString().substring(0, 2) + this.selectedYear.toString().substring(2),
        cvv: this.subscriptionPaymentForm.controls["CVV"].value
      })
      .subscribe(
        () => {
          this.attemptingPurchase = false;
          this.subscriptionPaymentModal.approve(null);
          this.paymentData = undefined;
        },
        (error) => {
          this.attemptingPurchase = false;
          this.notificationService.error(error);
        }
      );
  }

  validateDate() {
    if (this.selectedYear !== this.yearVector[0]) {
      this.useMonthVector = true;
    } else {
      this.useMonthVector = false;
      this.selectedMonth = this.currentMonthVector[0];
    }
  }

  getPreferences() {
    this.authService.getPreferences().subscribe(
      (response) => {
        this.global.changeData(response);
      },
      (error) => this.notificationService.error(error)
    );
  }

  ngAfterViewInit() {
    const faux = document.getElementById("faux");
    this.blankPage = this.router.url === "/privacy-policy" || this.router.url === "/terms-and-conditions";
    if (faux != null) {
      faux.style.width = Math.round(document.getElementById("nav_menu").offsetWidth) + "px";
    }
  }

  fullyLoggedIn() {
    return this.authService.checkTokenAccess("USER") || this.authService.checkTokenAccess("PAYMENT_ADMIN");
  }

  // onClicker() {
  //     console.log(this.isPaymentAdmin(), this.hasMultipleLocations)
  // }

  isPaymentAdmin() {
    return this.authService.checkTokenAccess("PAYMENT_ADMIN");
  }

  isMultiPerform() {
    return this.authService.checkTokenAccess("MULTI_PERFORMANCE");
  }

  isClinicSuperUser() {
    return this.authService.checkTokenAccess("CLINIC");
  }

  getAccountType() {
    return this.authService.checkAcconutType();
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.childSub.unsubscribe();
    this.subscription.unsubscribe();
  }

  changeInfo() {
    const config = new TemplateModalConfig<string, string, string>(this.accountInformationModal);
    config.mustScroll = true;
    this.modalService.open(config);
  }

  openPasswordModal() {
    const config = new TemplateModalConfig<string, string, string>(this.passwordModalTemplate);
    config.size = "mini";
    config.mustScroll = true;
    this.modalService.open(config);
  }
}
