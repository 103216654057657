import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs/Rx";
import {EndpointService} from './endpoint.service';
import {AuthService} from './auth.service';

@Injectable()
export class SaveExerciseService {

  constructor(private http: HttpClient) {
  }

  getExercise(id: string): Observable<any> {
    return this.http.get(EndpointService.exerciseUrl + `?id=${id}`, AuthService.defaultTokenHeader());
  }

  saveExercise(data): Observable<any> {
    return this.http.post(EndpointService.exerciseUrl, data, AuthService.defaultTokenHeader());
  }

  saveCategory(data): Observable<any> {
    return this.http.post(EndpointService.exerciseCategoryUrl, data, AuthService.defaultTokenHeader());
  }

  deleteCategory(selectedCategory: string) {
    return this.http.delete(EndpointService.exerciseCategoryUrl + `?id=${selectedCategory}`, AuthService.defaultTokenHeader());
  }

  savePairedExercise(data): Observable<any> {
    return this.http.post(EndpointService.savePairedExerciseUrl, data, AuthService.defaultTokenHeader());
  }
}
