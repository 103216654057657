import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {EndpointService} from "./endpoint.service";

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {

    constructor(private http: HttpClient) {
    }

    requestNewPassword(identifier) {
        return this.http.post(EndpointService.forgotPasswordUrl, {identifier: identifier});
    }

    resetPassword(password, token) {
        return this.http.post(EndpointService.resetPasswordUrl, {password: password, token:token});
    }
}
