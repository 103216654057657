import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EndpointService } from "./endpoint.service";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs/Rx";
import { environment } from "../../environments/environment";

@Injectable()
export class ExerciseService {
  constructor(private http: HttpClient) {}

  findAllByCategory(category, page, size, active: boolean, boardType: string): Observable<any> {
    return this.http.get(
      EndpointService.exercisesUrl + `?categoryId=${category}&page=${page}&size=${size}&active=${active}&boardType=${boardType}`,
      AuthService.defaultTokenHeader()
    );
  }

  findAllByCategoryArEnabled(category, page, size, active: boolean, arEnabled: boolean): Observable<any> {
    return this.http.get(
      EndpointService.exercisesUrl + `?categoryId=${category}&page=${page}&size=${size}&active=${active}&arEnabled=${arEnabled}`,
      AuthService.defaultTokenHeader()
    );
  }

  setField(data): Observable<any> {
    return this.http.post(EndpointService.setFieldUrl, data, AuthService.defaultTokenHeader());
  }

  deleteExercise(exerciseId: string): Observable<any> {
    return this.http.delete(EndpointService.exerciseUrl + `?id=${exerciseId}`, AuthService.defaultTokenHeader());
  }

  addExerciseToAll(exerciseId: string[]) {
    let body = { exercisesId: exerciseId };

    return this.http.post(EndpointService.addExerciseToAll + `?exerciseId=${exerciseId}`, body, AuthService.defaultTokenHeader());
  }

  updateExerciseVideo(exerciseId: string) {
    let body = {};

    return this.http.post(EndpointService.updateVideoToAll + `?exId=${exerciseId}`, body, AuthService.defaultTokenHeader());
  }

  getExerciseOrganization(page: number, size: number, exerciseId: string, org: string): Observable<any> {
    return this.http.get(
      EndpointService.exerciseOrganizations + `?page=${page}&size=${size}&exerciseId=${exerciseId}&org=${org}`,
      AuthService.defaultTokenHeader()
    );
  }

  getExercisesOrganization(page, size, exercisesIds, org): Observable<any> {
    let body = { exercisesIds, page, size, org };

    return this.http.post(EndpointService.exercisesOrganizations, body, AuthService.defaultTokenHeader());
  }

  getVideoOrganization(page, size, exercisesIds, name): Observable<any> {
    let body = new URLSearchParams({ page, size, name }).toString();

    return this.http.get(`${EndpointService.exerciseOrganizationsPaginated}?${body}`, AuthService.defaultTokenHeader());
  }

  addExerciseToLocation(exerciseId: string, locationId: string): Observable<any> {
    let body = {};

    return this.http.post(
      EndpointService.addExerciseToLocation + `?exerciseId=${exerciseId}&locationId=${locationId}`,
      body,
      AuthService.defaultTokenHeader()
    );
  }

  addExercisesToLocation(exercisesIds: string[], locationId: string): Observable<any> {
    let body = { exercisesIds, locationId };

    return this.http.post(EndpointService.addExercisesToLocation, body, AuthService.defaultTokenHeader());
  }

  countArExercises(): Observable<any> {
    return this.http.get(environment.baseURL + "/count-ar", AuthService.defaultTokenHeader());
  }

  updateVideos(exercisesIds, locationsIds): Observable<any> {
    let body = { exercisesIds, locationsIds };

    return this.http.post(EndpointService.updateVideos, body, AuthService.defaultTokenHeader());
  }
}
