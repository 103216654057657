import { Component, OnInit } from "@angular/core";
import { AthleteService } from "../../services/athlete.service";
import { ActivatedRoute, Params } from "@angular/router";
import * as moment from "moment";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: "app-agility",
  templateUrl: "./agility-rating.component.html",
  styleUrls: ["agility-rating.component.scss", "../../styles.common.css"],
  providers: [AthleteService]
})
export class AgilityRatingComponent implements OnInit {
  athleteId: string;

  athlete: any = null;
  agilityRatings: any = [];

  currentPage = 0;

  newStartDate;
  newEndDate;

  loading = false;

  constructor(private route: ActivatedRoute, private athleteService: AthleteService, private notificationService: NotificationService) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.athleteId = params["id"];
    });

    this.athleteService.getAthleteById(this.athleteId).subscribe(
      (data) => {
        this.athlete = data;
        this.newEndDate = new Date();
        this.newStartDate = new Date(this.newEndDate.getFullYear(), this.newEndDate.getMonth() - 6, this.newEndDate.getDate());
        this.getAgilityRatingByIdPagin(this.athleteId, this.newStartDate.getTime(), this.newEndDate.getTime(), 0, 5);
      },
      (error) => this.notificationService.error(error)
    );
  }

  getAgilityRatingByIdPagin(athleteId: string, start, end, page: number, size: number) {
    this.loading = false;
    return this.athleteService.getAgilityRatingByIdPaging(athleteId, start, end, page, size).subscribe(
      (data) => {
        this.agilityRatings = data;
        this.loading = true;
      },
      (error) => this.notificationService.error(error)
    );
  }

  downloadAgilityByName(agilityRating) {
    let name = agilityRating.filename;
    return this.athleteService.downloadAgilityRatingByName(name).subscribe(
      (data) => {
        let blob = new Blob([data.data], {
          type: "application/pdf"
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = data.filename;
        document.body.appendChild(link);

        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (error) => this.notificationService.error(error)
    );
  }

  viewAgilityRatingByName(agilityRating) {
    let name = agilityRating.filename;
    return this.athleteService.downloadAgilityRatingByName(name).subscribe(
      (data) => {
        // console.log(data);
        let blob = new Blob([data.data], {
          type: "application/pdf"
        });

        window.open(URL.createObjectURL(blob), "_blank");
      },
      (error) => this.notificationService.error(error)
    );
  }

  getResultData() {
    this.loading = false;
    this.athleteService
      .getAgilityRatingByIdPaging(this.athleteId, this.newStartDate.getTime(), this.newEndDate.getTime(), this.currentPage, 3)
      .subscribe(
        (data) => {
          this.loading = true;
          this.agilityRatings = data;
        },
        (error) => this.notificationService.error(error)
      );
  }

  changeDate(type: string, event: any) {
    this.newStartDate = moment(event.value.begin).toDate();
    this.newEndDate = moment(event.value.end).toDate();
  }

  isValidDate(newStartDate, newEndDate) {
    return moment(newStartDate).isValid() && moment(newEndDate).isValid();
  }

  resetValues() {
    this.newStartDate = "";
    this.newEndDate = "";
  }
}
