import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { nameValidator, phoneValidator } from "../../helpers/regex-data";
import { SettingsService } from "../../services/settings.service";
import { CountryService } from "../../services/country.service";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: "app-account-info",
  templateUrl: "./account-info.component.html",
  styleUrls: ["./account-info.component.scss"]
})
export class AccountInfoComponent implements OnInit {
  @Input() modal;

  subscriptionPaymentForm: FormGroup;
  accountInformationForm: FormGroup;
  addressSelected;
  addressObject = {
    countries: [],
    states: [],
    cities: [],
    typeahead: [],
    countryName: "country",
    stateName: "state",
    cityName: "city"
  };
  shippingObject = {
    countries: [],
    states: [],
    typeahead: [],
    countryName: "shippingCountry",
    stateName: "shippingState",
    cityName: "shippingCity"
  };
  billingObject = {
    countries: [],
    states: [],
    typeahead: [],
    countryName: "billingCountry",
    stateName: "billingState",
    cityName: "billingCity"
  };

  constructor(
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.addressSelected = 0;
    this.accountInformationForm = this.formBuilder.group({
      email: ["", []],
      organizationName: ["", [Validators.required]],
      firstName: ["", [Validators.required, nameValidator]],
      lastName: ["", [Validators.required, nameValidator]],
      country: ["", []],
      address: ["", []],
      address2: ["", []],
      city: ["", []],
      state: ["", []],
      zipCode: ["", []],
      shippingCountry: ["", []],
      shippingAddress: ["", []],
      shippingAddress2: ["", []],
      shippingCity: ["", []],
      shippingState: ["", []],
      shippingZipCode: ["", []],
      billingCountry: ["", []],
      billingAddress: ["", []],
      billingAddress2: ["", []],
      billingCity: ["", []],
      billingState: ["", []],
      billingZipCode: ["", []],
      phone: ["", [phoneValidator]]
    });
    this.subscriptionPaymentForm = this.formBuilder.group({
      firstName: [""],
      lastName: [""],
      cardNumber: [""],
      CVV: [""]
    });
    this.settingsService.getAccountInformation().subscribe(
      (response) => {
        this.accountInformationForm.controls["email"].setValue(response.email);
        this.accountInformationForm.controls["organizationName"].setValue(response.organizationName);
        this.accountInformationForm.controls["firstName"].setValue(response.firstName);
        this.accountInformationForm.controls["lastName"].setValue(response.lastName);
        if (response.organizationAddress) {
          this.accountInformationForm.controls["country"].setValue(response.organizationAddress.country);
          this.accountInformationForm.controls["state"].setValue(response.organizationAddress.state);
          this.accountInformationForm.controls["city"].setValue(response.organizationAddress.city);
          this.accountInformationForm.controls["zipCode"].setValue(response.organizationAddress.zipCode);

          this.accountInformationForm.controls["address"].setValue(response.organizationAddress.addressLine1);
          this.accountInformationForm.controls["address2"].setValue(response.organizationAddress.addressLine2);
        }
        this.accountInformationForm.controls["phone"].setValue(response.phoneNo);
        if (response.shippingAddress) {
          this.accountInformationForm.controls["shippingCountry"].setValue(response.shippingAddress.country);
          this.accountInformationForm.controls["shippingState"].setValue(response.shippingAddress.state);
          this.accountInformationForm.controls["shippingCity"].setValue(response.shippingAddress.city);
          this.accountInformationForm.controls["shippingZipCode"].setValue(response.shippingAddress.zipCode);

          this.accountInformationForm.controls["shippingAddress"].setValue(response.shippingAddress.addressLine1);
          this.accountInformationForm.controls["shippingAddress2"].setValue(response.shippingAddress.addressLine2);
        }
        if (response.billingAddress) {
          this.accountInformationForm.controls["billingCountry"].setValue(response.billingAddress.country);
          this.accountInformationForm.controls["billingState"].setValue(response.billingAddress.state);
          this.accountInformationForm.controls["billingCity"].setValue(response.billingAddress.city);
          this.accountInformationForm.controls["billingZipCode"].setValue(response.billingAddress.zipCode);

          this.accountInformationForm.controls["billingAddress"].setValue(response.billingAddress.addressLine1);
          this.accountInformationForm.controls["billingAddress2"].setValue(response.billingAddress.addressLine2);
        }
        this.getCountries(this.shippingObject, true);
        this.getCountries(this.billingObject, true);
        this.getCountries(this.addressObject, true);
      },
      (error) => this.notificationService.error(error)
    );
  }

  getCountries(locationObject, initialize: boolean = false) {
    this.countryService.getAllCountries().subscribe(
      (response) => {
        locationObject.countries = response;
        if (
          this.accountInformationForm.controls[locationObject.countryName].value &&
          this.accountInformationForm.controls[locationObject.countryName].value.length > 0
        ) {
          this.accountInformationForm.controls[locationObject.countryName].setValue(
            response[this.accountInformationForm.controls[locationObject.countryName].value - 1].id
          );
        } else {
          this.accountInformationForm.controls[locationObject.countryName].setValue(locationObject.countries[0].id);
        }
        this.countryHasChanged(locationObject, initialize);
      },
      (error) => this.notificationService.error("Country error: " + error.error.message)
    );
  }

  onForm() {
    // console.log('padasdas')
  }

  changeAccountInformation() {
    this.settingsService
      .changeAccountInformation({
        email: this.accountInformationForm.controls["email"].value,
        organizationName: this.accountInformationForm.controls["organizationName"].value,
        firstName: this.accountInformationForm.controls["firstName"].value,
        lastName: this.accountInformationForm.controls["lastName"].value,
        phoneNo: this.accountInformationForm.controls["phone"].value,
        organizationAddress: {
          addressLine1: this.accountInformationForm.controls["address"].value
            ? this.accountInformationForm.controls["address"].value.trim()
            : "",
          addressLine2: this.accountInformationForm.controls["address2"].value
            ? this.accountInformationForm.controls["address2"].value.trim()
            : "",
          country: this.accountInformationForm.controls["country"].value,
          state: this.accountInformationForm.controls["state"].value,
          city: this.accountInformationForm.controls["city"].value,
          zipCode: this.accountInformationForm.controls["zipCode"].value.trim()
        },
        shippingAddress: {
          firstName: this.accountInformationForm.controls["firstName"].value.trim(),
          lastName: this.accountInformationForm.controls["lastName"].value.trim(),
          addressLine1: this.accountInformationForm.controls["shippingAddress"].value
            ? this.accountInformationForm.controls["shippingAddress"].value.trim()
            : "",
          addressLine2: this.accountInformationForm.controls["shippingAddress2"].value
            ? this.accountInformationForm.controls["shippingAddress2"].value.trim()
            : "",
          country: this.accountInformationForm.controls["shippingCountry"].value,
          state: this.accountInformationForm.controls["shippingState"].value,
          city: this.accountInformationForm.controls["shippingCity"].value,
          zipCode: this.accountInformationForm.controls["shippingZipCode"].value.trim()
        },
        billingAddress: {
          firstName: this.accountInformationForm.controls["firstName"].value.trim(),
          lastName: this.accountInformationForm.controls["lastName"].value.trim(),
          addressLine1: this.accountInformationForm.controls["billingAddress"].value
            ? this.accountInformationForm.controls["billingAddress"].value.trim()
            : "",
          addressLine2: this.accountInformationForm.controls["billingAddress2"].value
            ? this.accountInformationForm.controls["billingAddress2"].value.trim()
            : "",
          country: this.accountInformationForm.controls["billingCountry"].value,
          state: this.accountInformationForm.controls["billingState"].value,
          city: this.accountInformationForm.controls["billingCity"].value,
          zipCode: this.accountInformationForm.controls["billingZipCode"].value.trim()
        }
      })
      .subscribe(
        () => {
          this.notificationService.success("Account information successfully changed.");
        },
        (error) => this.notificationService.error(error)
      );
  }

  countryHasChanged(locationObject, initialize: boolean = false) {
    this.countryService.getStatesByCountry(this.accountInformationForm.controls[locationObject.countryName].value).subscribe(
      (response) => {
        locationObject.states = response;
        if (
          this.accountInformationForm.controls[locationObject.stateName].value &&
          this.accountInformationForm.controls[locationObject.stateName].value.length > 0
        ) {
          this.accountInformationForm.controls[locationObject.stateName].setValue(
            initialize ? this.accountInformationForm.controls[locationObject.stateName].value : response[0].id
          );
        } else {
          this.accountInformationForm.controls[locationObject.stateName].setValue(response[0].id);
        }

        this.stateHasChanged(locationObject, initialize);
      },
      (error) => this.notificationService.error("State error: " + error.error.message)
    );
  }

  stateHasChanged(locationObject, initialize: boolean = false) {
    this.countryService.getCitiesByState(this.accountInformationForm.controls[locationObject.stateName].value).subscribe(
      (response) => {
        locationObject.cities = response;
        if (initialize) {
          const nameToAdd = this.accountInformationForm.controls["city"].value;
          let added = false;
          for (let i = 0; i < locationObject.cities.length; i++) {
            if (nameToAdd == locationObject.cities[i].name) {
              added = true;
              break;
            }
            if (nameToAdd < locationObject.cities[i].name) {
              locationObject.cities.splice(i, 0, { name: this.accountInformationForm.controls[locationObject.cityName].value });
              added = true;
              break;
            }
          }
          if (!added) {
            locationObject.cities.push({ name: this.accountInformationForm.controls[locationObject.cityName].value });
          }
        }
        locationObject.typeahead = response.map((city) => city.name);
      },
      (error) => this.notificationService.error("State error: " + error.error.message)
    );
  }
}
