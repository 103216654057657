import {Injectable} from "@angular/core";
import {Subject} from "rxjs/Rx";

@Injectable()
export class GlobalPreferences {
    data: any = {
        isClinic: false,
        athleteName: 'athlete',
        uppercaseAthleteName: 'Athlete',
        workoutName: 'workout',
        uppercaseWorkoutName: 'Workout'
    };

    dataChange: Subject<any> = new Subject<any>();

    constructor() {
        this.dataChange.subscribe(data => this.data = data);
    }

    changeData(data: any) {
        this.dataChange.next(data);
    }


}