import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../services/auth.service";
import {ResetPasswordService} from "../services/reset-password.service";
import {NotificationService} from "../services/notification.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss', '../styles.common.scss']
})
export class ForgotPasswordComponent implements OnInit {

    resetForm: FormGroup;
    isMobile = false;
    loading = false;

    constructor(private authService: AuthService, private resetPasswordService: ResetPasswordService,
                private formBuilder: FormBuilder, private notificationService: NotificationService, private activeRoute: ActivatedRoute) {
    }

    get loggedIn() {
        return this.authService.loggedIn();
    }

    ngOnInit() {
        if (this.activeRoute.snapshot.url && this.activeRoute.snapshot.url.length > 1 && this.activeRoute.snapshot.url[1].path == 'm')
            this.isMobile = true;
        this.resetForm = this.formBuilder.group({
            loginUsernameEmail: ['', [Validators.required]]
        });
    }

    reset() {
        this.loading = true;
        this.resetPasswordService.requestNewPassword(this.resetForm.controls['loginUsernameEmail'].value).subscribe(response => {
            this.loading = false;
            if (response['body']) {
                this.notificationService.success("Thank you, you will receive an email with instructions");
            } else {
                this.notificationService.error("Sorry, we could not find an user with this username/email");
            }

        })

    }

}
