import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs/Rx";
import {EndpointService} from './endpoint.service';
import {AuthService} from './auth.service';

@Injectable()
export class WorkoutService {

  constructor(private http: HttpClient) {
  }

  getWorkoutData(page, size): Observable<any> {
    return this.http.get(EndpointService.workoutUrl + `?page=${page}&size=${size}`, AuthService.defaultTokenHeader());
  }

  getWorkouts(page, size): Observable<any> {
    return this.http.get(EndpointService.workoutsUrl + `?page=${page}&size=${size}`, AuthService.defaultTokenHeader());
  }

  deleteWorkout(workoutId: string): Observable<any> {
    return this.http.delete(EndpointService.workoutUrl + `?id=${workoutId}`, AuthService.defaultTokenHeader());
  }

}
