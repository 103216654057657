import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class EndpointService {
  constructor() {}

  /** The base apiUrl that all services send requests to. */
  // public static apiUrl = 'https://api.thequickboard.com';
  // public static apiUrl = 'http://192.168.0.122:4000';
  //   public static apiUrl = "https://apidev.thequickboard.com";
  //   public static apiUrl = "https://dev.qb.cst.ro";

  public static paymentApiUrl = "https://apidev.thequickboard.com";

  /** The base Url for all microservices. */
  public static accountServiceUrl = environment.baseURL + "/accounts";

  public static authServiceUrl = environment.baseURL + "/uaa/users";

  public static paymentServiceUrl = environment.baseURL + "/payment";

  public static webServiceUrl = environment.baseURL;

  /** The version Url. (todo add version Url when necessary) */
  public static versionUrl = "/1";

  /** The specific endpoints for each microservice. */

  /** Account Service */
  public static registerUrl = EndpointService.accountServiceUrl + "/";

  public static checkIfExistsUrl = EndpointService.accountServiceUrl + "/check";

  public static countryUrl = EndpointService.accountServiceUrl + "/countries";

  public static stateUrl = EndpointService.accountServiceUrl + "/states";

  public static cityUrl = EndpointService.accountServiceUrl + "/cities";

  /** Auth Service */
  public static tokenUrl = environment.baseURL + "/uaa/oauth/token";

  /*   public static qrCodeUrl = EndpointService.authServiceUrl + '/QR';

       public static twoFactorUrl = EndpointService.authServiceUrl + '/2FA';*/

  public static logoutUrl = EndpointService.authServiceUrl + "/logout";

  public static updatePasswordUrl = EndpointService.authServiceUrl + "/change-password";

  /** Payment Service */
  public static registerProductsUrl = EndpointService.paymentServiceUrl + "/products";

  public static validCouponUrl = EndpointService.paymentServiceUrl + "/check-coupon";

  public static invoicesUrl = EndpointService.paymentServiceUrl + "/invoices";

  public static settingsUsersUrl = EndpointService.accountServiceUrl + "/users";

  public static attemptPaymentUrl = EndpointService.paymentServiceUrl + "/attempt-payment";

  /** Web Service **/
  public static adminUrl = EndpointService.webServiceUrl + "/admin";

  public static injuriesUrl = EndpointService.webServiceUrl + "/admin/injuries";

  public static athleteUrl = EndpointService.webServiceUrl + "/athlete";

  public static athletesUrl = EndpointService.webServiceUrl + "/athletes";

  public static athleteActiveUrl = EndpointService.webServiceUrl + "/athlete-active";

  public static athleteCategoryUrl = EndpointService.webServiceUrl + "/athlete-category";

  public static athleteCategoriesUrl = EndpointService.webServiceUrl + "/athlete-categories";

  public static athleteLevelsUrl = EndpointService.webServiceUrl + "/levels";

  public static athleteFiltersUrl = EndpointService.webServiceUrl + "/athlete-filters";

  public static sportsUrl = EndpointService.webServiceUrl + "/sports";

  public static teamsUrl = EndpointService.webServiceUrl + "/teams";

  public static teamUrl = EndpointService.webServiceUrl + "/team";

  public static exerciseCategoriesUrl = EndpointService.webServiceUrl + "/exercise-categories";

  public static exerciseCategoryUrl = EndpointService.webServiceUrl + "/exercise-category";

  public static exerciseUrl = EndpointService.webServiceUrl + "/exercise";

  public static setFieldUrl = EndpointService.webServiceUrl + "/set-exercise-field";

  public static exercisesUrl = EndpointService.webServiceUrl + "/exercises";

  public static savePairedExerciseUrl = EndpointService.webServiceUrl + "/pairedExercise";

  public static exerciseOrganizations = EndpointService.webServiceUrl + "/exercise-organizations";
  public static exercisesOrganizations = EndpointService.webServiceUrl + "/exercises-organizations";

  public static exerciseDetailsUrl = EndpointService.webServiceUrl + "/exercise-details";

  public static moveToCategoryUrl = EndpointService.webServiceUrl + "/move-to-category";

  public static playlistUrl = EndpointService.webServiceUrl + "/playlist";

  public static playlistCloneUrl = EndpointService.webServiceUrl + "/playlist-clone";

  public static workoutUrl = EndpointService.webServiceUrl + "/workout";

  public static workoutsUrl = EndpointService.webServiceUrl + "/workouts";

  public static saveWorkoutUrl = EndpointService.webServiceUrl + "/save-workout";

  public static workoutAthletesUrl = EndpointService.webServiceUrl + "/workout-athletes";

  public static iPadsUrl = EndpointService.webServiceUrl + "/ipads";

  public static resultUrl = EndpointService.webServiceUrl + "/result";

  public static resultsUrl = EndpointService.webServiceUrl + "/results";

  public static resultFiltersUrl = EndpointService.webServiceUrl + "/result-filters";

  public static dashboardUrl = EndpointService.webServiceUrl + "/dashboard";

  public static settingsUrl = EndpointService.webServiceUrl + "/settings/";

  public static preferencesUrl = EndpointService.webServiceUrl + "/settings/preferences";

  public static settingsDataUrl = EndpointService.webServiceUrl + "/settings/data";

  public static profilePictureUrl = EndpointService.webServiceUrl + "/settings/profile-picture";

  public static changeAccountInformationUrl = EndpointService.webServiceUrl + "/settings/change-account-info";

  public static updatePaymentInfo = EndpointService.paymentServiceUrl + "/update-payment-info";

  public static getAccountInformationUrl = EndpointService.webServiceUrl + "/settings/account-info";

  public static getAllLocations = EndpointService.accountServiceUrl + "/locations";

  public static selectLocation = EndpointService.authServiceUrl + "/select-location";

  public static getUserLocations = EndpointService.accountServiceUrl + "/user-locations";

  public static checkSelectedLocation = EndpointService.authServiceUrl + "/check-selected-location";

  public static locationSettings = EndpointService.accountServiceUrl + "/location";

  // public static addUser = EndpointService.accountServiceUrl + '/add-user';

  public static userSettings = EndpointService.accountServiceUrl + "/user";

  public static userSettingsDelete = environment.baseURL + "/User/accounts/delete-user";

  public static exportUrl = EndpointService.webServiceUrl + "/export";

  public static massExportUrl = EndpointService.webServiceUrl + "/mass-export";

  public static reportsUrl = EndpointService.webServiceUrl + "/reports";

  public static reportUrl = EndpointService.webServiceUrl + "/report";

  public static generateReportUrl = EndpointService.webServiceUrl + "/generate-report";

  public static athleteDetailsUrl = EndpointService.webServiceUrl + "/report-athletes";

  public static getChartDataUrl = EndpointService.webServiceUrl + "/generate-chart";

  public static forgotPasswordUrl = EndpointService.accountServiceUrl + "/forgot-password";

  public static resetPasswordUrl = EndpointService.accountServiceUrl + "/reset-password";

  public static quickbooksConnect = EndpointService.paymentServiceUrl + "/connect-to-quickbooks";

  public static workoutAddIndividually = environment.baseURL + "/add-workout";

  public static getOrgs = environment.baseURL + "/organizations";

  public static syncForFull = environment.baseURL + "/sync-for-full";

  public static syncForExercises = environment.baseURL + "/sync-for-exercises";

  public static checkLocationsWorkout = environment.baseURL + "/check-locations-workout";

  public static addWorkoutToAll = environment.baseURL + "/add-workout-multiple";

  public static addPlaylistToAll = environment.baseURL + "/sync-playlists-multiple";

  public static addExerciseToAll = environment.baseURL + "/add-exercise-to-all";

  public static updateVideoToAll = environment.baseURL + "/updateExerciseVideo";

  public static addExerciseToLocation = environment.baseURL + "/add-exercise-to-location";
  public static addExercisesToLocation = environment.baseURL + "/add-exercises-to-location";

  public static checkLocationName = environment.baseURL + "/settings/check-location";

  public static downloadAgilityRating = environment.baseURL + "/download-rating";

  public static getAgilityRatingsPaging = environment.baseURL + "/agility-rating";

  public static overviewData = environment.baseURL + "/admin/overviewData";

  public static mostActiveExercise = environment.baseURL + "/most-active";

  public static updateVideos = environment.baseURL + "/update-videos";

  public static exerciseOrganizationsPaginated = environment.baseURL + "/Exercise/organization-paginated";

  public static athletesDeleteMany = environment.baseURL + "/athletes/delete-many";

  public static userToggleStatus = environment.baseURL + "/user/toggle-status";

  public static addMultipleWorkouts = environment.baseURL + "/add-multiple-workouts";

  public static deleteLocation = environment.baseURL + "/locations";

  public static getPaymentHistory = environment.baseURL + "/Payment/get-history";

  public static deleteCreditCard = environment.baseURL + "/Payment/credit-card";

  public static organizationsActiveCount = environment.baseURL + "/organizations/active/count";
}
