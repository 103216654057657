import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AthleteService } from "../services/athlete.service";
import "rxjs/add/operator/debounceTime";
import { ModalTemplate, SuiModalService, TemplateModalConfig } from "ng2-semantic-ui";
import { NotificationService } from "../services/notification.service";
import { SharedAthleteService } from "../services/shared-athlete.service";
import { PlaylistsComponent } from "../playlists/playlists.component";
import { SharedCategoryService } from "../services/shared-category.service";
import { GlobalPreferences } from "../helpers/global-data";
import { Router } from "@angular/router";
import { MixpanelService } from "../services/mixpanel.service";

@Component({
  selector: "app-athletes",
  templateUrl: "./athletes.component.html",
  styleUrls: ["./athletes.component.scss", "../styles.common.scss"],
  providers: [AthleteService, SharedAthleteService, SharedCategoryService]
})
export class AthletesComponent implements OnInit, OnDestroy {
  @ViewChild("confirmModalTemplate")
  confirmModalTemplate: ModalTemplate<string, string, string>;
  @ViewChild("multipleAthleteDeleteModal")
  multipleAthleteDeleteModal: ModalTemplate<string, string, string>;
  @ViewChild("moveCategoryModal")
  moveCategoryModal: ModalTemplate<string, string, string>;
  athleteList = [];
  athleteToDelete;
  possibleFilters = [];
  filters = [];
  selectedFilters = [];
  filtering = false;
  active = true;
  canLoadAthletes = false;
  athletesSelected = [];
  updateSelected = PlaylistsComponent.updateSelected;
  destinationCategory;
  athletesLoaded;
  globalSub;
  athletesSelectedForDelete = [];

  constructor(
    private athleteService: AthleteService,
    private modalService: SuiModalService,
    private sharedAthleteService: SharedAthleteService,
    private notificationService: NotificationService,
    private sharedCategoryService: SharedCategoryService,
    public global: GlobalPreferences,
    private router: Router,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.mixpanelService.track("athlete_screen", {});
    this.globalSub = this.global.dataChange.subscribe(
      (data) => {
        if (this.router.url.indexOf(data.athleteName + "s") == -1) {
          this.router.navigate(["/" + data.athleteName + "s"]);
        }
      },
      (error) => this.notificationService.error(error)
    );
    const previousFilters = JSON.parse(sessionStorage.getItem("athleteFilters"));
    if (previousFilters != null) {
      this.filters = previousFilters.filters;
      this.selectedFilters = previousFilters.selectedFilters;
      this.active = previousFilters.active;
    }
    this.updateAthleteList();
    this.getPossibleFilters();
  }

  // onClick() {
  //     console.log(this.athleteList)
  // }

  updateFilters(key: string, value: any, name: string, type: number) {
    const filter = { key: key, value: value, name: name, type: type };
    for (let i = 0; i < this.filters.length; ++i) {
      if (this.filters[i].value === filter.value) {
        this.filters.splice(i, 1);
        this.selectedFilters.splice(this.selectedFilters.indexOf(value), 1);
        return;
      }
    }
    this.selectedFilters.push(value);
    this.filters.push(filter);
  }

  selectAllPositions(sport) {
    for (const position of sport.positions) {
      this.updateFilters("positionId", position.id, position.name, 0);
    }
    this.updateAthleteList(true);
  }

  updateAthleteList(shouldFilter: boolean = false) {
    this.athletesLoaded = false;
    const noOfAthletes = this.athleteList.length;
    let page = noOfAthletes > 0 ? Math.floor(noOfAthletes / 20) : 0;
    let size;
    if (shouldFilter) {
      this.athleteList = [];
      size = page !== 0 ? 20 * page : 20;
      page = 0;
      sessionStorage.setItem(
        "athleteFilters",
        JSON.stringify({
          selectedFilters: this.selectedFilters,
          filters: this.filters,
          active: this.active
        })
      );
    } else {
      size = 20;
    }

    this.athleteService.getAthletes(page, size, this.constructFilterString()).subscribe(
      (response) => {
        this.athleteList = noOfAthletes > 0 ? this.athleteList.concat(response) : response;
        const canLoadLength = response.length;
        this.canLoadAthletes = canLoadLength % 20 === 0 && canLoadLength !== 0;
        this.athletesLoaded = true;
      },
      (error) => this.notificationService.error(error)
    );
  }

  noInjuries(athlete) {
    return athlete.injuryNameList.length === 0;
  }

  constructFilterString() {
    let filterString = `&active1=${this.active}&`;
    for (const filter of this.filters) {
      filterString += `${filter.key}${filter.type}=${filter.value}&`;
    }
    return filterString.substring(0, filterString.length - 1);
  }

  getPossibleFilters() {
    this.athleteService.getPossibleFilters().subscribe(
      (response) => (this.possibleFilters = response),
      (error) => this.notificationService.error(error)
    );
  }

  deleteAllAthletes() {
    for (const athlete of this.athletesSelected) {
      this.deleteAthlete(athlete.id);
    }
  }

  deleteAthlete(athleteId: string) {
    this.sharedAthleteService.deleteAthlete(athleteId).subscribe(
      () => {
        this.athleteList.splice(this.findAthleteIndex(athleteId), 1);
      },
      (error) => this.notificationService.error(error)
    );
  }

  openMoveModal() {
    this.destinationCategory = [];
    this.modalService.open(new TemplateModalConfig<string, string, string>(this.moveCategoryModal));
  }

  moveToCategory() {
    const athletes = [];
    for (let i = 0; i < this.athletesSelected.length; i++) {
      if (this.athletesSelected[i].categoryId !== this.destinationCategory.id) {
        athletes.push(this.athletesSelected[i].id);
      }
    }
    this.sharedCategoryService
      .moveToCategory({
        type: 0,
        categoryId: this.destinationCategory.id,
        categoryUUID: this.destinationCategory.uuid,
        objects: athletes
      })
      .subscribe(
        () => {
          this.athletesSelected = [];
          this.notificationService.success("Successfully moved athletes.");
        },
        (error) => this.notificationService.error(error)
      );
  }

  setAllActive(active: boolean) {
    for (const athlete of this.athletesSelected) {
      this.setActive(athlete.id, active);
    }
  }

  setActive(athleteId: string, active: boolean) {
    this.athleteService.setActive(athleteId, active).subscribe(
      (response) => {
        this.athleteList.splice(this.findAthleteIndex(athleteId), 1);
      },
      (error) => this.notificationService.error(error)
    );
  }

  findAthleteIndex(athleteId: string): number {
    for (let i = 0; i < this.athleteList.length; i++) {
      if (athleteId === this.athleteList[i].id) {
        return i;
      }
    }
    return -1;
  }

  openConfirmModal(athlete) {
    this.athleteToDelete = {
      id: athlete.id,
      name: athlete.firstName + " " + athlete.lastName
    };
    this.modalService.open(new TemplateModalConfig<string, string, string>(this.confirmModalTemplate));
  }

  ngOnDestroy() {
    this.globalSub.unsubscribe();
    sessionStorage.removeItem("athleteFilters");
  }

  handleAtheleteSelect(id) {
    if (this.athletesSelectedForDelete.includes(id)) {
      this.athletesSelectedForDelete.splice(this.athletesSelectedForDelete.indexOf(id), 1);
    } else {
      this.athletesSelectedForDelete.push(id);
    }
  }

  handleDeleteAthletes() {
    if (!this.athletesSelectedForDelete.length) {
      return;
    }
    this.athleteService.deleteMultipleAthletes(this.athletesSelectedForDelete).subscribe(
      () => {
        this.athletesSelectedForDelete.forEach((id) => {
          this.athleteList.splice(this.findAthleteIndex(id), 1);
        });

        this.athletesSelectedForDelete = [];
      },
      (error) => this.notificationService.error(error)
    );
  }

  handleteMultipleAthleteDeleteModal() {
    this.modalService.open(new TemplateModalConfig<string, string, string>(this.multipleAthleteDeleteModal));
  }
}
