import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FileUploader } from "ng2-file-upload";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SaveAthleteService } from "../services/save-athlete.service";
import { DatePipe } from "@angular/common";
import { feetValidator, floatValidator } from "../helpers/regex-data";
import { NotificationService } from "../services/notification.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ModalTemplate, SuiModalService, TemplateModalConfig } from "ng2-semantic-ui";
import { GlobalPreferences } from "../helpers/global-data";
import { MixpanelService } from "../services/mixpanel.service";
import { DateAdapter, MAT_DATE_FORMATS, MatDatepickerInputEvent } from "@angular/material";
import { AppDateAdapter, APP_DATE_FORMATS } from "../helpers/date-format";
import * as moment from "moment";
import { AuthService } from "../services/auth.service";
import { Subscription } from "rxjs";
import { MessageService } from "../services/message-service";

@Component({
  selector: "app-save-athlete",
  templateUrl: "./save-athlete.component.html",
  styleUrls: ["./save-athlete.component.scss", "../styles.common.scss", "../assets/scss/_saveAthletes.scss"],
  providers: [
    SaveAthleteService,
    DatePipe,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class SaveAthleteComponent implements OnInit, OnDestroy {
  @ViewChild("confirmModalTemplate") confirmModalTemplate: ModalTemplate<string, string, string>;
  @ViewChild("confirmBackModal") confirmBackModal: ModalTemplate<string, string, string>;
  @ViewChild("dobDatepicker") dobDatepicker;
  @ViewChildren("rehabDatePicker") rehabDatePicker: QueryList<any>;
  @ViewChildren("practiceDatePicker") practiceDatePicker: QueryList<any>;
  @ViewChildren("playDatePicker") playDatePicker: QueryList<any>;
  id;
  private sub;
  athleteForm: FormGroup;
  categoryList = [];
  loading = false;
  categoryListTypeahead = null;
  levelList = [];
  sportList = [];
  selectedSport: any = {};
  selectedPosition: any = {};
  selectedLevel: any = {};
  teamList = [];
  teamListTypeahead = null;
  imageUploader: FileUploader;
  image = null;
  imagePreviewUrl = null;
  categoryMode = "select";
  selectedCategory: any = {};
  teamMode = "select";
  selectedTeam: any = {};
  selectedUUID = null;
  foundAthlete = null;
  injuryList = [];
  iPadList;
  activeIpadList = [];
  selectedAthlete: any = {};
  objectToDelete;
  minDate;
  maxDate;
  startDate;
  heightType = 1;
  weightType = 1;
  globalSub;

  mandatoryEmailSubscription: Subscription;

  emailMandatory: boolean;

  metric = false;

  datePickers;
  selectedDatePicker = "rehab";
  dateOfBirth;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private saveAthleteService: SaveAthleteService,
    private router: Router,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer,
    private modalService: SuiModalService,
    public global: GlobalPreferences,
    private mixpanelService: MixpanelService,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.mixpanelService.track("athlete;_save_screen", {});
    this.globalSub = this.global.dataChange.subscribe(
      (data) => {
        if (this.router.url.indexOf("save-" + data.athleteName) == -1) {
          this.router.navigate(["/save-" + data.athleteName]);
        }
      },
      (error) => this.notificationService.error(error)
    );
    this.minDate = new Date(1900, 0, 1);
    const today = new Date();
    const currentYear = new Date().getFullYear();
    today.setDate(today.getDate());
    this.maxDate = today;
    this.startDate = new Date(currentYear - 18, 0, 1);
    this.athleteForm = this.formBuilder.group({
      active: [true],
      firstName: ["", [Validators.required, Validators.maxLength(25), Validators.pattern(/[aA-zZ'-]$/)]],
      lastName: ["", [Validators.required, Validators.maxLength(25), Validators.pattern(/[aA-zZ'-]$/)]],
      category: ["", [Validators.required]],
      dateOfBirth: [""],
      height: [""],
      weight: ["", floatValidator],
      sport: [""],
      position: [""],
      team: [""],
      gender: [],
      level: [""],
      dominantLeg: [],
      email: [""]
    });

    this.messageService.accessMandatoryEmail().subscribe((data) => {
      this.emailMandatory = data;

      if (this.emailMandatory) {
        this.athleteForm.controls["email"].setValidators([Validators.email]);
      }
    });

    this.imageUploader = new FileUploader({
      queueLimit: 1
    });
    this.imageUploader.onAfterAddingFile = (file) => {
      if (file.file.type.indexOf("image") >= 0 && file.file.size <= 10000000) {
        this.image = file.file;
        this.imagePreviewUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file._file));
      } else {
        this.notificationService.warn("Invalid image type or size submitted!");
        this.image = null;
        this.imagePreviewUrl = null;
      }
    };

    this.sub = this.route.params.subscribe(
      (params) => {
        this.id = params["id"];
        if (this.id !== undefined) {
          this.retrieveAthlete();
        } else {
          // this.changeHeightType();
          this.changeWeightType(this.athleteForm.controls["weight"].value);
          this.updateTeamList();
          this.updateCategoryList();
          this.initLists();
        }
      },
      (error) => this.notificationService.error(error)
    );
  }

  initLists() {
    this.saveAthleteService.getIpads().subscribe(
      (response) => (this.iPadList = response),
      (error) => this.notificationService.error(error)
    );
    this.saveAthleteService.getLevels().subscribe(
      (response) => {
        this.levelList = response;
        this.levelList.unshift({
          lastModified: null,
          creationDate: null,
          isDeleted: false,
          serverModification: null,
          id: null,
          uuid: null,
          name: "No Level",
          locationId: null
        });
        let found = false;
        this.levelList.forEach((value) => {
          if (value.id == this.selectedLevel.id && value.id !== null) {
            this.athleteForm.controls["level"].setValue(value);
            found = true;
          }
        });
        if (!found) {
          // this.athleteForm.controls['level'].setValue(this.levelList[0]);
        }
      },
      (error) => this.notificationService.error(error)
    );
    this.saveAthleteService.getSports().subscribe(
      (response) => {
        this.sportList = response;
        this.sportList.unshift({ id: null, name: "No Sport", uuid: null, positions: [] });
        let found = false;
        let foundPosition = false;
        this.sportList.forEach((value) => {
          if (value.id == this.selectedSport.id && this.selectedSport.id != null) {
            this.athleteForm.controls["sport"].setValue(value);
            found = true;
            for (const tempPosition of value.positions) {
              if (tempPosition.id === this.selectedPosition.id) {
                foundPosition = true;
                this.athleteForm.controls["position"].setValue(tempPosition);
                break;
              }
            }
            if (!foundPosition) {
              this.athleteForm.controls["position"].setValue(0);
            }
            this.selectedSport.positions = value.positions;
          }
        });
        if (!found) {
          // this.selectedSport = this.sportList[0];
          // this.athleteForm.controls['sport'].setValue(this.sportList[0]);
          // this.athleteForm.controls['position'].setValue(0);
        }
      },
      (error) => this.notificationService.error(error)
    );
  }

  isClinicSuperUser() {
    return this.authService.checkTokenAccess("CLINIC");
  }

  saveAthlete() {
    this.loading = true;
    // this.removeInvalidInjuries();
    const data = new FormData();
    this.imagePreviewUrl == null ? data.append("file", new Blob()) : data.append("file", this.image.rawFile);
    const athlete: any = Object.assign(this.selectedAthlete, {});
    athlete.firstName = this.athleteForm.controls["firstName"].value;
    athlete.lastName = this.athleteForm.controls["lastName"].value;
    athlete.categoryId = this.selectedCategory.id;
    athlete.categoryUUID = this.selectedCategory.uuid;
    athlete.dateOfBirth = this.datePipe.transform(this.dobDatepicker.nativeElement.value, "MM-dd-yyyy");

    // this was for the old system => save only on cm; if imperial => convert to cm
    // if (this.athleteForm.controls['height'].value !== `0'0"`) {
    //     athlete.height = this.heightType == 1 ? this.feetToCm() : this.athleteForm.controls['height'].value;
    // }
    // if (this.athleteForm.controls['weight'].value !== 0) {
    //     athlete.weight = this.weightType === 1 ?
    //     (Math.round(this.athleteForm.controls['weight'].value * 45.3592) / 100) :
    //     this.athleteForm.controls['weight'].value;
    // }

    // this is for the new system => heightIsMetric = false => salvez imperial

    if (this.athleteForm.controls["height"].value) {
      if (this.heightType == 1) {
        const height = this.athleteForm.controls["height"].value;

        // if (height.indexOf("'") !== -1) {
        const splits = height.split("'");

        const feet = splits[0];

        const secondSplit = splits[1].split(`\"`);
        const inches = secondSplit[0];

        athlete.height = feet + "." + inches;
        athlete.weight = this.athleteForm.controls["weight"].value;
        // }

        // else {
        //     athlete.weight = this.athleteForm.controls['weight'].value;

        // }
      } else {
        athlete.height = this.athleteForm.controls["height"].value;
        athlete.weight = this.athleteForm.controls["weight"].value;
      }
    } else {
      athlete.height = null;
      athlete.weight = null;
    }

    athlete.sportId = this.athleteForm.controls["sport"].value.id;
    athlete.sportUUID = this.athleteForm.controls["sport"].value.uuid;
    if (this.athleteForm.controls["position"].value == 0) {
      athlete.positionId = null;
      athlete.positionUUID = null;
    } else {
      athlete.positionId = this.athleteForm.controls["position"].value.id;
      athlete.positionUUID = this.athleteForm.controls["position"].value.uuid;
    }
    athlete.teamId = this.selectedTeam.id;
    athlete.teamUUID = this.selectedTeam.uuid;
    athlete.gender = this.athleteForm.controls["gender"].value;
    athlete.levelId = this.athleteForm.controls["level"].value.id;
    athlete.levelUUID = this.athleteForm.controls["level"].value.uuid;
    athlete.dominantLeg = this.athleteForm.controls["dominantLeg"].value;
    athlete.email = this.athleteForm.controls["email"].value;
    athlete.profilePicturePath = typeof this.image === "string" ? this.image : null;
    athlete.activeIpads = this.activeIpadList;
    athlete.active = this.athleteForm.controls["active"].value;
    athlete.uuid = this.selectedUUID;
    athlete.workoutIds = [];
    athlete.injuryList = this.injuryList;
    if (this.heightType == 1) {
      athlete.heightIsMetric = false;
    } else {
      athlete.heightIsMetric = true;
    }
    if (this.weightType == 1) {
      athlete.weightIsMetric = false;
    } else {
      athlete.weightIsMetric = true;
    }

    data.append("athlete", new Blob([JSON.stringify(athlete)], { type: "application/json" }));
    data.append("injuryList", new Blob([JSON.stringify(this.injuryList)], { type: "application/json" }));

    this.saveAthleteService.saveAthlete(data).subscribe(
      () => {
        this.loading = false;
        this.router.navigate(["/athletes"]);
        this.notificationService.success("Athlete saved successfully.");
      },
      (error) => {
        this.notificationService.error(error);
        this.loading = false;
      }
    );
  }

  removeInvalidInjuries() {
    const list = [];
    for (const injury of this.injuryList) {
      if (
        injury.type != null &&
        injury.name != null &&
        injury.date != null &&
        injury.icd10 != null &&
        injury.location != null &&
        injury.side != null &&
        injury.returnToPlayDate != null &&
        injury.rehabStartDate != null &&
        injury.returnToPlayDate != null
      ) {
        list.push(injury);
      }
    }
    this.injuryList = list;
  }

  retrieveAthlete() {
    this.saveAthleteService.getAthlete(this.id).subscribe(
      (athlete) => {
        this.foundAthlete = athlete.id;
        this.selectedAthlete = athlete;
        this.selectedCategory.id = athlete.categoryId;
        this.selectedCategory.uuid = athlete.categoryUUID;
        this.selectedTeam.id = athlete.teamId;
        this.selectedTeam.uuid = athlete.teamUUID;
        this.selectedUUID = athlete.uuid;
        this.selectedSport.id = athlete.sportId;
        this.selectedSport.uuid = athlete.sportUUID;
        this.selectedPosition.id = athlete.positionId;
        this.selectedPosition.uuid = athlete.positionUUID;
        this.selectedLevel.id = athlete.levelId;
        this.selectedLevel.uuid = athlete.levelUUID;

        this.initLists();
        this.updateEditTeamList(this.selectedTeam.id);
        this.updateEditCategoryList(this.selectedCategory.id);
        this.athleteForm.controls["firstName"].setValue(athlete.firstName);
        this.athleteForm.controls["lastName"].setValue(athlete.lastName);
        this.dateOfBirth = athlete.dateOfBirth;
        if (this.dateOfBirth !== null) {
          const date = new Date();

          if (this.dateOfBirth.includes("-")) {
            const dateArr = this.dateOfBirth.split("-");
            date.setMonth(dateArr[0] - 1);
            date.setDate(dateArr[1]);
            date.setFullYear(dateArr[2]);

            this.athleteForm.controls["dateOfBirth"].setValue(new Date(date).toISOString());
          } else if (this.dateOfBirth.includes("/")) {
            const dateArr = this.dateOfBirth.split("/");
            date.setMonth(dateArr[0] - 1);
            date.setDate(dateArr[1]);
            date.setFullYear(dateArr[2]);

            this.athleteForm.controls["dateOfBirth"].setValue(new Date(date).toISOString());
          }
        }

        if (athlete.height) {
          if (athlete.heightIsMetric) {
            this.athleteForm.controls["height"].setValue(athlete.height);
            this.heightType = 0;
          } else {
            const parts = athlete.height.toString().split(".");
            const feet = parts[0];
            const inches = parts[1];
            this.athleteForm.controls["height"].setValue(feet + `\'` + inches + `\"`);
          }
        }

        if (athlete.weight) {
          if (athlete.weightIsMetric) {
            this.athleteForm.controls["weight"].setValue(athlete.weight);

            this.weightType = 0;
          } else {
            this.athleteForm.controls["weight"].setValue(athlete.weight);
          }
        }

        this.athleteForm.controls["gender"].setValue(athlete.gender);

        this.image = athlete.profilePicturePath;
        this.athleteForm.controls["dominantLeg"].setValue(athlete.dominantLeg);
        this.athleteForm.controls["email"].setValue(athlete.email);
        this.athleteForm.controls["active"].setValue(athlete.active);
        this.activeIpadList = athlete.activeIpads;
        for (const injury of athlete.injuryList) {
          this.injuryList.push(injury);
        }
      },
      (error) => {
        if (error.status === 400) {
          this.router.navigate(["/save-athlete"]);
        }
        this.notificationService.error(error);
      }
    );
  }

  selectTeam(selectedTeam) {
    for (const team of this.teamList) {
      if (team.name === selectedTeam) {
        this.selectedTeam.id = team.id;
        this.selectedTeam.uuid = team.uuid;
      }
    }
    if (selectedTeam === "No Team") {
      this.selectedTeam.id = null;
      this.selectedTeam.uuid = null;
    }
  }

  addTeam() {
    let found = false;
    const formTeam = this.athleteForm.controls["team"].value.toLowerCase().trim();
    for (const team of this.teamList) {
      if (team.name.toLowerCase().trim() === formTeam) {
        found = true;
        break;
      }
    }
    if (formTeam === "no team") {
      found = true;
    }
    found
      ? this.notificationService.error("This team already exists.")
      : this.saveAthleteService
          .saveTeam({
            name: this.athleteForm.controls["team"].value,
            creationDate: Date.now()
          })
          .subscribe(
            (response) => {
              this.updateTeamList();
              this.selectedTeam.id = response.id;
              this.selectedTeam.uuid = response.uuid;
              this.selectedTeam.name = response.name;
              this.notificationService.success("Team added successfully.");
            },
            (error) => this.notificationService.error(error)
          );
  }

  updateTeam() {
    this.saveAthleteService
      .saveTeam({
        id: this.selectedTeam.id,
        uuid: this.selectedTeam.uuid,
        name: this.athleteForm.controls["team"].value
      })
      .subscribe(
        () => {
          this.updateTeamList();
          this.notificationService.success("Team updated successfully.");
        },
        (error) => this.notificationService.error(error)
      );
  }

  deleteTeam() {
    this.saveAthleteService.deleteTeam(this.selectedTeam.id).subscribe(
      () => {
        this.updateTeamList();
        this.athleteForm.controls["team"].setValue("");
        this.notificationService.success("Team deleted successfully.");
      },
      (error) => (error.status === 409 ? this.notificationService.warn(error) : this.notificationService.error(error))
    );
  }

  updateTeamList() {
    this.saveAthleteService.getTeams().subscribe(
      (response) => {
        this.teamList = response;
        this.teamListTypeahead = this.teamList.map((team) => team.name);
        this.teamListTypeahead.unshift("No Team");
        // this.athleteForm.controls['team'].setValue('No Team');
      },
      (error) => this.notificationService.error(error)
    );
  }

  updateSelectedSport() {
    this.selectedSport = this.athleteForm.controls["sport"].value;
    if (this.selectedSport.positions && this.selectedSport.positions.length > 0) {
      this.athleteForm.controls["position"].setValue(this.selectedSport.positions[0]);
    } else {
      this.athleteForm.controls["position"].setValue(0);
    }
  }

  selectCategory(selectedCategory) {
    for (const category of this.categoryList) {
      if (category.name === selectedCategory) {
        this.selectedCategory.id = category.id;
        this.selectedCategory.uuid = category.uuid;
      }
    }
  }

  addAthleteCategory() {
    let found = false;
    for (const category of this.categoryListTypeahead) {
      if (category.toLowerCase().trim() === this.athleteForm.controls["category"].value.toLowerCase().trim()) {
        found = true;
        break;
      }
    }
    found
      ? this.notificationService.error("This category already exists.")
      : this.saveAthleteService
          .saveCategory({
            name: this.athleteForm.controls["category"].value,
            creationDate: Date.now()
          })
          .subscribe(
            (response) => {
              this.updateCategoryList();
              this.selectedCategory.id = response.id;
              this.selectedCategory.uuid = response.uuid;
              this.selectedCategory.name = response.name;
              this.notificationService.success("Athlete category added successfully.");
            },
            (error) => this.notificationService.error(error)
          );
  }

  updateAthleteCategory() {
    this.saveAthleteService
      .saveCategory({
        id: this.selectedCategory.id,
        uuid: this.selectedCategory.uuid,
        name: this.athleteForm.controls["category"].value,
        active: true
      })
      .subscribe(
        (response) => {
          this.updateCategoryList();
          this.selectedCategory.id = response.id;
          this.selectedCategory.uuid = response.uuid;
          this.notificationService.success("Athlete category updated successfully.");
        },
        (error) => this.notificationService.error(error)
      );
  }

  deleteAthleteCategory() {
    this.saveAthleteService.deleteCategory(this.selectedCategory.id).subscribe(
      () => {
        this.updateCategoryList();
        this.athleteForm.controls["category"].setValue("");
        this.notificationService.success("Athlete category deleted successfully.");
      },
      (error) => (error.status === 409 ? this.notificationService.warn(error) : this.notificationService.error(error))
    );
  }

  updateCategoryList() {
    this.saveAthleteService.getCategories().subscribe(
      (response) => {
        this.categoryList = response;
        this.categoryListTypeahead = this.categoryList.map((category) => category.name);
      },
      (error) => this.notificationService.error(error)
    );
  }

  updateEditCategoryList(categoryId: string) {
    this.saveAthleteService.getCategories().subscribe(
      (response) => {
        this.categoryList = response;
        this.categoryListTypeahead = this.categoryList.map((category) => category.name);
        if (!categoryId) {
          return;
        }
        for (const category of this.categoryList) {
          if (category.id === categoryId) {
            this.athleteForm.controls["category"].setValue(category.name);
            this.selectedCategory.name = category.name;
          }
        }
        if (this.athleteForm.controls["category"].value === "") {
          this.notificationService.error("Could not find the athlete's category.");
        }
      },
      (error) => this.notificationService.error(error)
    );
  }

  updateEditTeamList(teamId: string) {
    this.saveAthleteService.getTeams().subscribe(
      (response) => {
        this.teamList = response;
        this.teamListTypeahead = this.teamList.map((team) => team.name);
        this.teamListTypeahead.unshift("No Team");
        if (teamId == null) {
          // this.athleteForm.controls['team'].setValue('No Team');
          // this.selectedTeam.name = 'No Team';
        } else {
          for (const team of this.teamList) {
            if (team.id === teamId) {
              this.athleteForm.controls["team"].setValue(team.name);
              this.selectedTeam.name = team.name;
            }
          }
        }
      },
      (error) => this.notificationService.error(error)
    );
  }

  checkForSave() {
    return !this.athleteForm.valid || this.categoryMode != "select" || this.teamMode != "select" || this.loading;
  }

  closeCategoryTeam(target) {
    if (target == "category") {
      this.categoryMode = "select";
      this.updateEditCategoryList(this.selectedCategory.id);
    } else {
      this.teamMode = "select";
      this.updateEditTeamList(this.selectedTeam.id);
    }
  }

  openConfirmModal(deletingTeam: boolean = false) {
    if (deletingTeam) {
      this.objectToDelete = {
        name: `team: ${this.selectedTeam.name}`,
        deletingTeam: deletingTeam
      };
    } else {
      this.objectToDelete = {
        name: `category: ${this.selectedCategory.name}`,
        deletingTeam: deletingTeam
      };
    }
    this.modalService.open(new TemplateModalConfig<string, string, string>(this.confirmModalTemplate));
  }

  openBackModal() {
    const config = new TemplateModalConfig<string, string, string>(this.confirmBackModal);
    config.mustScroll = true;
    config.size = "small";
    this.modalService.open(config);
  }

  changeHeightType() {
    if (this.heightType === 1) {
      this.athleteForm.controls["height"].setValidators([feetValidator]);
      if (!this.athleteForm.controls["height"].valid) {
        return;
      }
      const realFeet = this.athleteForm.controls["height"].value;
      let feet = Math.floor(realFeet);
      let inches = Math.round((realFeet - feet) * 12);
      if (inches == 12) {
        feet++;
        inches = 0;
      }
      this.athleteForm.controls["height"].setValue(feet + `\'` + inches + `\"`);
      this.athleteForm.controls["height"].updateValueAndValidity();
      return;
    }
    this.athleteForm.controls["height"].setValidators([floatValidator]);
    if (!this.athleteForm.controls["height"].valid) {
      return;
    }
    this.athleteForm.controls["height"].setValue(this.feetToCm());
    this.athleteForm.controls["height"].updateValueAndValidity();
  }

  public handleHeightInput(value) {
    this.athleteForm.controls["height"].setValue(this.transformHeightUnit(value, this.heightType));
  }

  private transformHeightUnit(value, heightType) {
    const isImperialUnit = new RegExp(/^\d+'(\d+")?$/gm).test(value);

    if (!Boolean(value) || (isNaN(value) && !isImperialUnit)) {
      return "";
    }

    switch (heightType) {
      case 0: //metric
        if (isImperialUnit) {
          const [_, feet, inches]: any = new RegExp(/^(\d+)'(\d+)"$/).exec(value);
          return Math.floor(feet * 30.48 + inches * 2.54);
        }
        return value;
      case 1: //imperial
        if (isImperialUnit) {
          return value;
        }
        let decimalValue = Number(value);
        if (!isImperialUnit && Number.isInteger(decimalValue)) {
          decimalValue = (value * 0.3937) / 12;
        }
        const feet = Math.floor(decimalValue);
        const inches = Math.round((decimalValue - feet) * 12);
        return `${feet || "0"}'${inches || "0"}"`;
    }
  }

  feetToCm(): number {
    const value = this.athleteForm.controls["height"].value.toString().replace(`\"`, "");
    const index = value.indexOf("'");
    const feetInCM = value.slice(0, index) * 30.48;
    const inches = value.slice(index + 1, value.length);
    const inchesInCM = inches.length === 0 ? 0 : inches * 2.54;
    return Math.round((feetInCM + inchesInCM) * 100) / 100;
  }

  changeWeightType(value) {
    if (!this.athleteForm.controls["height"].valid) {
      return;
    }

    this.athleteForm.controls["weight"].setValue(this.transformWeightUnit(value, this.weightType));
    this.athleteForm.controls["weight"].updateValueAndValidity();
  }

  private transformWeightUnit(value, weightType) {
    switch (weightType) {
      case 0: //metric
        return (value / 2.2046).toFixed(2);

      case 1: //imperial
        return Math.round(value * 2.2046);
    }
  }

  changeDateOfBirth(type: string, event: MatDatepickerInputEvent<Date>) {
    this.dateOfBirth = moment(event.value).format("MMM D YYYY");
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.globalSub.unsubscribe();
    // this.mandatoryEmailSubscription.unsubscribe();
  }

  toggleActive() {
    if (this.athleteForm.controls["active"].value) {
      this.athleteForm.controls["active"].setValue(false);
    } else {
      this.athleteForm.controls["active"].setValue(true);
    }
  }
}
