import {Injectable} from '@angular/core';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
    providedIn: 'root'
})
export class MixpanelService {

    constructor() {
        mixpanel.init("1e05dfdcf88be98b40c34d3efce68ccf");
    }

    /**
     * Push new action to mixpanel.
     *
     * @param {string} id Name of the action to track.
     * @param {*} [action={}] Actions object with custom properties.
     * @memberof MixpanelService
     */
    track(id: string, action: any = {}): void {
        // mixpanel.track(id, action);
    }

    identify(name) {
        // mixpanel.identify(name);
    }
}
