import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs/Rx";
import {EndpointService} from './endpoint.service';

@Injectable()
export class RegisterService {

  constructor(private http: HttpClient) {
  }

  register(data): Observable<any> {
    return this.http.put(EndpointService.registerUrl, data);
  }

}
