import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {JwtHelperService} from "@auth0/angular-jwt";
import {LocationsService} from "../services/locations.service";

@Injectable()
export class UserGuard implements CanActivate {
    jwtHelper;

    constructor(private router: Router, private locationService: LocationsService) {
        this.jwtHelper = new JwtHelperService();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const localToken = AuthService.getOauthRefreshTokenFromStorage();
        if (localToken !== null) {
            const token = this.jwtHelper.decodeToken(localToken);
            if (this.jwtHelper.isTokenExpired(localToken, 0)) {
                AuthService.clearStorageData();
                return this.refuse();
            }
            if (token.authorities != null) {
                if (token.authorities.includes('ROLE_USER')) {
                    this.locationService.checkSelectedLocation().subscribe(resp => {
                        if (!resp.body && route.data.name && route.data.name != 'locations')
                            this.router.navigate(['locations']);
                    })
                } else if (token.authorities.includes('ROLE_USER'))
                    return true;
                return true;
            }
        }
        return this.refuse();
    }

    refuse(): boolean {
        this.router.navigate(['/login']);
        return false;
    }
}
