import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EndpointService } from "./endpoint.service";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs/Rx";
import { map } from "rxjs/operators";

@Injectable()
export class AthleteService {
  constructor(private http: HttpClient) {}

  getAthletes(page: number, size: number, filterString: string): Observable<any> {
    // console.log(EndpointService.athletesUrl + `?page=${page}&size=${size}${filterString}`);
    return this.http.get(EndpointService.athletesUrl + `?page=${page}&size=${size}${filterString}`, AuthService.defaultTokenHeader());
  }

  setActive(athleteId: string, active: boolean) {
    return this.http.delete(EndpointService.athleteActiveUrl + `?id=${athleteId}&active=${active}`, AuthService.defaultTokenHeader());
  }

  getPossibleFilters(): Observable<any> {
    return this.http.get(EndpointService.athleteFiltersUrl, AuthService.defaultTokenHeader());
  }

  downloadAgilityRatingByName(name: string): Observable<any> {
    let t = {
      name: "asd"
    };

    const httpOption: Object = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + AuthService.getOauthTokenFromStorage()
      }),
      responseType: "arrayBuffer" as "json"
    };

    return this.http.post<Blob>(EndpointService.downloadAgilityRating + `?name=${name}`, t, httpOption).pipe(
      map((res) => {
        // console.log('asda');
        return {
          filename: "file.pdf",
          data: new Blob([res], { type: "application/pdf" })
        };
      })
    );
  }

  getAthleteById(id: string): Observable<any> {
    return this.http.get(EndpointService.athleteUrl + `?id=${id}`, AuthService.defaultTokenHeader());
  }

  getAgilityRatingByIdPaging(athleteId: string, start, end, page: number, size: number): Observable<any> {
    return this.http.get(
      EndpointService.getAgilityRatingsPaging +
        `?athleteId=${athleteId}&startTimestamp=${start}&endTimestamp=${end}` +
        `&page=${page}&size=${size}`,
      AuthService.defaultTokenHeader()
    );
  }

  deleteMultipleAthletes(ids: string[]) {
    const searchParams = new URLSearchParams();
    ids.forEach((id) => searchParams.append("id", id));
    return this.http.delete(`${EndpointService.athletesDeleteMany}?${searchParams.toString()}`);
  }
}
