import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {EndpointService} from './endpoint.service';
import {Observable} from "rxjs/Rx";
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SaveAthleteService {

  constructor(private http: HttpClient) {
  }

  getAthlete(id: string): Observable<any> {
    return this.http.get(EndpointService.athleteUrl + `?id=${id}`, AuthService.defaultTokenHeader());
  }

  saveAthlete(data): Observable<any> {
    return this.http.post(EndpointService.athleteUrl, data, AuthService.defaultTokenHeader());
  }

  getIpads(): Observable<any> {
    return this.http.get(EndpointService.iPadsUrl, AuthService.defaultTokenHeader());
  }

  getLevels(): Observable<any> {
    return this.http.get(EndpointService.athleteLevelsUrl, AuthService.defaultTokenHeader());
  }

  getSports(): Observable<any> {
    return this.http.get(EndpointService.sportsUrl, AuthService.defaultTokenHeader());
  }

  getTeams(): Observable<any> {
    return this.http.get(EndpointService.teamsUrl, AuthService.defaultTokenHeader());
  }

  saveTeam(data): Observable<any> {
    return this.http.post(EndpointService.teamUrl, data, AuthService.defaultTokenHeader());
  }

  deleteTeam(teamId: string): Observable<any> {
    return this.http.delete(EndpointService.teamUrl + `?id=${teamId}`, AuthService.defaultTokenHeader());
  }

  getCategories(): Observable<any> {
    return this.http.get(EndpointService.athleteCategoriesUrl, AuthService.defaultTokenHeader());
  }

  saveCategory(data): Observable<any> {
    return this.http.post(EndpointService.athleteCategoryUrl, data, AuthService.defaultTokenHeader());
  }

  deleteCategory(categoryId: string): Observable<any> {
    return this.http.delete(EndpointService.athleteCategoryUrl + `?id=${categoryId}`, AuthService.defaultTokenHeader());
  }
}
