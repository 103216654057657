import { Injectable } from "@angular/core";
import { Subject, Observable, BehaviorSubject } from "rxjs";


@Injectable()
export class MessageService {

    private addedLocation = new BehaviorSubject(false);

    private mandatoryEmail = new BehaviorSubject(false);

    sendMessage(msg) {
        // it is used to publish data
         this.addedLocation.next(msg);
    }
    accessMessage(): Observable<any> {
        // asObservable helps us to prevent the
        // leaks of Observable from outside of the subject
        return this.addedLocation.asObservable();
    }

    sendMandatoryEmail(msg) {
        this.mandatoryEmail.next(msg);
    }

    accessMandatoryEmail(): Observable<any> {
        return this.mandatoryEmail.asObservable();
    }
    
}