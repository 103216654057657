import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'format'})

export class FormatPipe implements PipeTransform {

  /**
   * Formats a sum into a readable format (Ex: 26000 becomes 26,000.00)
   *
   * @param {number} sum the sum to be formatted
   * @param {string[]} floatpt , the first element in this array will represent the floating point precision of the format
   * @returns {any} the formatted string
   */
  transform(sum: number, floatpt: number): string {
    let c, s, i, j, strsum: string;
    const d = '.', t = ',';
    c = isNaN(c = Math.abs(c)) ? floatpt : c;
    s = sum < 0 ? '-' : '';
    i = String(parseInt(strsum = Math.abs(Number(sum) || 0).toFixed(c), 10));
    j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : '') + i.substr(j)
      .replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(sum - i).toFixed(c).slice(2) : '');
  }

}
