import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EndpointService } from "./endpoint.service";
import { AuthService } from "./auth.service";
import { Observable, Subject } from "rxjs/Rx";

@Injectable()
export class SettingsService {
  public currentUser: any;
  public currentLocation: any;
  public userAction: string;
  public locationAction: string;
  public userActionModal: any;
  public locationActionModal: any;

  constructor(private http: HttpClient) {}

  getData(): Observable<any> {
    return this.http.get(EndpointService.settingsDataUrl, AuthService.defaultTokenHeader());
  }

  getInvoices(): Observable<any> {
    return this.http.get(EndpointService.invoicesUrl, AuthService.defaultTokenHeader());
  }

  getUsers(): Observable<any> {
    return this.http.get(EndpointService.settingsUsersUrl, AuthService.defaultTokenHeader());
  }

  updateProfilePicture(data): Observable<any> {
    return this.http.post(EndpointService.profilePictureUrl, data, AuthService.defaultTokenHeader());
  }

  updatePassword(data): Observable<any> {
    return this.http.post(EndpointService.updatePasswordUrl, data, AuthService.defaultTokenHeader());
  }

  changeAccountInformation(data): Observable<any> {
    return this.http.post(EndpointService.changeAccountInformationUrl, data, AuthService.defaultTokenHeader());
  }

  getAccountInformation(): Observable<any> {
    return this.http.get(EndpointService.getAccountInformationUrl, AuthService.defaultTokenHeader());
  }

  updatePaymentInfo(param: { cardNo: any; firstName; lastName; expiration: string; cvv: any }) {
    return this.http.post(EndpointService.updatePaymentInfo, param, AuthService.defaultTokenHeader());
  }

  connectQuickbooks(): Observable<any> {
    return this.http.get(EndpointService.quickbooksConnect, AuthService.defaultTokenHeader());
  }

  getAllLocations(): Observable<any> {
    return this.http.get(EndpointService.getAllLocations, AuthService.defaultTokenHeader());
  }

  addUser(user: any): Observable<any> {
    return this.http.post(EndpointService.userSettings, user, AuthService.defaultTokenHeader());
  }

  deleteUser(id: any): Observable<any> {
    return this.http.request("delete", EndpointService.userSettingsDelete, { headers: AuthService.setTokenHeader(), body: { body: id } });
  }

  addLocation(value: any) {
    return this.http.post(EndpointService.locationSettings, value, AuthService.defaultTokenHeader());
  }

  editLocation(value: any) {
    return this.http.put(EndpointService.locationSettings, value, AuthService.defaultTokenHeader());
  }

  checkLocationName(name: string) {
    return this.http.get(EndpointService.checkLocationName + `?name=${name}`, AuthService.defaultTokenHeader());
  }

  editUser(user: { id: any; firstName: any; lastName: any; password: any; email: any; locations: any[] }) {
    return this.http.put(EndpointService.userSettings, user, AuthService.defaultTokenHeader());
  }

  updateUserPreferences(mandatoryEmail: boolean) {
    let body = {
      emailMandatory: mandatoryEmail
    };
    return this.http.post(EndpointService.settingsUrl + "user-preferences", body, AuthService.defaultTokenHeader());
  }

  putUserToggleStatus(userId: string) {
    return this.http.put(EndpointService.userToggleStatus + `?userId=${userId}`, {}, AuthService.defaultTokenHeader());
  }

  deleteLocation(locationId: string, action: any, newLocationId?: string) {
    const params = { action };
    if (newLocationId) {
      Object.assign(params, { newLocationId });
    }
    return this.http
      .delete(`${EndpointService.deleteLocation}/${locationId}`, { params, headers: AuthService.setTokenHeader() })
      .toPromise();
  }

  getPaymentHistory(body) {
    return this.http.post(EndpointService.getPaymentHistory, body, AuthService.defaultTokenHeader());
  }
}
