import {Injectable} from '@angular/core';
import {EndpointService} from './endpoint.service';
import {Observable} from "rxjs/Rx";
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SaveWorkoutService {

  constructor(private http: HttpClient) {
  }

  saveWorkout(data): Observable<any> {
    return this.http.post(EndpointService.workoutUrl, data, AuthService.defaultTokenHeader());
  }

  getWorkout(id: string) {
    return this.http.get(EndpointService.saveWorkoutUrl + `?id=${id}`, AuthService.defaultTokenHeader());
  }

  getAthletes(page, size): Observable<any> {
    return this.http.get(EndpointService.workoutAthletesUrl + `?page=${page}&size=${size}`, AuthService.defaultTokenHeader());
  }

}
