import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CountryService } from "../services/country.service";
import { RegisterService } from "../services/register.service";
import { ActivatedRoute, Router } from "@angular/router";
import { monthVector, yearVector } from "../helpers/calendar-data";
import { ProductService } from "../services/product.service";
import { ModalTemplate, SuiModalService, TemplateModalConfig } from "ng2-semantic-ui";
import { map, take } from "rxjs/operators";
import { LoginService } from "../services/login.service";
import {
  cardValidator,
  emailValidator,
  minLength7Validator,
  nameValidator,
  passwordValidator,
  phoneValidator,
  usernameValidator
} from "../helpers/regex-data";
import { NotificationService } from "../services/notification.service";
import { NotifyParentService } from "../services/notify-parent.service";
import { timer } from "rxjs/index";
import { finalize } from "rxjs/internal/operators";
import { SharedRegisterService } from "../services/shared-register.service";
import * as moment from "moment";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss", "../styles.common.scss"],
  providers: [RegisterService, CountryService, ProductService, LoginService, SharedRegisterService]
})
export class RegisterComponent implements OnInit {
  @ViewChild("processingModal")
  processingModal: ModalTemplate<string, string, string>;
  @ViewChild("termsModal")
  termsModal: ModalTemplate<string, string, string>;
  currentStep = 1;
  personalDetailsForm: FormGroup;
  addressForm: FormGroup;
  shippingInformationForm: FormGroup;
  billingInformationForm: FormGroup;
  billingAddressForm: FormGroup;
  userError = null;
  addressObject = { countries: [], states: [], cities: [], typeahead: [] };
  shippingObject = { countries: [], states: [], typeahead: [] };
  billingObject = { countries: [], states: [], typeahead: [] };
  productList = null;
  productBoughtList = null;
  totalFee: number;
  totalFeeWithShipping: number = 0;
  termsAgreed = false;
  registerSuccess;
  registerError = null;
  yearVector: string[];
  currentMonthVector: string[];
  useMonthVector = false;
  selectedMonth: string;
  selectedYear: string;
  codeAttempt = "";
  couponWillExpireOn: any = "";
  currentCoupon: any = {};
  couponSuccess = null;
  activeCoupons = [];
  countdown;
  userInfo;
  accountType = 0;
  alreadyExists = true;
  subscriptionList = [];
  subscriptionAllList = [];
  selectedSubscription;
  collapsed = true;
  shippingCosts = 0;
  allCountries = [];
  subscriptionTotal = 0; // 0 - monthly 1 - annually
  totalDiscountsAnnually = 0;

  get monthVector() {
    return monthVector;
  }

  constructor(
    private countryService: CountryService,
    private registerService: RegisterService,
    private productService: ProductService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: SuiModalService,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private notifyParent: NotifyParentService,
    private sharedRegisterService: SharedRegisterService,
    private route: ActivatedRoute
  ) {}

  static variableCvvValidator() {
    return (group: FormGroup) => {
      const cardNo = (group.controls["cardNumber"].value && group.controls["cardNumber"].value.toString()) || "";
      const cvv = (group.controls["CVV"].value && group.controls["CVV"].value.toString()) || "";

      if (cardNo.startsWith("37") || cardNo.startsWith("34")) {
        if (cvv.length !== 3 && cvv.length !== 4) {
          group.controls["CVV"].setErrors({ invalidAmexCVV: true, invalidCVV: false });
        }
      } else {
        if (cvv.length !== 3) {
          group.controls["CVV"].setErrors({ invalidCVV: true, invalidAmexCVV: false });
        }
      }
    };
  }

  static checkMatchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: FormGroup) => {
      if (group.controls[passwordKey].value !== group.controls[confirmPasswordKey].value) {
        group.controls[confirmPasswordKey].setErrors({ mismatchedPasswords: true });
      }
    };
  }

  ngOnInit() {
    const storedData = JSON.parse(sessionStorage.getItem("formData"));
    if (storedData != null) {
      this.userInfo = storedData;
    }
    this.accountType = getProperty(this.userInfo, "accountType", 0);
    this.personalDetailsForm = this.formBuilder.group(
      {
        organizationName: [getProperty(this.userInfo, "organizationName", ""), [Validators.required]],
        firstName: [getProperty(this.userInfo, "customer.firstName", ""), [Validators.required, nameValidator]],
        lastName: [getProperty(this.userInfo, "customer.lastName", ""), [Validators.required, nameValidator]],
        email: [getProperty(this.userInfo, "customer.email", ""), [Validators.required, emailValidator]],
        username: [getProperty(this.userInfo, "user.username", ""), [Validators.required, minLength7Validator, usernameValidator]],
        password: [getProperty(this.userInfo, "user.password", ""), [Validators.required, passwordValidator]],
        confirmPassword: [getProperty(this.userInfo, "user.password", ""), [Validators.required, passwordValidator]]
      },
      {
        validator: RegisterComponent.checkMatchingPasswords("password", "confirmPassword")
      }
    );
    this.addressForm = this.formBuilder.group({
      country: [getProperty(this.userInfo, "address.country", ""), [Validators.required]],
      address: [getProperty(this.userInfo, "address.address", ""), [Validators.required]],
      address2: [getProperty(this.userInfo, "address.address2", ""), []],
      city: [getProperty(this.userInfo, "address.city", ""), [Validators.required]],
      state: [getProperty(this.userInfo, "address.state", ""), [Validators.required]],
      zipCode: [getProperty(this.userInfo, "address.zipCode", ""), [Validators.required]],
      phone: [getProperty(this.userInfo, "customer.phone", ""), [Validators.required, phoneValidator]]
    });
    this.shippingInformationForm = this.formBuilder.group({
      firstName: [getProperty(this.userInfo, "customer.firstName", ""), [Validators.required, nameValidator]],
      lastName: [getProperty(this.userInfo, "customer.lastName", ""), [Validators.required, nameValidator]],
      address: [getProperty(this.userInfo, "address.address", ""), [Validators.required]],
      address2: [getProperty(this.userInfo, "address.address2", ""), []],
      country: [getProperty(this.userInfo, "address.country", ""), [Validators.required]],
      city: [getProperty(this.userInfo, "address.city", ""), [Validators.required]],
      state: [getProperty(this.userInfo, "address.state", ""), [Validators.required]],
      zipCode: [getProperty(this.userInfo, "address.zipCode", ""), [Validators.required]]
    });
    this.billingInformationForm = this.formBuilder.group(
      {
        firstName: ["", [Validators.required, nameValidator]],
        lastName: ["", [Validators.required, nameValidator]],
        cardNumber: ["", [Validators.required, cardValidator]],
        CVV: ["", [Validators.required]]
      },
      { validator: RegisterComponent.variableCvvValidator() }
    );
    this.billingAddressForm = this.formBuilder.group({
      address: [getProperty(this.userInfo, "address.address", ""), [Validators.required]],
      address2: [getProperty(this.userInfo, "address.address2", ""), []],
      country: [getProperty(this.userInfo, "address.country", ""), [Validators.required]],
      city: [getProperty(this.userInfo, "address.city", ""), [Validators.required]],
      state: [getProperty(this.userInfo, "address.state", ""), []],
      zipCode: [getProperty(this.userInfo, "address.zipCode", ""), [Validators.required]]
    });

    if (this.userInfo != null) {
      this.alreadyExists = true;
      this.finalStep();
    }

    this.getCountries(this.addressObject, this.addressForm, true);
    this.productService.getRegisterProducts().subscribe(
      (response) => {
        this.productList = response.products;
        this.productList.forEach((product) => (product.exactPrice = 0));
        this.subscriptionAllList = response.subscriptions;
        response.subscriptions.forEach((value) => {
          if (value.targetUserType == 0) {
            this.subscriptionList.push(value);
          }
        });
        // this.subscriptionList = response.subscriptions;
        if (this.subscriptionList.length === 0) {
          this.notificationService.error("No subscriptions are currently available.");
        }

        this.subscriptionList.forEach((el) => {
          el.discountAnnuallyApplied = false;
        });
        this.selectedSubscription = getProperty(this.userInfo, "selectedSubscription", this.firstAvailableSubscription());
        this.selectedSubscription.discountAnnuallyApplied = false;
        this.subscriptionTotal = this.selectedSubscription.price;
        this.initTotal();

        this.route.queryParams.subscribe(({ order, product }) => {
          if (order >= 4 && order <= 7) {
            this.updateSubscriptionTotal(this.subscriptionList.find((el) => el.order == order));
          }

          if (product >= 0 && product <= 2) {
            const index = Number(product);
            this.productList[index].exactQuantity = 1;
            this.updateProductTotal(index, this.productList[index]);
          }
        });
      },
      (error) => this.notificationService.error("An error occurred while retrieving the products: " + error.error.message)
    );

    const activeCoupons = JSON.parse(sessionStorage.getItem("activeCoupons"));
    if (activeCoupons) {
      this.activeCoupons = activeCoupons;
      const currentCoupon = activeCoupons.at(-1);
      this.codeAttempt = currentCoupon.code;
      this.couponSuccess = 0;

      const [coupon] = activeCoupons;

      if (coupon.currency === "days") {
        this.currentCoupon = coupon;

        this.couponWillExpireOn = moment().add(coupon.discount, "days").toDate();
      }
    }
  }

  firstAvailableSubscription() {
    for (let i = 0; i < this.subscriptionAllList.length; i++) {
      if (this.subscriptionAllList[i].active) {
        return this.subscriptionAllList[i];
      }
    }
    this.notificationService.error("No subscriptions are currently available.");
    return null;
  }

  checkUsername() {
    const username = this.personalDetailsForm.controls["username"].value;
    if (username.length > 6) {
      this.sharedRegisterService
        .validUser(this.personalDetailsForm.controls["username"].value)
        .then((nameTaken) => {
          this.alreadyExists = nameTaken !== undefined;
        })
        .catch((error) => {
          error.status === 409 ? (this.alreadyExists = false) : this.notificationService.error(error);
        });
    }
  }

  omit_special_char(event) {
    let k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return k >= 48 && k <= 57;
  }

  getCountries(locationObject, form: FormGroup, initialize: boolean = false) {
    this.countryService.getAllCountries().subscribe(
      (response) => {
        locationObject.countries = response;
        this.allCountries = response;
        form.controls["country"].setValue(getProperty(this.userInfo, "address.country", response[230].id));
        this.countryHasChanged(locationObject, form, initialize);
      },
      (error) => this.notificationService.error("Country error: " + error.error.message)
    );
  }

  countryHasChanged(locationObject, form: FormGroup, initialize: boolean = false) {
    this.countryService.getStatesByCountry(form.controls["country"].value).subscribe(
      (response) => {
        locationObject.states = response;
        form.controls["state"].setValue(getProperty(this.userInfo, "address.state", response[0].id));
        this.stateHasChanged(locationObject, form, initialize);
      },
      (error) => this.notificationService.error("State error: " + error.error.message)
    );
    if (this.productBoughtList) {
      this.shippingCosts = 0;
      this.computeShippingCosts();
    }
  }

  stateHasChanged(locationObject, form: FormGroup, initialize: boolean = false) {
    this.countryService.getCitiesByState(form.controls["state"].value).subscribe(
      (response) => {
        locationObject.cities = response;
        if (initialize) {
          const nameToAdd = form.controls["city"].value;
          let added = false;
          for (let i = 0; i < locationObject.cities.length; i++) {
            if (nameToAdd == locationObject.cities[i].name) {
              added = true;
              break;
            }
            if (nameToAdd < locationObject.cities[i].name) {
              locationObject.cities.splice(i, 0, { name: form.controls["city"].value });
              added = true;
              break;
            }
          }
          if (!added) {
            locationObject.cities.push({ name: form.controls["city"].value });
          }
        }
        locationObject.typeahead = response.map((city) => city.name);
      },
      (error) => this.notificationService.error("State error: " + error.error.message)
    );
  }

  finalStep() {
    this.currentStep = 4;
    this.yearVector = yearVector();
    this.currentMonthVector = Object.assign([], monthVector);
    this.currentMonthVector.splice(-12, new Date().getMonth());
    this.selectedMonth = this.currentMonthVector[0];
    this.selectedYear = this.yearVector[0];

    this.getCountries(this.shippingObject, this.shippingInformationForm, true);
    this.getCountries(this.billingObject, this.billingAddressForm, true);
  }

  saveProgress() {
    this.userInfo = {
      organizationName: this.personalDetailsForm.controls["organizationName"].value,
      customer: {
        firstName: this.personalDetailsForm.controls["firstName"].value,
        lastName: this.personalDetailsForm.controls["lastName"].value,
        email: this.personalDetailsForm.controls["email"].value,
        phone: this.addressForm.controls["phone"].value
      },
      address: {
        address: this.addressForm.controls["address"].value,
        address2: this.addressForm.controls["address2"].value,
        country: this.addressForm.controls["country"].value,
        state: this.addressForm.controls["state"].value,
        city: this.addressForm.controls["city"].value,
        zipCode: this.addressForm.controls["zipCode"].value
      },
      user: {
        username: this.personalDetailsForm.controls["username"].value,
        password: this.personalDetailsForm.controls["password"].value
      },
      accountType: this.accountType,
      selectedSubscription: this.selectedSubscription
    };
    this.shippingInformationForm.controls["firstName"].setValue(this.personalDetailsForm.controls["firstName"].value);
    this.shippingInformationForm.controls["lastName"].setValue(this.personalDetailsForm.controls["lastName"].value);
    this.completeForm(this.shippingInformationForm);
    this.completeForm(this.billingAddressForm);
    sessionStorage.setItem("formData", JSON.stringify(this.userInfo));
  }

  completeForm(form: FormGroup) {
    form.controls["address"].setValue(this.addressForm.controls["address"].value);
    form.controls["address2"].setValue(this.addressForm.controls["address2"].value);
    form.controls["country"].setValue(this.addressForm.controls["country"].value);
    form.controls["city"].setValue(this.addressForm.controls["city"].value);
    form.controls["state"].setValue(this.addressForm.controls["state"].value);
    form.controls["zipCode"].setValue(this.addressForm.controls["zipCode"].value);
  }

  initTotal() {
    this.totalFee = this.subscriptionTotal;
    this.shippingCosts = 0;
    this.totalFeeWithShipping = this.totalFee;
    this.updateSubscriptionTotal(this.selectedSubscription);
    for (const product of this.productList) {
      this.totalFee += product.exactPrice;
    }
  }

  updateProductTotal(index: number, product) {
    if (!this.productBoughtList) {
      this.productBoughtList = [];
    }
    this.productBoughtList[index] = product;
    const fee = product.exactQuantity * product.price;
    this.totalFee -= this.productList[index].exactPrice;
    this.productList[index].exactPrice = fee;
    this.totalFee += fee;
    this.shippingCosts = 0;
    this.computeShippingCosts();
    this.totalFeeWithShipping = this.totalFee + this.shippingCosts - this.totalDiscountsAnnually;
    // this.updateSubscriptionTotal(this.selectedSubscription);
    // console.log(this.totalFeeWithShipping);
    this.reinitializeCoupons();
  }

  reinitializeProducts(index: number, product) {
    if (!this.productBoughtList) {
      this.productBoughtList = [];
    }
    this.productBoughtList[index] = product;
    const fee = product.exactQuantity * product.price;
    // this.totalFee -= this.productList[index].exactPrice;
    this.productList[index].exactPrice = fee;
    this.totalFee += fee;
    this.shippingCosts = 0;
    this.computeShippingCosts();
    this.totalFeeWithShipping = this.totalFee + this.shippingCosts - this.totalDiscountsAnnually;
    // this.reinitializeCoupons();
  }

  updateSubscriptionTotal(subscription: any, event?) {
    if (event) {
      // stop the button click event from triggering the div click event
      event.stopPropagation();
    }
    this.totalDiscountsAnnually = 0;

    this.totalFee = 0;
    this.totalFeeWithShipping = 0;
    this.shippingCosts = 0;
    if (subscription.discountAnnuallyApplied) {
      this.subscriptionTotal = subscription.price * 12;
      this.totalDiscountsAnnually += subscription.discountAnnually;
    } else {
      this.subscriptionTotal = subscription.price;
    }

    this.selectedSubscription = subscription;
    this.totalFee += this.subscriptionTotal;
    this.totalFeeWithShipping = this.totalFee + this.shippingCosts;
    if (subscription.discountAnnuallyApplied) {
      this.totalFeeWithShipping -= subscription.discountAnnually;
    }
    if (this.productBoughtList) {
      this.productBoughtList.forEach((value, index) => {
        this.reinitializeProducts(index, value);
      });
    }
    this.reinitializeCoupons();
  }

  validateDate() {
    if (this.selectedYear !== this.yearVector[0]) {
      this.useMonthVector = true;
    } else {
      this.useMonthVector = false;
      this.selectedMonth = this.currentMonthVector[0];
    }
  }

  applyCoupon(coupon) {
    this.currentCoupon = coupon;

    if (coupon.currency === "days") {
      this.couponWillExpireOn = moment().add(coupon.discount, "days").toDate();
    }

    let couponCanBeApplied = true;
    this.activeCoupons.forEach((value) => {
      coupon.validProducts.forEach((product) => {
        if (value.validProducts.indexOf(product) > -1) {
          couponCanBeApplied = false;
        }
      });
    });
    if (!couponCanBeApplied) {
      this.couponSuccess = 3;
      return false;
    }
    if (coupon.perOrder) {
      if (coupon.currency === "$") {
        this.totalFeeWithShipping -= coupon.discount;
      } else if (coupon.currency === "%") {
        this.totalFeeWithShipping -= (coupon.discount / 100) * this.totalFeeWithShipping;
      }
    } else {
      coupon.validProducts.forEach((value) => {
        if (value == this.selectedSubscription.id) {
          if (coupon.currency === "$") {
            this.totalFeeWithShipping -= coupon.discount;
          } else if (coupon.currency === "%") {
            if (this.selectedSubscription.discountAnnuallyApplied) {
              this.totalFeeWithShipping -=
                (coupon.discount / 100) * this.selectedSubscription.price * 12 + this.selectedSubscription.discountAnnually;
            } else {
              this.totalFeeWithShipping -= (coupon.discount / 100) * this.selectedSubscription.price;
            }
          } else if (coupon.currency === "days") {
            this.totalFeeWithShipping -= this.selectedSubscription.price;
          }
        }
        if (this.productBoughtList) {
          this.productBoughtList.forEach((product) => {
            if (product.id == value) {
              if (coupon.currency === "$") {
                this.totalFeeWithShipping -= coupon.discount * product.exactQuantity;
              } else {
                this.totalFeeWithShipping -= (coupon.discount / 100) * product.price * product.exactQuantity;
              }
            }
          });
        }
      });

      if (!coupon.validProducts.includes(this.selectedSubscription.id) && this.activeCoupons.length) {
        this.checkValidCoupon();
      }
    }
    if (Number(this.totalFeeWithShipping) < 0) {
      this.totalFeeWithShipping = 0;
    }
    this.activeCoupons.push(coupon);
    sessionStorage.setItem("activeCoupons", JSON.stringify(this.activeCoupons));
    return true;
  }

  reinitializeCoupons() {
    this.activeCoupons.forEach((coupon) => {
      // console.log(coupon);
      if (coupon.perOrder) {
        if (coupon.currency === "$") {
          this.totalFeeWithShipping -= coupon.discount;
        } else if (coupon.currency === "%") {
          this.totalFeeWithShipping -= (coupon.discount / 100) * this.totalFeeWithShipping;
        }
      } else {
        coupon.validProducts.forEach((value) => {
          if (value == this.selectedSubscription.id) {
            if (coupon.currency === "$") {
              this.totalFeeWithShipping -= coupon.discount;
            } else if (coupon.currency === "%") {
              if (this.selectedSubscription.discountAnnuallyApplied) {
                this.totalFeeWithShipping -= (coupon.discount / 100) * this.selectedSubscription.price * 12;
              } else {
                this.totalFeeWithShipping -= (coupon.discount / 100) * this.selectedSubscription.price;
              }
            } else if (coupon.currency === "days") {
              this.totalFeeWithShipping -= this.selectedSubscription.price;
            }
          }
          if (this.productBoughtList) {
            this.productBoughtList.forEach((product) => {
              if (product.id == value) {
                if (coupon.currency === "$") {
                  this.totalFeeWithShipping -= coupon.discount * product.exactQuantity;
                } else {
                  this.totalFeeWithShipping -= (coupon.discount / 100) * product.price * product.exactQuantity;
                }
              }
            });
          }
        });

        if (!coupon.validProducts.includes(this.selectedSubscription.id) && this.activeCoupons.length) {
          this.checkValidCoupon();
        }
      }
    });

    if (Number(this.totalFeeWithShipping) < 0) {
      this.totalFeeWithShipping = 0;
    }
  }

  getProductIds() {
    const productIds = [this.selectedSubscription.id];

    for (const item of this.productList) {
      if (item.products) {
        for (const product of item.products) {
          if (product.exactPrice !== 0) {
            productIds.push(product.id);
          }
        }
      } else {
        productIds.push(item.id);
      }
    }

    return productIds;
  }

  removeCoupon() {
    this.codeAttempt = "";
    this.currentCoupon = "";
    this.activeCoupons = [];
    this.couponSuccess = null;
    this.totalFeeWithShipping = this.subscriptionTotal;
    sessionStorage.removeItem("activeCoupons");
  }

  checkValidCoupon() {
    this.couponSuccess = null;

    this.sharedRegisterService
      .validCoupon({
        // todo rewrite
        code: this.codeAttempt.trim(),
        productIds: this.getProductIds()
      })
      .subscribe(
        (coupon) => {
          if (coupon != null) {
            if (!this.couponAlreadyExists(coupon)) {
              if (this.applyCoupon(coupon)) {
                this.couponSuccess = 0;
              }
            } else {
              this.couponSuccess = 2;
            }
          } else {
            this.codeAttempt = "";
            this.couponSuccess = 1;
          }
        },
        (error) => {
          this.couponSuccess = 1;
          this.notificationService.error(error);
        }
      );
  }

  couponAlreadyExists(coupon): boolean {
    for (const foundCoupon of this.activeCoupons) {
      if (foundCoupon.id === coupon.id) {
        return true;
      }
    }
    return false;
  }

  register() {
    const config = new TemplateModalConfig<string, string, string>(this.processingModal);
    config.isClosable = false;
    config.size = "mini";
    config.mustScroll = true;
    const modal = this.modalService.open(config);
    this.registerSuccess = null;
    const purchasedItems = [];
    for (const product of this.productList) {
      if (product.exactPrice > 0) {
        purchasedItems.push({
          productId: product.id,
          price: product.exactPrice,
          quantity: product.exactQuantity
        });
      }
    }
    const couponsIds = [];
    if (this.activeCoupons && this.activeCoupons.length > 0) {
      this.activeCoupons.forEach((value) => {
        couponsIds.push(value.id);
      });
    }
    this.registerService
      .register({
        user: {
          username: this.personalDetailsForm.controls["username"].value.toLowerCase().trim(),
          password: this.personalDetailsForm.controls["password"].value,
          firstName: this.personalDetailsForm.controls["firstName"].value.trim(),
          lastName: this.personalDetailsForm.controls["lastName"].value.trim(),
          email: this.personalDetailsForm.controls["email"].value.toLowerCase().trim(),
          phoneNo: this.addressForm.controls["phone"].value.trim()
        },
        organizationName: this.personalDetailsForm.controls["organizationName"].value.trim(),
        accountType: this.accountType,
        billingAddress: {
          firstName: this.shippingInformationForm.controls["firstName"].value.trim(),
          lastName: this.shippingInformationForm.controls["lastName"].value.trim(),
          addressLine1: this.billingAddressForm.controls["address"].value.trim(),
          addressLine2: this.billingAddressForm.controls["address2"].value.trim(),
          country: this.billingAddressForm.controls["country"].value,
          state: this.billingAddressForm.controls["state"].value,
          city: this.billingAddressForm.controls["city"].value,
          zipCode: this.billingAddressForm.controls["zipCode"].value.trim()
        },
        shippingAddress: {
          firstName: this.shippingInformationForm.controls["firstName"].value.trim(),
          lastName: this.shippingInformationForm.controls["lastName"].value.trim(),
          addressLine1: this.shippingInformationForm.controls["address"].value.trim(),
          addressLine2: this.shippingInformationForm.controls["address2"].value.trim(),
          country: this.shippingInformationForm.controls["country"].value,
          state: this.shippingInformationForm.controls["state"].value,
          city: this.shippingInformationForm.controls["city"].value,
          zipCode: this.shippingInformationForm.controls["zipCode"].value.trim()
        },
        organizationAddress: {
          addressLine1: this.addressForm.controls["address"].value.trim(),
          addressLine2: this.addressForm.controls["address2"].value.trim(),
          country: this.addressForm.controls["country"].value,
          state: this.addressForm.controls["state"].value,
          city: this.addressForm.controls["city"].value,
          zipCode: this.addressForm.controls["zipCode"].value.trim()
        },
        creditCard: {
          cardNo: this.billingInformationForm.controls["cardNumber"].value,
          firstName: this.billingInformationForm.controls["firstName"].value.trim(),
          lastName: this.billingInformationForm.controls["lastName"].value.trim(),
          expiration: this.selectedMonth.toString().substring(0, 2) + this.selectedYear.toString().substring(2),
          cvv: this.billingInformationForm.controls["CVV"].value
        },
        subscriptionId: this.selectedSubscription.id,
        discountAnnuallyApplied: this.selectedSubscription.discountAnnuallyApplied,
        coupons: couponsIds,
        purchasedItems: purchasedItems
      })
      .subscribe(
        () => {
          this.registerSuccess = true;
          sessionStorage.clear();
          let countdownSeconds = 10;
          this.countdown = timer(0, 1000).pipe(
            take(countdownSeconds),
            map(() => --countdownSeconds),
            finalize(() => this.redirectToDashboard(modal))
          );
        },
        (error) => {
          this.registerSuccess = false;
          if (error.status === 400) {
            const message = error.error.message;
            message.indexOf("Failed") >= 0
              ? (this.registerError = message.substring(message.lastIndexOf("Failed"), message.indexOf('","path')))
              : message.indexOf("already exists") >= 0
              ? (this.registerError = "An account with this name already exists!")
              : (this.registerError = "The server is currently busy. Please try again later.");
          } else {
            this.registerError = "An error occurred: " + error.status + ": " + error.error.message;
          }
        }
      );
  }

  redirectToDashboard(modal) {
    this.loginService
      .login(this.personalDetailsForm.controls["username"].value, this.personalDetailsForm.controls["password"].value)
      .then((response) => {
        this.loginService.setTokens(true, response["access_token"], response["refresh_token"]);
        modal.approve(null);
        this.router.navigate(["/"]);
        this.notifyParent.sendData("fullUpdate");
      })
      .catch(() => {});
  }

  openTermsModal() {
    const config = new TemplateModalConfig<string, string, string>(this.termsModal);
    config.size = "large";
    config.mustScroll = true;
    this.modalService.open(config);
  }

  changeAccountType(type) {
    this.accountType = type;
    this.subscriptionList = [];
    this.subscriptionAllList.forEach((value) => {
      if (value.targetUserType == type) {
        this.subscriptionList.push(value);
      }
    });
  }

  getContinentByCountryName(country) {
    let found = null;
    this.allCountries.forEach((value) => {
      if (value.id == country) {
        found = value.continent;
      }
    });
    return found;
  }

  computeShippingCosts() {
    const continent = this.getContinentByCountryName(this.addressForm.controls["country"].value);
    this.productBoughtList.forEach((value) => {
      let shippingCostPerProduct = value.shippingCost[continent];
      if (shippingCostPerProduct) {
        this.shippingCosts += shippingCostPerProduct * value.exactQuantity;
      } else {
        shippingCostPerProduct = value.shippingCost["Others"];
        this.shippingCosts += shippingCostPerProduct * value.exactQuantity;
      }
    });
  }
}

function getProperty(obj, props, defaultValue): any {
  let res;
  const isvoid = function (x) {
    return typeof x === "undefined" || x === null;
  };
  if (!isvoid(obj)) {
    if (isvoid(props)) {
      props = [];
    }
    if (typeof props === "string") {
      props = props.trim().split(".");
    }
    if (props.constructor === Array) {
      res = props.length > 1 ? getProperty(obj[props.shift()], props, defaultValue) : obj[props[0]];
    }
  }
  return typeof res === "undefined" ? defaultValue : res;
}
