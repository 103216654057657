import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EndpointService} from './endpoint.service';
import {Router} from '@angular/router';
import {Observable} from "rxjs/Rx";
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable()
export class AuthService {
    jwtHelper;

    constructor(private http: HttpClient, private router: Router) {
        this.jwtHelper = new JwtHelperService();
    }

    static getOauthTokenFromStorage() {
        let token = localStorage.getItem('token');
        return token != null ? token : sessionStorage.getItem('token');
    }

    static getOauthRefreshTokenFromStorage() {
        let token = localStorage.getItem('refresh_token');
        return token != null ? token : sessionStorage.getItem('refresh_token');
    }

    static getDeviceIDFromStorage() {
        let deviceID = localStorage.getItem('deviceID');
        return deviceID != null ? deviceID : sessionStorage.getItem('deviceID');
    }

    static clearStorageData() {
        AuthService.removeOauthTokenFromStorage();
        AuthService.removeOauthRefreshTokenFromStorage();
        AuthService.removeDeviceIDFromStorage();
    }

    static removeOauthTokenFromStorage() {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
    }

    static removeOauthRefreshTokenFromStorage() {
        localStorage.removeItem('refresh_token');
        sessionStorage.removeItem('refresh_token');
    }

    static removeDeviceIDFromStorage() {
        localStorage.removeItem('deviceID');
        sessionStorage.removeItem('deviceID');
    }

    static defaultTokenHeader() {
        return {headers: AuthService.setTokenHeader()};
    }

    static setTokenHeader(): HttpHeaders {
        return new HttpHeaders()
            .set('Authorization', 'Bearer ' + AuthService.getOauthTokenFromStorage());
    }

    loggedIn() {
        return !this.jwtHelper.isTokenExpired(AuthService.getOauthRefreshTokenFromStorage());
    }

    checkTokenAccess(role: string): boolean {
        const local_token = AuthService.getOauthTokenFromStorage();
        if (local_token !== null) {
            const token = this.jwtHelper.decodeToken(local_token);
            if (token.authorities != null) {
                return !!token.authorities.includes('ROLE_' + role);
            }
        }
        return false;
    }

    checkAcconutType(): string {
        const local_token = AuthService.getOauthTokenFromStorage();
        if (local_token !== null) {
            const token = this.jwtHelper.decodeToken(local_token);
            if (token.accountType != null) {
                return token.user_name === 'twolves' || token.accountType == 1 ? "CLINIC" : "PERFORMANCE";
            }
        }
        return "";
    }

    logout() {
        const tkn = AuthService.getOauthTokenFromStorage();
        const deviceId: string = AuthService.getDeviceIDFromStorage();
        return this.http.post(EndpointService.logoutUrl,null,AuthService.defaultTokenHeader()).subscribe(() => {
            AuthService.clearStorageData();
            this.router.navigate(['/login']);
        }, () => {
            AuthService.clearStorageData();
            this.router.navigate(['/login']);
        });
    }

    getUserSettings(): Observable<any> {
        return this.http.get(EndpointService.settingsUrl, AuthService.defaultTokenHeader());
    }

    getPreferences(): Observable<any> {
        return this.http.get(EndpointService.preferencesUrl, AuthService.defaultTokenHeader());
    }

    attemptPayment(data: any): Observable<any> {
        return this.http.post(EndpointService.attemptPaymentUrl, data, AuthService.defaultTokenHeader());
    }
}
