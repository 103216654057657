import { Injectable, Injector } from "@angular/core";
import { EndpointService } from "./endpoint.service";
import { AuthService } from "./auth.service";
import { BehaviorSubject, EMPTY, Observable, throwError } from "rxjs/index";
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest
} from "@angular/common/http";
import { Router } from "@angular/router";
import { catchError, filter, finalize, map, switchMap, take } from "rxjs/internal/operators";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  jwtHelper;

  constructor(private router: Router, private inj: Injector, private authService: AuthService) {
    this.jwtHelper = new JwtHelperService();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(response => {
        if (response.status === 401) {
          this.isRefreshingToken = true;
          return this.refreshToken().pipe(
            map(
              token_data => {
                localStorage.setItem("token", token_data["access_token"]);
                localStorage.setItem("refresh_token", token_data["refresh_token"]);
                const token = this.jwtHelper.decodeToken(token_data["access_token"]);
                if (token != null) {
                  localStorage.setItem("deviceID", token.deviceID);
                }
                this.tokenSubject.next("done");
                return this.reformHeaders(request);
              },
              error => {
                return throwError(error);
              }
            ),
            switchMap(req => next.handle(req)),
            finalize(() => {
              this.isRefreshingToken = false;
            })
          );
        }

        if (!(response instanceof HttpErrorResponse)) {
          return throwError(response);
        }

        if (request.url.indexOf("logout") > -1) {
          AuthService.clearStorageData();
          this.router.navigate(["/login"]);
          return EMPTY;
        }

        if (response.error.error === "invalid_grant" && response.error.error_description.indexOf("Invalid refresh token") > -1) {
          this.authService.logout();
          return EMPTY;
        }

        if (this.jwtHelper.isTokenExpired(AuthService.getOauthRefreshTokenFromStorage())) {
          return EMPTY;
        }

        if (this.isRefreshingToken) {
          return this.tokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(token => {
              return next.handle(this.reformHeaders(request));
            })
          );
        }

        if (response.status !== 401) {
          return throwError(response);
        }
      })
    );
  }

  refreshToken(): Observable<any> {
    return this.http.post(
      EndpointService.tokenUrl,
      new HttpParams().set("grant_type", "refresh_token").set("refresh_token", AuthService.getOauthRefreshTokenFromStorage()),
      { headers: new HttpHeaders().set("Authorization", "Basic YnJvd3Nlcjo=") }
    );
  }

  reformHeaders(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      headers: new HttpHeaders().set("Authorization", "Bearer " + AuthService.getOauthTokenFromStorage())
    });
  }

  public get http(): HttpClient {
    return this.inj.get(HttpClient);
  }
}
