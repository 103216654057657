import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalTemplate, SuiModalService, TemplateModalConfig} from 'ng2-semantic-ui';
import { matRangeDatepickerRangeValue } from 'mat-range-datepicker';
import * as moment from 'moment';

@Component({
    selector: 'app-activity-data',
    templateUrl: './activity-data.component.html',
    styleUrls: ['./activity-data.component.scss', '../styles.common.scss', '../athletes/athletes.component.scss']
})
export class ActivityDataComponent implements OnInit {

    @ViewChild('activityDataModalTemplate')
    activityDataModalTemplate: ModalTemplate<string, string, string>;
    startDate: Date;
    endDate: Date;

    filtering = false;

    constructor(private modalService: SuiModalService) { }

    ngOnInit() {}

    changeDate(type: string, event: any) {
        this.startDate = moment(event.value.begin).toDate();
        this.endDate = moment(event.value.end).toDate();
    }

    createActivityData() {
        const config = new TemplateModalConfig<string, string, string>(this.activityDataModalTemplate);
        config.size = 'large';
        this.modalService.open(config);
    }
}
