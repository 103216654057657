import { OnInit, Component } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ExerciseService } from "../../services/exercise.service";
import { Subject } from "rxjs";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: "app-sync-exercise",
  templateUrl: "./sync-exercise.component.html",
  styleUrls: ["./sync-exercise.component.scss", "../../styles.common.css"],
  providers: [ExerciseService]
})
export class SyncExerciseComponent implements OnInit {
  showData: boolean;
  updateVideos: boolean;
  exerciseId: string[];
  paired: string;
  organizations: any = [];
  canLoadOrganizations: boolean;
  collapseFlag = false;
  organizationsLoaded = false;
  checkFinished = false;
  searchText: string = "";
  syncExerciseArray: any = [];
  syncExerciseArrayCopy: any = [];
  private organizationName: Subject<string> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private exerciseService: ExerciseService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(({ exerciseId, exercisePaired, updateVideos }) => {
      if (exerciseId instanceof Array) {
        this.exerciseId = exerciseId;
      } else {
        this.exerciseId = [exerciseId];
      }

      this.paired = exercisePaired;

      this.updateVideos = updateVideos;
    });

    this.getOrganizations();

    this.organizationName.debounceTime(1000).subscribe(
      () => {
        const page = 0;
        const size = 20;

        this.exerciseService[this.updateVideos ? "getVideoOrganization" : "getExercisesOrganization"](
          page,
          size,
          this.exerciseId,
          this.searchText
        ).subscribe((data) => {
          this.organizations = data;

          const canLoadLength = data.length;
          this.canLoadOrganizations = canLoadLength % 20 === 0 && canLoadLength !== 0;
          this.showData = true;

          this.syncExerciseArray = [];
          const rows = this.getArraySize(this.organizations)[0];
          const cols = this.getArraySize(this.organizations)[1];
          this.syncExerciseArray = Array.from({ length: rows }, () => Array.from({ length: cols }, () => false));
        });
      },
      (error) => {
        this.notificationService.error(error);
      }
    );
  }

  getArraySize(arr) {
    const rowCount = arr.length;
    const rowSizes = [];
    for (let i = 0; i < rowCount; i++) {
      rowSizes.push(arr[i].locationExerciseList.length);
    }
    return [rowCount, rowSizes.reduce((a, b) => Math.max(a, b))];
  }

  onSearch(search: string) {
    this.organizationName.next(search);
  }

  getOrganizations() {
    const orgsLength = this.organizations.length;

    const page = orgsLength > 0 ? Math.floor(orgsLength / 20) : 0;
    const size = 20;
    this.exerciseService[this.updateVideos ? "getVideoOrganization" : "getExercisesOrganization"](
      page,
      size,
      this.exerciseId,
      this.searchText
    ).subscribe(
      (data) => {
        for (const org of data) {
          this.organizations.push(org);
        }

        const canLoadLength = data.length;
        this.canLoadOrganizations = canLoadLength % 20 === 0 && canLoadLength !== 0;
        this.showData = true;
        this.organizationsLoaded = true;

        if (this.syncExerciseArray != null) {
          for (let i = 0; i < this.syncExerciseArray.length; i++) {
            for (let j = 0; j < this.syncExerciseArray[i].length; j++) {
              this.syncExerciseArrayCopy[i][j] = this.syncExerciseArray[i][j];
            }
          }
        }

        const rows = this.getArraySize(this.organizations)[0];
        const cols = this.getArraySize(this.organizations)[1];

        this.syncExerciseArray = Array.from({ length: rows }, () => Array.from({ length: cols }, () => false));

        if (this.syncExerciseArrayCopy != null) {
          for (let i = 0; i < this.syncExerciseArrayCopy.length; i++) {
            for (let j = 0; j < this.syncExerciseArrayCopy[i].length; j++) {
              this.syncExerciseArray[i][j] = this.syncExerciseArrayCopy[i][j];
            }
          }
        }

        this.syncExerciseArrayCopy = Array.from({ length: rows }, () => Array.from({ length: cols }, () => false));
      },
      (error) => {
        this.notificationService.error(error);
      }
    );
  }

  onSelectCollapse() {
    this.collapseFlag = !this.collapseFlag;
  }

  addExerciseToAll() {
    this.exerciseService.addExerciseToAll(this.exerciseId).subscribe(
      (data) => {
        this.router.navigate(["/exercises"]);
        this.notificationService.success(data);
      },
      (error) => {
        this.notificationService.error(error);
      }
    );
  }

  syncExercise(loc, i, j) {
    this.syncExerciseArray[i][j] = true;
    this.exerciseService.addExercisesToLocation(this.exerciseId, loc.id).subscribe(
      (data) => {},
      (error) => this.notificationService.error(error)
    );
  }

  handleVideoUpdate(locationId, i, j) {
    this.exerciseService.updateVideos(this.exerciseId, [locationId]).subscribe(
      (status) => {
        if (status) {
          this.notificationService.success("Update successfull");
        } else {
          this.notificationService.error("Error!");
        }
      },
      (error) => this.notificationService.error(error)
    );

    this.syncExerciseArray[i][j] = true;
  }
}
