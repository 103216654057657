import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable()
export class CodeGuard implements CanActivate {
  jwtHelper;

  constructor(private router: Router) {
    this.jwtHelper = new JwtHelperService();
  }

  canActivate() {
    const localToken = AuthService.getOauthRefreshTokenFromStorage();
    if (localToken !== null) {
      if (this.jwtHelper.isTokenExpired(localToken, 0)) {
        AuthService.clearStorageData();
        return this.refuse();
      }
      const token = this.jwtHelper.decodeToken(localToken);
      if (token.authorities != null) {
        return (token.authorities.includes('ROLE_PRE_AUTH') || token.authorities.includes('ROLE_ADMIN_PRE_AUTH') ? this.refuse() : true);
      }
    }
    return true;
  }

  refuse(): boolean {
    this.router.navigate(['/login']);
    return false;
  }
}
