import {Injectable} from '@angular/core';
import {Observable} from "rxjs/Rx";
import {EndpointService} from './endpoint.service';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SharedPlaylistService {

  constructor(private http: HttpClient) {
  }

  getExerciseDetails(page: number, size: number, exercisesToFilter, search: string = ''): Observable<any> {
    const details = search == '' ? `?page=${page}&size=${size}` : `?page=${page}&size=${size}&search=${search}`;
    return this.http.post(EndpointService.exerciseDetailsUrl + details, exercisesToFilter,
      AuthService.defaultTokenHeader());
  }

}
