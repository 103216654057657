import {Injectable} from '@angular/core';
import {AuthService} from "./auth.service";
import {EndpointService} from "./endpoint.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/Rx";

@Injectable({
    providedIn: 'root'
})
export class LocationsService {

    constructor(private http: HttpClient) {
    }

    checkSelectedLocation(): Observable<any>  {
        return this.http.get(EndpointService.checkSelectedLocation, AuthService.defaultTokenHeader());

    }

    selectUserLocations(): Observable<any> {
        return this.http.get(EndpointService.getUserLocations, AuthService.defaultTokenHeader());
    }

    selectLocation(id: any) {
        return this.http.post(EndpointService.selectLocation, {body: id}, AuthService.defaultTokenHeader());

    }
}
