import {Component, OnInit} from '@angular/core';
import {ResetPasswordService} from "../services/reset-password.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../services/auth.service";
import {NotificationService} from "../services/notification.service";
import {ActivatedRoute} from "@angular/router";
import {passwordValidator} from "../helpers/regex-data";
import {RegisterComponent} from "../register/register.component";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    resetForm: FormGroup;

    constructor(private authService: AuthService, private resetPasswordService: ResetPasswordService,
                private formBuilder: FormBuilder, private notificationService: NotificationService,
                private activeRoute: ActivatedRoute) {
    }

    get loggedIn() {
        return this.authService.loggedIn();
    }

    ngOnInit() {
        this.resetForm = this.formBuilder.group({
            password: ['', [Validators.required, passwordValidator]],
            password2: ['', [Validators.required, passwordValidator]]
        }, {
            validator: RegisterComponent.checkMatchingPasswords('password', 'password2')
        });
    }

    reset() {
        this.resetPasswordService.resetPassword(this.resetForm.controls['password'].value, this.activeRoute.snapshot.params['token']).subscribe(response => {
            if (response['body']) {
                this.notificationService.success("Thank you, password changed. You can login now.");
            } else {
                this.notificationService.error("Sorry, invalid token or token expired");
            }

        })

    }

}
