import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalSize, ModalTemplate, SuiModalService, TemplateModalConfig} from 'ng2-semantic-ui';
import {NotificationService} from "../services/notification.service";
import {ReportService} from "../services/report.service";
import {PlaylistsComponent} from "../playlists/playlists.component";

declare var jQuery: any;

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss', '../styles.common.scss', '../athletes/athletes.component.scss'],
    providers: [ReportService]
})
export class ReportsComponent implements OnInit {
    active = true;
    creatingReport = false;
    reports: any;
    height = window.innerHeight - 100;
    selectedReport: any;
    @ViewChild('addReportTemplate')
    addReportTemplate: ModalTemplate<string, string, string>;
    @ViewChild('reportModalTemplate')
    reportModalTemplate: ModalTemplate<string, string, string>;
    searchAddAthlete = '';
    filteredAthleteList = [];
    addAthletesSelected = [];
    athleteList = [];
    filterTimeout;
    canLoadAthleteList;
    searching = false;
    type;

    constructor(private modalService: SuiModalService, private notificationService: NotificationService,
                private reportService: ReportService) {
    }

    ngOnInit() {
        this.updateReports();
        this.getAthletes();
    }

    updateReports() {
        this.reportService.getReports().subscribe(response => {
            this.reports = response;
        }, error => this.notificationService.error(error));
    }

    updateSelected(value, array) {
        return PlaylistsComponent.updateSelected(value, array);
    }

    openGenerateModal() {
        this.type = 0;
        this.addAthletesSelected = [];
        this.searchAddAthlete = '';
        this.filteredAthleteList = Object.assign([], this.athleteList);
        const config = new TemplateModalConfig<string, string, string>(this.addReportTemplate);
        config.mustScroll = true;
        this.modalService.open(config);
    }

    openReportModal(report: any) {
        this.selectedReport = report;
        const config = new TemplateModalConfig<string, string, string>(this.reportModalTemplate);
        config.mustScroll = true;
        config.isFullScreen = true;
        this.modalService.open(config);
    }

    generateReport() {
        this.creatingReport = true;
        this.reportService.generateReport({
            athleteIds: this.addAthletesSelected,
            type: this.type
        }).subscribe(() => {
            this.notificationService.success("Reports generated successfully");
            this.creatingReport = false;
            this.updateReports();
        }, error => {
            this.notificationService.error(error);
            this.creatingReport = false;
        });
    }

    deleteReport(id: number, i: number) {
        this.reportService.deleteReport(id).subscribe(() => {
            this.reports.splice(i, 1);
            this.notificationService.success("Report deleted successfully.");
        }, error => this.notificationService.error(error));
    }

    filterAthleteList(value) {
        if (this.filterTimeout) {
            clearTimeout(this.filterTimeout);
        }
        this.filterTimeout = setTimeout(() => {
            if (value.length === 0) {
                this.filteredAthleteList = Object.assign([], this.athleteList);
                const length = this.athleteList.length;
                this.canLoadAthleteList = length % 20 === 0 && length !== 0;
                return;
            }
            if (value.length < 2) {
                return;
            }
            this.searching = true;
            this.reportService.getAthletes(0, 20, value).subscribe((response: any) => {
                this.filteredAthleteList = response;
                const length = response.length;
                this.canLoadAthleteList = length % 20 === 0 && length !== 0;
                this.searching = false;
            }, error => {
                this.notificationService.error(error);
                this.searching = false;
            });
        }, 350);
    }

    getAthletes() {
        const length = this.athleteList.length;
        const page = length > 0 ? Math.floor(length / 20) : 0;
        const size = 20;
        this.reportService.getAthletes(page, size, this.searchAddAthlete).subscribe(response => {
            if (this.searchAddAthlete == '') {
                for (const athlete of response) {
                    this.athleteList.push(athlete);
                }
                this.filteredAthleteList = Object.assign([], this.athleteList);
            } else {
                for (const athlete of response) {
                    this.filteredAthleteList.push(athlete);
                }
            }
            const athletes = response.length;
            this.canLoadAthleteList = athletes % 20 === 0 && athletes !== 0;
        }, error => this.notificationService.error(error));
    }

}
