import { Validators } from "@angular/forms";

export const nameValidator = Validators.pattern("^[A-Za-z \\u00C0-\\u00FF\\u0100-\\u0280'-]*$");

export const floatValidator = Validators.pattern("^\\d+(\\.\\d{1,2})?$");

export const emailValidator = Validators.pattern(
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))' +
    "@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$"
);

export const phoneValidator = Validators.pattern("^[0-9-+s()]*$");

export const minLength7Validator = Validators.minLength(7);

export const usernameValidator = Validators.pattern("^[a-zA-Z\\-0-9]*$");

export const passwordValidator = Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");

export const codeValidator = Validators.pattern("^[0-9 ]+$");

export const cardValidator = Validators.pattern(
  "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])" +
    "[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$"
);

export const feetValidator = Validators.pattern(/^\d+'(\d+")?$/gm);
