import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { emailValidator, nameValidator, passwordValidator, usernameValidator, minLength7Validator } from "../../helpers/regex-data";
import { RegisterComponent } from "../../register/register.component";
import { SettingsService } from "../../services/settings.service";
import { SharedRegisterService } from "../../services/shared-register.service";
import { NotificationService } from "../../services/notification.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-user-action",
  templateUrl: "./user-action.component.html",
  styleUrls: ["./user-action.component.scss"]
})
export class UserActionComponent implements OnInit {
  userForm: FormGroup;
  selectedLocations = [];
  locations = [];
  type = 0;
  user: any;
  action: string;
  alreadyExists = 0;
  errorLocations = false;
  loading = false;

  constructor(
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    private sharedRegisterService: SharedRegisterService,
    private notificationService: NotificationService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.action = this.settingsService.userAction;
    this.user = this.settingsService.currentUser;
    if (this.action == "Add") {
      this.userForm = this.formBuilder.group({
        firstName: ["", [Validators.required, nameValidator]],
        lastName: ["", [Validators.required, nameValidator]],
        username: ["", [Validators.required, usernameValidator, minLength7Validator]],
        email: ["", [Validators.required, emailValidator]],
        type: [""],
        location: ["", Validators.required]
      });
    } else {
      this.userForm = this.formBuilder.group(
        {
          firstName: ["", [Validators.required, nameValidator]],
          lastName: ["", [Validators.required, nameValidator]],
          email: ["", [Validators.required, emailValidator]],
          password: ["", [passwordValidator]],
          confirmPassword: ["", [passwordValidator]],
          location: ["", Validators.required]
        },
        {
          validator: RegisterComponent.checkMatchingPasswords("password", "confirmPassword")
        }
      );
    }

    if (this.user.firstName && this.user.firstName.length > 0) {
      this.userForm.controls["firstName"].setValue(this.user.firstName);
      this.userForm.controls["lastName"].setValue(this.user.lastName);
      this.userForm.controls["email"].setValue(this.user.email);
    }

    this.settingsService.getAllLocations().subscribe((response) => {
      this.locations = response.body;
      this.selectedLocations = [];
      this.user.activeLocations.forEach((value) => {
        this.locations.forEach((location) => {
          if (location.id == value) this.selectedLocations.push(location);
        });
      });
      this.userForm.controls["location"].setValue(this.selectedLocations);
    });
  }

  update() {
    this.errorLocations = false;
    this.loading = true;

    // if(this.selectedLocations.filter((value: any) => {return value.isInitial;}).length == 1 && this.settingsService.currentUser.isOwner){
    //     this.errorLocations = true;
    //     return;
    // }
    let locationIds = [];
    this.userForm.controls["location"].value.forEach((value) => {
      locationIds.push(value.id);
    });
    if (locationIds.length == 0) {
      this.errorLocations = true;
      return;
    }
    if (this.settingsService.userAction == "Edit") {
      this.settingsService
        .editUser({
          id: this.user.id,
          firstName: this.userForm.controls["firstName"].value,
          lastName: this.userForm.controls["lastName"].value,
          password: this.userForm.controls["password"].value,
          email: this.userForm.controls["email"].value,
          locations: locationIds
        })
        .subscribe((response) => {
          this.notificationService.success("User edited!");
          this.settingsService.userActionModal.approve("done");
        });
    } else {
      // console.log(locationIds)
      this.settingsService
        .addUser({
          firstName: this.userForm.controls["firstName"].value,
          lastName: this.userForm.controls["lastName"].value,
          username: this.userForm.controls["username"].value,
          email: this.userForm.controls["email"].value,
          password: "",
          type: this.userForm.controls["type"].value,
          locations: locationIds
        })
        .subscribe((response) => {
          this.loading = false;
          this.notificationService.success("User added!");
          this.settingsService.userActionModal.approve("done");
        });
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.userForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  checkUsername() {
    this.sharedRegisterService
      .validUser(this.userForm.controls["username"].value)
      .then(() => {
        this.alreadyExists = 1;
      })
      .catch((error) => {
        error.status === 409 ? (this.alreadyExists = -1) : this.notificationService.error(error);
      });
  }

  selectedLocationsNotNull() {
    return this.selectedLocations.length == 0;
  }

  checkingValue(): boolean {
    if (!this.userForm.valid && this.selectedLocations.length == 0) {
      return true;
    }
    return false;
  }

  // onCLick() {
  //     console.log(this.userForm.valid);
  //     console.log(this.selectedLocations.length)
  // }
}
