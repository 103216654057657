import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EndpointService } from "./endpoint.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs/Rx";

@Injectable()
export class LoginService {
  jwtHelper;

  constructor(private http: HttpClient) {
    this.jwtHelper = new JwtHelperService();
  }

  setTokens(rememberMe: boolean, acc_token: string, ref_token: string) {
    if (rememberMe) {
      localStorage.setItem("token", acc_token);
      localStorage.setItem("refresh_token", ref_token);
      const token = this.jwtHelper.decodeToken(ref_token);
      if (token != null) {
        localStorage.setItem("deviceID", token.deviceID);
      }
      return;
    }

    sessionStorage.setItem("token", acc_token);
    sessionStorage.setItem("refresh_token", ref_token);
    const token = this.jwtHelper.decodeToken(ref_token);
    if (token != null) {
      sessionStorage.setItem("deviceID", token.deviceID);
    }
  }

  requestOauthToken(username: string, password: string, code: string): Promise<any> {
    let data: HttpParams;
    data = new HttpParams().set("scope", "ui").set("username", username).set("password", password).set("grant_type", "password");

    if (code !== null) {
      data = data.append("code", code);
    }

    const header = new HttpHeaders().set("Authorization", "Basic YnJvd3Nlcjo=");

    return this.http.post(EndpointService.tokenUrl, data, { headers: header }).toPromise();
  }

  login(username: string, password: string): Promise<any> {
    return this.requestOauthToken(username, password, null);
  }

  login2FA(username: string, password: string, code: string): Promise<any> {
    return this.requestOauthToken(username, password, code);
  }
}
