import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SaveExerciseService } from "../services/save-exercise.service";
import { SharedExerciseService } from "../services/shared-exercise.service";
import { delayArray, flashArray } from "../helpers/exercise-data";
import { NotificationService } from "../services/notification.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalTemplate, SuiModalService, TemplateModalConfig } from "ng2-semantic-ui";
import { FileUploader } from "ng2-file-upload";
import { MixpanelService } from "../services/mixpanel.service";

@Component({
  selector: "app-save-exercise",
  templateUrl: "./save-exercise.component.html",
  styleUrls: ["./save-exercise.component.scss", "../styles.common.scss"],
  providers: [SaveExerciseService, SharedExerciseService]
})
export class SaveExerciseComponent implements OnInit, OnDestroy {
  @ViewChild("confirmModalTemplate")
  confirmModalTemplate: ModalTemplate<string, string, string>;
  @ViewChild("confirmBackModal") confirmBackModal: ModalTemplate<string, string, string>;
  id;
  uuid;
  private sub;
  categoryMode = "select";
  categoryInput = "";
  reactSensors = [];
  sequenceSensors = [];
  agilitySensors = [];
  exerciseCategories;
  categoryOptions;
  delayArray = delayArray;
  arrowDelayArray = this.delayArray.slice(1, this.delayArray.length);
  flashArray = flashArray;
  react = [];
  dontReact = [];
  mainForm: FormGroup;
  countForm: FormGroup;
  reactForm: FormGroup;
  sequenceForm: FormGroup;
  agilityForm: FormGroup;
  verticalForm: FormGroup;
  additionalValues = {
    boardType: 0,
    favorited: false,
    paired: false,
    creationDate: null
  };
  selectedSettings: any = {};
  videoUploader: FileUploader;
  video = null;
  loading = false;
  selectedCategoryIsUserCreated;
  poppedUp = false;
  countdownSeconds = new Array(60).fill(0, 0, 60).map((x, i) => i);
  countdownSecondsDelay = new Array(60).fill(0, 0, 60).map((x, i) => i);
  secondsDelayDropdownModel;
  secondsDropdownModel;
  isActive: boolean = true;
  numberOfLegs: number;

  constructor(
    private route: ActivatedRoute,
    private saveExerciseService: SaveExerciseService,
    private router: Router,
    private sharedExerciseService: SharedExerciseService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private modalService: SuiModalService,
    private mixpanelService: MixpanelService
  ) {}

  static startValueValidation() {
    return (group: FormGroup) => {
      if (group.controls["startType"].value === 1 && !group.controls["startValue"].value) {
        group.controls["startValue"].setErrors({ startValueRequired: true });
      }
    };
  }

  static neurocognitiveValidation(react, dontReact, inactiveSensors) {
    return (group: FormGroup) => {
      switch (group.controls["cognitiveReact"].value) {
        case 1:
          const sensorsLength = 5 - inactiveSensors.length - 1;
          if (react.length !== 1 || dontReact.length !== (sensorsLength || 1)) {
            group.controls["cognitiveReact"].setErrors({ invalidNeurocognitive: true });
          }
          break;
        case 2:
          const reactLength = react.length;
          const dontReactLength = dontReact.length;
          if (reactLength < 1 || dontReactLength < 1) {
            group.controls["cognitiveReact"].setErrors({ invalidNeurocognitive: true });
          }
          break;
      }
    };
  }

  static activeSensorValidation(reactSensors) {
    return (group: FormGroup) => {
      let maximumSensors = 4;
      if (group.controls["cognitiveReact"].value === 1) {
        maximumSensors = 3;
      }
      if (group.controls["isolatedReact"].value === 1 && reactSensors.length > maximumSensors) {
        group.controls["isolatedReact"].setErrors({ invalidSensors: true });
      }
    };
  }

  static sequenceValidation(sequenceSensors) {
    return (group: FormGroup) => {
      const length = sequenceSensors.length;
      if (length < 2 || length > 20) {
        group.controls["delay"].setErrors({ invalidSequence: true });
      }
    };
  }

  static arrowValidation(agilitySensors) {
    return (group: FormGroup) => {
      const length = agilitySensors.length;
      if (group.controls["agilityCustomType"].value === 0) {
        if (length < 2 || length > 20) {
          group.controls["agilityCustomType"].setErrors({ invalidArrows: true });
        }
      } else {
        if (length === 0) {
          group.controls["agilityCustomType"].setErrors({ invalidActiveArrows: true });
        }
      }
    };
  }

  ngOnInit() {
    this.mixpanelService.track("exercise_save_screen", {});
    this.mainForm = this.formBuilder.group(
      {
        active: [true, Validators.required],
        title: ["", [Validators.required]],
        category: ["", Validators.required],
        type: [0, Validators.required],
        startType: [1, Validators.required],
        startValue: [""],
        endType: [2, Validators.required],
        endValue: ["", Validators.required],
        verticalJumpType: [1, Validators.required],
        numberOfLegs: [0, Validators.required],
        videoUrl: [""]
      },
      { validator: SaveExerciseComponent.startValueValidation() }
    );
    this.countForm = this.formBuilder.group({
      countType: [0, Validators.required]
    });
    this.reactForm = this.formBuilder.group(
      {
        delay: [0.1, Validators.required],
        flashTime: [0, Validators.required],
        cognitiveReact: [0, Validators.required],
        isolatedReact: [0, Validators.required]
      },
      {
        validator: [
          SaveExerciseComponent.neurocognitiveValidation(this.react, this.dontReact, this.reactSensors),
          SaveExerciseComponent.activeSensorValidation(this.reactSensors)
        ]
      }
    );
    this.sequenceForm = this.formBuilder.group(
      {
        delay: [0.1, Validators.required],
        flashTime: [0, Validators.required]
      },
      { validator: SaveExerciseComponent.sequenceValidation(this.sequenceSensors) }
    );
    this.agilityForm = this.formBuilder.group(
      {
        delay: [0.1, Validators.required],
        agilityCustomType: [0, Validators.required]
      },
      { validator: SaveExerciseComponent.arrowValidation(this.agilitySensors) }
    );
    this.verticalForm = this.formBuilder.group({});

    this.sub = this.route.params.subscribe(
      (params) => {
        this.id = params["id"];
        this.id !== undefined ? this.retrieveExercise() : this.getCategories();
      },
      (error) => this.notificationService.error(error)
    );

    this.videoUploader = new FileUploader({
      queueLimit: 1
    });
    this.videoUploader.onAfterAddingFile = (file) => {
      if (file.file.type.indexOf("video") >= 0 && file.file.size <= 200000000) {
        this.video = file.file;
      } else {
        this.notificationService.warn("Invalid video type or size submitted!");
        this.video = null;
      }
    };

    this.mainForm.get("type").valueChanges.subscribe((data) => {
      if (data !== 4) return;

      this.mainForm.controls["startType"].setValue(12);
      this.mainForm.controls["startValue"].setValue(1);
    });
  }

  retrieveExercise() {
    this.saveExerciseService.getExercise(this.id).subscribe(
      (response) => {
        const exercise = response.exercise;
        this.additionalValues = {
          boardType: exercise.boardType,
          favorited: exercise.favorited,
          paired: exercise.paired,
          creationDate: exercise.creationDate
        };

        const exerciseType = exercise.exerciseType;
        this.mainForm.controls["type"].setValue(exerciseType);
        this.mainForm.controls["videoUrl"].setValue(exercise.videoUrl);
        this.video =
          exercise.videoUrl && exercise.videoUrl.length > 0
            ? { name: exercise.videoUrl.slice(exercise.videoUrl.lastIndexOf("/") + 1, exercise.videoUrl.length) }
            : null;
        this.mainForm.controls["active"].setValue(exercise.active);
        this.mainForm.controls["title"].setValue(exercise.name);

        this.getAndUpdateCategories(exercise.categoryId);

        this.selectedSettings = response.exerciseSettings;
        this.mainForm.controls["startType"].setValue(this.selectedSettings.startType);
        this.mainForm.controls["startValue"].setValue(this.selectedSettings.startValue);
        this.mainForm.controls["endType"].setValue(this.selectedSettings.endType);
        this.mainForm.controls["endValue"].setValue(this.selectedSettings.endValue);

        switch (exerciseType) {
          case 0:
            this.countForm.controls["countType"].setValue(this.selectedSettings.countType);
            break;
          case 1:
            this.reactForm.controls["delay"].setValue(this.selectedSettings.delay);
            if (this.selectedSettings.promptType == 0) this.reactForm.controls["flashTime"].setValue(0);
            else this.reactForm.controls["flashTime"].setValue(this.selectedSettings.flashTime);
            this.reactForm.controls["cognitiveReact"].setValue(this.selectedSettings.cognitiveReact);
            if (this.selectedSettings.inactiveSensors === null) {
              this.reactForm.controls["isolatedReact"].setValue(0);
            } else {
              this.reactForm.controls["isolatedReact"].setValue(1);
              Object.assign(this.reactSensors, this.selectedSettings.inactiveSensors);
            }
            Object.assign(this.dontReact, this.selectedSettings.dontReactTo);
            Object.assign(this.react, this.selectedSettings.reactTo);
            break;
          case 2:
            this.sequenceForm.controls["delay"].setValue(this.selectedSettings.delay);
            if (this.selectedSettings.promptType == 0) this.sequenceForm.controls["flashTime"].setValue(0);
            else this.sequenceForm.controls["flashTime"].setValue(this.selectedSettings.flashTime);
            Object.assign(this.sequenceSensors, this.selectedSettings.sequence);
            break;
          case 3:
            this.agilityForm.controls["delay"].setValue(this.selectedSettings.delay);
            this.agilityForm.controls["agilityCustomType"].setValue(this.selectedSettings.agilityCustomType);
            Object.assign(this.agilitySensors, this.selectedSettings.sequence);
            break;
          case 4:
            this.mainForm.controls["numberOfLegs"].setValue(this.selectedSettings.numberOfLegs);
            this.mainForm.controls["verticalJumpType"].setValue(this.selectedSettings.verticalJumpType);
            break;
          default:
            this.notificationService.error("Invalid exercise type.");
            this.mainForm.controls["type"].setValue(0);
            break;
        }
      },
      (error) => this.notificationService.error(error)
    );
  }

  currentForm() {
    switch (this.mainForm.controls["type"].value) {
      case 0:
        return this.countForm;
      case 1:
        return this.reactForm;
      case 2:
        return this.sequenceForm;
      case 3:
        return this.agilityForm;
      case 4:
        return this.verticalForm;
      default:
        this.notificationService.error("Invalid exercise type.");
        this.mainForm.controls["type"].setValue(0);
        return this.countForm;
    }
  }

  saveExercise() {
    this.loading = true;
    const data = new FormData();
    this.video && this.video.rawFile != null ? data.append("file", this.video.rawFile) : data.append("file", new Blob());

    const selectedCategory = this.mainForm.controls["category"].value;
    let categoryId, categoryUUID;
    for (const category of this.exerciseCategories) {
      if (category.name === selectedCategory) {
        categoryId = category.id;
        categoryUUID = category.uuid;
        break;
      }
    }

    const exerciseType = this.mainForm.controls["type"].value;
    this.getExerciseSettings(exerciseType);
    this.selectedSettings.startType = this.mainForm.controls["startType"].value;
    this.selectedSettings.startValue = this.mainForm.controls["startValue"].value;
    this.selectedSettings.endType = this.mainForm.controls["endType"].value;
    this.selectedSettings.endValue = this.mainForm.controls["endValue"].value;

    if (exerciseType === 4) {
      this.selectedSettings.endType = 10;
      this.selectedSettings.verticalJumpType = this.mainForm.controls["verticalJumpType"].value;
      this.selectedSettings.numberOfLegs = this.mainForm.controls["numberOfLegs"].value;
    }

    if (this.selectedSettings.flashTime) {
      this.selectedSettings.promptType = 1;
    } else {
      this.selectedSettings.promptType = 0;
    }
    data.append(
      "exercise",
      new Blob(
        [
          JSON.stringify({
            id: this.id,
            categoryId: categoryId,
            categoryUUID: categoryUUID,
            name: this.mainForm.controls["title"].value,
            active: this.mainForm.controls["active"].value,
            exerciseType: exerciseType,
            videoUrl: this.mainForm.controls["videoUrl"].value,
            creationDate: this.additionalValues.creationDate,
            paired: this.additionalValues.paired,
            favorited: this.additionalValues.favorited,
            boardType: this.additionalValues.boardType,
            exerciseSettings: this.selectedSettings
          })
        ],
        { type: "application/json" }
      )
    );

    this.saveExerciseService.saveExercise(data).subscribe(
      () => {
        this.loading = false;
        this.router.navigate(["/exercises"]);
        this.notificationService.success("Exercise saved successfully.");
      },
      (error) => {
        this.notificationService.error(error);
        this.loading = false;
      }
    );
  }

  getExerciseSettings(exerciseType: number) {
    switch (exerciseType) {
      case 0:
        this.selectedSettings.countType = this.countForm.controls["countType"].value;
        break;
      case 1:
        this.selectedSettings.delay = this.reactForm.controls["delay"].value;
        this.selectedSettings.flashTime = this.reactForm.controls["flashTime"].value;
        this.selectedSettings.cognitiveReact = this.reactForm.controls["cognitiveReact"].value;
        this.selectedSettings.dontReactTo = this.dontReact;
        this.selectedSettings.inactiveSensors = this.reactForm.controls["isolatedReact"].value === 1 ? this.reactSensors : null;
        this.selectedSettings.reactTo = this.react;
        break;
      case 2:
        this.selectedSettings.delay = this.sequenceForm.controls["delay"].value;
        this.selectedSettings.flashTime = this.sequenceForm.controls["flashTime"].value;
        this.selectedSettings.sequence = this.sequenceSensors;
        break;
      case 3:
        this.selectedSettings.delay = this.agilityForm.controls["delay"].value;
        this.selectedSettings.agilityCustomType = this.agilityForm.controls["agilityCustomType"].value;
        this.selectedSettings.sequence = this.agilitySensors;
        break;
      case 4:
        // console.log('vertical');
        break;
      default:
        this.notificationService.error("Invalid exercise type.");
        this.mainForm.controls["type"].setValue(0);
        break;
    }
  }

  getAndUpdateCategories(categoryId: string) {
    this.sharedExerciseService.getCategories().subscribe(
      (response) => {
        this.exerciseCategories = response;
        this.categoryOptions = this.exerciseCategories.map((category) => category.name);
        for (const category of this.exerciseCategories) {
          if (category.id === categoryId) {
            this.mainForm.controls["category"].setValue(category.name);
            this.categoryInput = category.name;
            break;
          }
        }
      },
      (error) => this.notificationService.error(error)
    );
  }

  getCategories() {
    this.sharedExerciseService.getCategories().subscribe(
      (response) => {
        this.exerciseCategories = response;
        this.categoryOptions = this.exerciseCategories.map((category) => category.name);
      },
      (error) => this.notificationService.error(error)
    );
  }

  getCategoryId(categoryName: string) {
    for (const category of this.exerciseCategories) {
      if (category.name === categoryName) {
        return category.id;
      }
    }
    this.notificationService.error("Could not find category.");
    return null;
  }

  addExerciseCategory() {
    let found = false;
    for (const category of this.exerciseCategories) {
      if (category.name.toLowerCase().trim() === this.categoryInput.toLowerCase().trim()) {
        found = true;
        break;
      }
    }
    found
      ? this.notificationService.error("This category already exists.")
      : this.saveExerciseService
          .saveCategory({
            name: this.categoryInput,
            creationDate: Date.now()
          })
          .subscribe(
            (response) => {
              this.getCategories();
              this.mainForm.controls["category"].setValue(this.categoryInput);
              this.notificationService.success("Exercise category added successfully.");
            },
            (error) => this.notificationService.error(error)
          );
  }

  updateExerciseCategory() {
    this.saveExerciseService
      .saveCategory({
        id: this.getCategoryId(this.mainForm.controls["category"].value),
        name: this.categoryInput,
        active: true
      })
      .subscribe(
        (response) => {
          this.getCategories();
          this.mainForm.controls["category"].setValue(this.categoryInput);
          this.notificationService.success("Exercise category updated successfully.");
        },
        (error) => this.notificationService.error(error)
      );
  }

  deleteExerciseCategory() {
    this.saveExerciseService.deleteCategory(this.getCategoryId(this.mainForm.controls["category"].value)).subscribe(
      () => {
        this.getCategories();
        this.mainForm.controls["category"].setValue("");
        this.notificationService.success("Exercise category deleted successfully.");
      },
      (error) => (error.status === 409 ? this.notificationService.warn(error) : this.notificationService.error(error))
    );
  }

  setExerciseType(type: number) {
    if (this.id === undefined) {
      this.mainForm.controls["endType"].setValue(2);
      this.mainForm.controls["type"].setValue(type);
    }
  }

  resetReact() {
    this.reactSensors.splice(0, this.reactSensors.length);
    this.reactForm.controls["isolatedReact"].updateValueAndValidity();
  }

  setReact(color: number, react: boolean) {
    let array;
    let otherArray;

    if (react) {
      array = this.react;
      otherArray = this.dontReact;
    } else {
      array = this.dontReact;
      otherArray = this.react;
    }

    let index = array.indexOf(color);
    index > -1 ? array.splice(index, 1) : array.push(color);

    index = otherArray.indexOf(color);
    if (index > -1) {
      otherArray.splice(index, 1);
    }
    this.reactForm.controls["cognitiveReact"].updateValueAndValidity();
  }

  setSensor(sensor: number) {
    const index = this.reactSensors.indexOf(sensor);
    index > -1 ? this.reactSensors.splice(index, 1) : this.reactSensors.push(sensor);

    this.reactForm.controls["isolatedReact"].updateValueAndValidity();
  }

  resetSequence() {
    this.sequenceSensors.splice(0, this.sequenceSensors.length);
    this.sequenceForm.controls["delay"].updateValueAndValidity();
  }

  addSequence(sensor: number) {
    const length = this.sequenceSensors.length;
    if (length < 26) {
      this.sequenceSensors.push(sensor);
      this.sequenceForm.controls["delay"].updateValueAndValidity();
    }
  }

  goToAgilitySequence() {
    if (this.agilityForm.controls["agilityCustomType"].value == 0) {
      this.emptyAgilitySensors();
      Object.assign(this.agilitySensors, [1, 2, 3, 4, 5, 6, 7, 8, 9]);
      this.agilityForm.controls["agilityCustomType"].setValue(1);
    }
    this.poppedUp = true;
  }

  goToAgilityReact() {
    if (this.agilityForm.controls["agilityCustomType"].value == 1) {
      this.emptyAgilitySensors();
      this.agilityForm.controls["agilityCustomType"].setValue(0);
    }
    this.poppedUp = true;
  }

  resetAgilitySensors(type: number) {
    this.emptyAgilitySensors();
    if (type === 1) {
      Object.assign(this.agilitySensors, [1, 2, 3, 4, 5, 6, 7, 8, 9]);
    }
  }

  emptyAgilitySensors() {
    while (this.agilitySensors.length != 0) {
      this.agilitySensors.pop();
    }
  }

  addArrows(arrow: number) {
    if (this.agilityForm.controls["agilityCustomType"].value === 0) {
      const length = this.agilitySensors.length;
      if (length < 26) {
        this.agilitySensors.push(arrow);
        this.agilityForm.controls["agilityCustomType"].updateValueAndValidity();
      }
    } else {
      const index = this.agilitySensors.indexOf(arrow);
      index > -1 ? this.agilitySensors.splice(index, 1) : this.agilitySensors.push(arrow);

      this.agilityForm.controls["agilityCustomType"].updateValueAndValidity();
    }
  }

  arrowsColorCondition(arrow: number) {
    return this.agilityForm.controls["agilityCustomType"].value === 0 || this.agilitySensors.indexOf(arrow) > -1;
  }

  removeAgilitySensor(index: number) {
    this.agilitySensors.splice(index, 1);
  }

  removeSequenceSensor(index: number) {
    this.sequenceSensors.splice(index, 1);
  }

  openConfirmModal() {
    this.modalService.open(new TemplateModalConfig<string, string, string>(this.confirmModalTemplate));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  checkPopUp() {
    if (this.reactForm.controls["isolatedReact"].value == 1) {
      this.poppedUp = true;
    }
  }

  selectExerciseCategory(name: string) {
    this.categoryInput = name;
    for (const category of this.exerciseCategories) {
      if (category.name === name) {
        this.selectedCategoryIsUserCreated = category.userCreated;
        break;
      }
    }
  }

  openBackModal() {
    const config = new TemplateModalConfig<string, string, string>(this.confirmBackModal);
    config.mustScroll = true;
    config.size = "small";
    this.modalService.open(config);
  }

  toggleActive() {
    if (this.isActive) {
      this.mainForm.controls["active"].setValue(false);
      this.isActive = false;
    } else {
      this.mainForm.controls["active"].setValue(true);
      this.isActive = true;
    }
  }
}
