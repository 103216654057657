import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { WorkoutService } from "../services/workout.service";
import { possibleRestTime, possibleSets } from "../helpers/exercise-data";
import { NotificationService } from "../services/notification.service";
import { GlobalPreferences } from "../helpers/global-data";
import { Router } from "@angular/router";
import { ModalTemplate, SuiModalService, TemplateModalConfig } from "ng2-semantic-ui";
import { AddWorkoutService } from "../services/add-workout.service";
import { Subject } from "rxjs";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-workouts",
  templateUrl: "./workouts.component.html",
  styleUrls: ["./workouts.component.scss", "../styles.common.scss"],
  providers: [WorkoutService, AddWorkoutService]
})
export class WorkoutsComponent implements OnInit, OnDestroy {
  workoutData = [];
  workoutList = [];
  selectedIndex = null;
  possibleRestTime = possibleRestTime;
  possibleSets = possibleSets;
  toggle = true;
  canLoadWorkoutData = false;
  canLoadPagedWorkouts = false;
  workoutsLoaded;
  globalSub;
  organizations;
  locations: Array<string> = [];
  workoutId: string = "";
  searchText: string = "";
  selectedWorkouts = [];

  constructor(
    private workoutService: WorkoutService,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
    private router: Router,
    private modalService: SuiModalService,
    private addWorkoutService: AddWorkoutService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.globalSub = this.global.dataChange.subscribe(
      (data) => {
        if (this.router.url.indexOf(data.workoutName + "s") == -1) {
          this.router.navigate(["/" + data.workoutName + "s"]);
        }
      },
      (error) => this.notificationService.error(error)
    );
    this.getWorkouts();
  }

  getWorkoutData() {
    const workoutDataLength = this.workoutData.length;
    const page = workoutDataLength > 0 ? Math.floor(workoutDataLength / 20) : 0;
    const size = 20;
    this.workoutService.getWorkoutData(page, size).subscribe(
      (workoutData) => {
        this.workoutData = workoutDataLength > 0 ? this.workoutData.concat(workoutData) : workoutData;
        const canLoadLength = workoutData.length;
        this.canLoadWorkoutData = canLoadLength % 20 === 0 && canLoadLength !== 0;
      },
      (error) => this.notificationService.error(error)
    );
  }

  getWorkouts() {
    this.workoutsLoaded = false;
    const noOfWorkouts = this.workoutList.length;
    const page = noOfWorkouts > 0 ? Math.floor(noOfWorkouts / 20) : 0;
    const size = 20;
    this.workoutService.getWorkouts(page, size).subscribe(
      (response) => {
        for (const workout of response) {
          this.workoutList.push(workout);
        }
        const canLoadLength = response.length;
        this.canLoadPagedWorkouts = canLoadLength % 20 === 0 && canLoadLength !== 0;
        this.workoutsLoaded = true;
      },
      (error) => this.notificationService.error(error)
    );
  }

  updateSelectedAthlete(index: number) {
    this.selectedIndex === index ? (this.selectedIndex = null) : (this.selectedIndex = index);
  }

  deleteWorkout(workoutId: string) {
    this.workoutService.deleteWorkout(workoutId).subscribe(
      () => {
        for (let i = 0; i < this.workoutList.length; i++) {
          if (this.workoutList[i].id === workoutId) {
            this.workoutList.splice(i, 1);
            break;
          }
        }
        for (const data of this.workoutData) {
          for (let i = 0; i < data.workouts.length; i++) {
            if (data.workouts[i].id === workoutId) {
              data.workouts.splice(i, 1);
              break;
            }
          }
        }
        this.notificationService.success("Workout deleted successfully.");
      },
      (error) => this.notificationService.error(error)
    );
  }

  ngOnDestroy() {
    this.globalSub.unsubscribe();
  }

  isSuperAdmin() {
    return this.authService.checkTokenAccess("SUPER_ADMIN");
  }

  syncWorkout(workoutId) {
    this.router.navigate([`workouts/sync-workout`], { queryParams: { id: workoutId ? workoutId : this.selectedWorkouts.map((id) => id) } });
  }

  handleWorkoutSelection(id) {
    if (this.selectedWorkouts.includes(id)) {
      this.selectedWorkouts.splice(this.selectedWorkouts.indexOf(id), 1);
    } else {
      this.selectedWorkouts.push(id);
    }
  }
}
