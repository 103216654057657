import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DashboardService } from "../services/dashboard.service";
import { Chart } from "angular-highcharts";
import { NotificationService } from "../services/notification.service";
import { colorArray } from "../helpers/chart-data";
import { GlobalPreferences } from "../helpers/global-data";
import { MixpanelService } from "../services/mixpanel.service";
import { AuthService } from "../services/auth.service";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription, User } from "../models/Subscription";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ModalTemplate, SuiModalService, TemplateModalConfig } from "ng2-semantic-ui";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss", "../styles.common.scss"],
  providers: [DashboardService]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild("deleteCreditCardModal")
  deleteCreditCardModal: ModalTemplate<string, string, string>;

  activeAthletes = { fromDate: 3, value: 0 };
  subscription = { type: "", endDate: "" };
  mostPopularExercise = { fromDate: 3, value: "", id: "" };
  totalTime = { fromDate: 3, value: "0h" };
  favoriteExercises = [];
  leaderBoardAthletes = [];
  leaderboardResults = [];
  activeExercises = [];
  mostActiveAthletesChart = { value: new Chart(), fromDate: 3, loading: false };
  colorArray = colorArray;
  mostActiveAthletes = 0;
  totalTimeLoaded = false;

  selectedCategory;

  possibleFilters = [];
  activeCategories = [];
  selectedExercise;

  totalItems;
  usersPage = 0;
  usersSize = 10;
  usersDataSource;
  users: User[];
  subscriptions: Subscription[] = [];
  subscriptionForm: FormGroup;
  timeframe = 0;
  updatedUsers = [];
  searchText = "";
  displayedColumns: string[] = [
    "userName",
    "organizationName",
    "firstName",
    "lastName",
    "email",
    "status",
    "lastBilled",
    "subscription",
    "price",
    "athleteLimit",
    "creditCardLast4"
  ];

  valueChanged = false;

  timeframeExercise = 0;

  selectedBoolean = false;
  showExerciseSection = false;
  refreshTimer;

  mostActiveExercise = false;

  athleteCount: number;
  organizationCount: number;
  userCount: number;

  constructor(
    private dashboardService: DashboardService,
    private notificationService: NotificationService,
    public global: GlobalPreferences,
    private mixpanelService: MixpanelService,
    private authService: AuthService,
    private fb: FormBuilder,
    private modalService: SuiModalService
  ) {}

  ngOnInit() {
    this.mixpanelService.track("dashboard", {});
    this.countActiveAthletes();
    this.getSubscription();
    this.getMostPopularExercise();
    this.getTotalTime();
    this.getFavoriteExercises(3);
    if (!this.isSuperAdmin()) {
      this.getMostActiveAthletes();
    }
    this.getMostActiveExerciseLeaderboard();
    if (this.isSuperAdmin()) {
      this.getOrganizationsActiveCount();
      this.getUsers();
    }
    this.subscriptionForm = this.fb.group({
      subscriptionControl: this.fb.array([
        this.fb.group({
          name: ""
        })
      ])
    });
  }

  getActiveCategories() {
    if (this.selectedExercise == undefined) {
      this.selectedBoolean = false;
      this.dashboardService.getActiveExercises(this.timeframe.toString()).subscribe(
        (data) => {
          this.activeCategories = data;
        },
        (error) => this.notificationService.error(error)
      );
    } else {
      this.selectedBoolean = true;

      this.dashboardService.getLeaderboardForExercise(this.selectedExercise.id, this.timeframe.toString()).subscribe(
        (data) => {
          this.leaderboardResults = data;
        },
        (error) => this.notificationService.error(error)
      );
    }
  }

  getMostActiveExerciseLeaderboard() {
    this.dashboardService.getMostActiveExerciseLeaderboard().subscribe(
      (data) => {
        if (data.athleteResultLeaderboards !== null) {
          this.activeCategories = data.categoryDTO;
          this.selectedCategory = data.categoryDTO[0];

          this.activeExercises.push(data.exercise);
          this.selectedExercise = data.exercise;
          this.selectedBoolean = true;
          this.showExerciseSection = true;

          this.leaderboardResults = data.athleteResultLeaderboards;
          this.mostActiveExercise = true;
        }
      },
      (error) => this.notificationService.error(error)
    );
  }

  getLeaderBoardForExercise() {
    this.selectedBoolean = true;
    this.dashboardService.getLeaderboardForExercise(this.selectedExercise.id, this.timeframe.toString()).subscribe(
      (data) => {
        this.leaderboardResults = data.map((el) => {
          return {
            ...el,
            averageComparison: this.calculateComparisonFormula(el.averageScore, el.averageComparison),
            bestComparison: this.calculateComparisonFormula(el.bestScore, el.bestComparison)
          };
        });
      },
      (error) => this.notificationService.error(error)
    );
  }

  clearSelection() {
    this.selectedExercise = undefined;
    this.selectedBoolean = false;
    this.showExerciseSection = false;
  }

  selectExercise() {
    this.showExerciseSection = true;
    this.activeExercises = this.selectedCategory.exerciseList;
  }

  countActiveAthletes() {
    this.dashboardService.countActiveAthletes(this.activeAthletes.fromDate).subscribe(
      (response) => (this.activeAthletes.value = response.body),
      (error) => this.notificationService.error(error)
    );
  }

  getSubscription() {
    this.dashboardService.getSubscription().subscribe(
      (response) => (this.subscription = response),
      (error) => this.notificationService.error(error)
    );
  }

  getMostPopularExercise() {
    this.dashboardService.getMostPopularExercise(this.mostPopularExercise.fromDate).subscribe(
      (response) => {
        if (response && response.body) {
          // A quick-fix to truncate the exercise name in the middle
          if (response.body.name && response.body.name.length > 42 && !(response.body.name.length < 46)) {
            response.body.name =
              response.body.name.substr(0, 20) +
              "..." +
              response.body.name.substr(response.body.name.length - 20, response.body.name.length);
          }
          this.mostPopularExercise.value = response.body.name;
          this.mostPopularExercise.id = response.body.id;
        } else {
          this.mostPopularExercise.value = null;
          this.mostPopularExercise.id = null;
        }
      },
      (error) => this.notificationService.error(error)
    );
  }

  getTotalTime() {
    this.totalTimeLoaded = false;
    this.dashboardService.getTotalTime(this.totalTime.fromDate).subscribe(
      (response) => {
        // if (response.body > 0) {
        const d = Number(response.body);
        const h = Math.floor(d / 3600);
        const m = Math.floor((d % 3600) / 60);
        const s = Math.floor((d % 3600) % 60);

        const hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
        const mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
        const sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";

        this.totalTime.value = hDisplay + mDisplay + sDisplay;

        if (this.totalTime.value == "") {
          this.totalTime.value = "0";
        }
        // }
        this.totalTimeLoaded = true;
      },
      (error) => this.notificationService.error(error)
    );
  }

  getMostActiveAthletes() {
    this.mostActiveAthletesChart.loading = true;
    this.dashboardService.getMostActiveAthletes(this.mostActiveAthletesChart.fromDate).subscribe(
      (response) => {
        this.mostActiveAthletes = response.length;
        const seriesData = [];
        response.forEach((value) => {
          seriesData.push({
            name: value.firstName + " " + value.lastName,
            y: value.count,
            color: this.colorArray[this.colorArray.length - seriesData.length]
          });
        });
        this.mostActiveAthletesChart.value = this.initBarChart(seriesData, "Exercises", "<b>{point.y}</b> exercises", false);
        this.mostActiveAthletesChart.loading = false;
      },
      (error) => {
        this.notificationService.error(error);
        this.mostActiveAthletesChart.loading = false;
      }
    );
  }

  getFavoriteExercises(fromDate: number, init: boolean = true) {
    this.favoriteExercises.forEach((value) => {
      value.loading = true;
    });

    this.dashboardService.getFavoriteExercises(fromDate).subscribe(
      (response) => {
        response.forEach((value) => {
          const scoreUnit = value.scoreType === "Correct touches" ? "touches" : "seconds";
          const length = value && value.results && value.results.length;
          if (length) {
            this.favoriteExercises.push({
              fromDate: fromDate,
              exercise: value.exercise,
              results: value.results,
              scoreType: value.scoreType,
              scoreUnit: scoreUnit,
              canLoad: length % 5 === 0 && length !== 0
            });
          }
        });
        this.favoriteExercises.forEach((value, index) => {
          this.refreshFavoriteExercise(index, false);
        });
      },
      (error) => this.notificationService.error(error)
    );
  }

  refreshFavoriteExercise(index, initialize: boolean = false) {
    const exercise = this.favoriteExercises[index];
    exercise.loading = true;
    exercise.results = [];
    let page;
    let toExclude;
    if (initialize) {
      page = 0;
      toExclude = [];
    } else {
      const length = exercise.results.length;
      toExclude = exercise.results.map((result) => result.athleteId);
      page = length > 0 ? Math.floor(length / 5) : 0;
    }

    this.dashboardService.refreshOneFavoriteExercise(0, toExclude, exercise.exercise.id, exercise.fromDate).subscribe(
      (response) => {
        response.forEach((value) => {
          if (initialize) {
            exercise.results = value.results;
          } else {
            value.results.forEach((val) => exercise.results.push(val));
          }
          const length = value.results.length;
          exercise.canLoad = length % 5 === 0 && length !== 0;
          exercise.loading = false;
        });

        clearTimeout(exercise.refreshTimer);
        exercise.refreshTimer = setTimeout(() => {
          this.refreshFavoriteExercise(index, false);
        }, 10000);
      },
      (error) => {
        this.notificationService.error(error);
        exercise.loading = false;
      }
    );
  }

  initBarChart(seriesData, yAxisName: string, tooltip: string, decimals?: boolean) {
    return new Chart({
      chart: { type: "column", height: "300" },
      title: { text: "" },
      credits: { enabled: false },
      legend: { enabled: false },
      xAxis: { categories: seriesData.map((value) => value.name) },
      tooltip: { pointFormat: tooltip },
      series: [{ data: seriesData }],
      yAxis: { title: { text: yAxisName }, allowDecimals: decimals }
    });
  }

  ngOnDestroy() {
    this.favoriteExercises.forEach((exercise) => {
      clearTimeout(exercise.refreshTimer);
    });
  }

  getUsers() {
    this.dashboardService.getUsers(this.searchText, this.usersPage, this.usersSize).subscribe(
      (data) => {
        this.users = data.users;
        this.usersDataSource = new MatTableDataSource(data.users);
        this.totalItems = data.totalUsers;
        this.subscriptions = data.subscriptions;
      },
      (error) => this.notificationService.error(error)
    );
  }

  changed(element) {
    this.valueChanged = true;
    this.userUpdated(element);
  }

  isSuperAdmin() {
    // console.log(this.authService.checkTokenAccess('SUPER_ADMIN'))
    return this.authService.checkTokenAccess("SUPER_ADMIN");
  }

  pageChanged(event) {
    this.usersPage = event.pageIndex;
    this.usersSize = event.pageSize;
    this.cancelUpdate();
  }

  userUpdated(element) {
    this.updatedUsers.push(element);
  }

  cancelUpdate() {
    this.updatedUsers = [];
    this.getUsers();
  }

  updateUsers() {
    this.dashboardService.updateUsers(this.updatedUsers).subscribe(
      (data) => {
        this.updatedUsers = [];
        this.getUsers();
      },
      (error) => {
        this.updatedUsers = [];
        this.getUsers();
        this.notificationService.error(error);
      }
    );
  }

  deleteCreditCard(organizationId) {
    const config = new TemplateModalConfig<string, string, string>(this.deleteCreditCardModal);

    this.modalService.open(config).onApprove(() => {
      this.dashboardService.deleteCreditCard(organizationId).subscribe(
        ({ message }) => {
          this.getUsers();
          this.notificationService.success(message);
        },
        (error) => this.notificationService.error(error)
      );
    });
  }

  getOrganizationsActiveCount() {
    this.dashboardService.getOrganizationsActiveCount().subscribe(({ athleteCount, organizationCount, userCount }) => {
      this.athleteCount = athleteCount;
      this.organizationCount = organizationCount;
      this.userCount = userCount;
    });
  }

  private calculateComparisonFormula(current: number, past: number): number {
    if (!past) {
      return null;
    }
    return (Math.abs(current - past) / ((current + past) / 2)) * 100;
  }
}
