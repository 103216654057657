import {Injectable} from '@angular/core';
import {ToasterService} from 'angular2-toaster';


@Injectable()
export class NotificationService {

  constructor(private toasterService: ToasterService) {
    this.toasterService = toasterService;
  }

  private static findErrorMessage(error): string {
    const err = error.error;
    if (err) {
      if (err.message) {
        return err.message;
      }
      if (err.error_description) {
        return err.error_description;
      }
    }
    return 'The server is currently busy. Please try again later.';
  }

  success(message: any) {
    this.toasterService.popAsync('success', 'Success', message);
  }

  warn(message: any) {
    if (typeof message === 'string') {
      this.toasterService.popAsync('warning', 'Warning', message);
      return;
    }
    this.toasterService.popAsync('warning', 'Warning', NotificationService.findErrorMessage(message));
  }

  error(message: any) {
    if (typeof message === 'string') {
      this.toasterService.popAsync('error', 'Error', message);
      return;
    }

    if (message.error !== 'invalid_token') {
      this.toasterService.popAsync('error', 'Error', NotificationService.findErrorMessage(message));
    }
  }
}
