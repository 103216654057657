import {Injectable} from '@angular/core';
import {Observable} from "rxjs/Rx";
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {EndpointService} from './endpoint.service';

@Injectable()
export class ReportService {

    constructor(private http: HttpClient) {
    }

    getReports(): Observable<any> {
        return this.http.get(EndpointService.reportsUrl, AuthService.defaultTokenHeader());
    }

    generateReport(data): Observable<any> {
        return this.http.post(EndpointService.generateReportUrl, data, AuthService.defaultTokenHeader());
    }

    deleteReport(id: number) {
        return this.http.delete(EndpointService.reportUrl + `?id=${id}`, AuthService.defaultTokenHeader());
    }

    getAthletes(page: number, size: number, search: string = ''): Observable<any> {
        const details = search == '' ? `?page=${page}&size=${size}` : `?page=${page}&size=${size}&search=${search}`;
        return this.http.get(EndpointService.athleteDetailsUrl + details, AuthService.defaultTokenHeader());
    }

}
