import {Component, OnInit, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NotificationService} from "../../services/notification.service";
import {SharedRegisterService} from "../../services/shared-register.service";
import {SettingsService} from "../../services/settings.service";
import {emailValidator, nameValidator, passwordValidator, usernameValidator} from "../../helpers/regex-data";
import {RegisterComponent} from "../../register/register.component";
import { MessageService } from '../../services/message-service';

@Component({
    selector: 'app-location-action',
    templateUrl: './location-action.component.html',
    styleUrls: ['./location-action.component.scss']
})
export class LocationActionComponent implements OnInit {

    locationForm: FormGroup;
    selectedLocations = [];
    locations = [];
    user: any;
    action: string;
    errorLocations = false;
    location = null;
    allreadyPressed = false;
    alreadyExists = 0;
    alreadyIsWithName: boolean;
    errorMessage: string;

    constructor(private messageService: MessageService, private settingsService: SettingsService, private formBuilder: FormBuilder,
                private sharedRegisterService: SharedRegisterService, private notificationService: NotificationService) {
    }

    ngOnInit() {

        this.locationForm = this.formBuilder.group({
            name: ['', [Validators.required]],
        });
        this.action = this.settingsService.locationAction;
        this.location = this.settingsService.currentLocation;
        if (this.settingsService.currentLocation && this.settingsService.currentLocation.name) {
            this.locationForm.controls['name'].setValue(this.location.name);
        }
    }

    update() {

        const locationName = this.locationForm.controls['name'].value;
        this.settingsService.checkLocationName(locationName).subscribe((data: boolean) => {

            if (!data) {
                if (this.action == 'Add') {
                    this.settingsService.addLocation({body: this.locationForm.controls['name'].value}).subscribe(response => {
                        this.notificationService.success("The new Location will be added in a few moments");
                        this.settingsService.locationActionModal.approve('done');
                        this.messageService.accessMessage().subscribe(data => {
                            if (!data) {
                                this.messageService.sendMessage(true);
                            }
                        })
                        
                    }, error => {
                        this.notificationService.error("Not authorized");
                        this.settingsService.locationActionModal.approve('done');
                    })
                    this.allreadyPressed = true;
                } else {
                    this.settingsService.editLocation({
                        name: this.locationForm.controls['name'].value,
                        id: this.location.id
                    }).subscribe(response => {
                        this.notificationService.success("Location added");
                        this.settingsService.locationActionModal.approve('done');
                    }, error => {
                        this.notificationService.error("Not authorized");
                        this.settingsService.locationActionModal.approve('done');
                    })
                    this.allreadyPressed = true;
                }
            } else {
                this.notificationService.error('A location with this name already exists !')
            }
        })
        
    }

    checkLocationName(): boolean {
        const locationName = this.locationForm.controls['name'].value;
        this.settingsService.checkLocationName(locationName).subscribe((data: boolean) => {
            this.alreadyIsWithName = data;
        })

        return this.alreadyIsWithName;
    }

}
