import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedPlaylistService } from "../services/shared-playlist.service";
import { SaveWorkoutService } from "../services/save-workout.service";
import { DragulaService } from "ng2-dragula";
import { possibleRestTime, possibleSets } from "../helpers/exercise-data";
import { NotificationService } from "../services/notification.service";
import { PlaylistsComponent } from "../playlists/playlists.component";
import { ModalTemplate, SuiModalService, TemplateModalConfig } from "ng2-semantic-ui";
import { GlobalPreferences } from "../helpers/global-data";
import { SharedExerciseService } from "../services/shared-exercise.service";
import { ExerciseService } from "../services/exercise.service";

@Component({
  selector: "app-save-workout",
  templateUrl: "./save-workout.component.html",
  styleUrls: ["./workouts.component.scss", "../styles.common.scss"],
  providers: [SharedPlaylistService, SaveWorkoutService, SharedExerciseService, ExerciseService]
})
export class SaveWorkoutComponent implements OnInit, OnDestroy {
  @ViewChild("confirmBackModal") confirmBackModal: ModalTemplate<string, string, string>;
  @ViewChild("confirmEditModal") confirmEditModal: ModalTemplate<string, string, string>;
  id;
  private sub;
  newWorkout;
  playlistIndex = null;
  athletesSelected = [];
  playlistsSelected = [];
  playlistExercisesSelected = [];
  addExercisesSelected = [];
  possibleSets = possibleSets;
  possibleRestTime = possibleRestTime;
  updateSelected = SaveWorkoutComponent.updateSelected;
  exerciseList = [];
  athleteList = [];
  filteredExerciseList = [];
  editing = false;
  canLoadAthletes = false;
  canLoadExerciseList = false;
  @ViewChild("exerciseModalTemplate")
  exerciseModalTemplate: ModalTemplate<string, string, string>;
  searchAddExercise = "";
  loading = false;
  searching = false;
  filterTimeout;
  globalSub;
  automated: boolean;
  automatedArray: any = [];
  circuit: boolean;
  circuitArray: any = [];

  exerciseCategories = [];
  exercises = [];
  loadingExercises = true;

  automatedNew = false;
  circuitNew = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sharedPlaylistService: SharedPlaylistService,
    private saveWorkoutService: SaveWorkoutService,
    private dragulaService: DragulaService,
    private notificationService: NotificationService,
    private modalService: SuiModalService,
    private sharedExerciseService: SharedExerciseService,
    private exerciseService: ExerciseService,
    public global: GlobalPreferences
  ) {
    dragulaService.setOptions("playlistBag", {
      moves: function (el, container, target) {
        return target.classList.contains("playlist");
      }
    });
    dragulaService.setOptions("exerciseBag", {
      accepts: function (el, target, source, sibling) {
        if (target.innerHTML === source.innerHTML) {
          return true;
        }
        const exercises = target.children;
        for (const exercise of exercises) {
          if (el.id === exercise.id) {
            return false;
          }
        }
        return true;
      }
    });
  }

  static updateSelected(value, array) {
    const newValue = {
      exerciseId: value.id,
      exerciseUUID: value.uuid,
      name: value.name,
      paired: value.paired,
      sets: 1
    };
    const index = array.indexOf(newValue);
    index >= 0 ? array.splice(index, 1) : array.push(newValue);
  }

  ngOnInit() {
    this.automatedArray = Array.from({ length: 100 }, () => false);
    this.circuitArray = Array.from({ length: 100 }, () => false);
    this.globalSub = this.global.dataChange.subscribe(
      (data) => {
        if (this.router.url.indexOf("save-" + data.workoutName) == -1) {
          this.router.navigate(["/save-" + data.workoutName]);
        }
      },
      (error) => this.notificationService.error(error)
    );
    this.getAthleteData();
    this.newWorkout = {
      name: "",
      playlists: [],
      collapsed: false
    };
    this.sub = this.route.params.subscribe(
      (params) => {
        this.id = params["id"];
        if (this.id !== undefined) {
          this.retrieveWorkout();
        }
      },
      (error) => this.notificationService.error(error)
    );
  }

  getExerciseData() {
    const noOfExercises = this.exerciseList.length + this.newWorkout.playlists[this.playlistIndex].exerciseList.length;
    const page = noOfExercises > 0 ? Math.floor(noOfExercises / 20) : 0;
    const size = 20;
    const exercisesToFilter = this.newWorkout.playlists[this.playlistIndex].exerciseList.map((value) => value.exerciseId);
    this.sharedPlaylistService.getExerciseDetails(page, size, exercisesToFilter, this.searchAddExercise).subscribe(
      (response) => {
        if (this.searchAddExercise == "") {
          for (const exercise of response) {
            this.exerciseList.push(exercise);
          }
          this.filteredExerciseList = Object.assign([], this.exerciseList);
        } else {
          for (const exercise of response) {
            this.filteredExerciseList.push(exercise);
          }
        }
        const exercises = response.length;
        this.canLoadExerciseList = exercises % 20 === 0 && exercises !== 0;
      },
      (error) => this.notificationService.error(error)
    );
  }

  getAthleteData() {
    const noOfAthletes = this.athleteList.length;
    const page = noOfAthletes > 0 ? Math.floor(noOfAthletes / 20) : 0;
    const size = 20;
    this.saveWorkoutService.getAthletes(page, size).subscribe(
      (response) => {
        this.athleteList = noOfAthletes > 0 ? this.athleteList.concat(response) : response;
        const canLoadLength = response.length;
        this.canLoadAthletes = canLoadLength % 20 === 0 && canLoadLength !== 0;
      },
      (error) => this.notificationService.error(error)
    );
  }

  retrieveWorkout() {
    this.saveWorkoutService.getWorkout(this.id).subscribe(
      (response) => {
        this.newWorkout = response["workout"];
        // console.log(response)
        const playlists = response["workout"].playlists;
        for (let i = 0; i < playlists.length; i++) {
          let playlist = playlists[i];
          if (playlist.type == 0) {
            this.automatedArray[i] = false;
          } else if (playlist.type == 1) {
            this.automatedArray[i] = true;
            this.circuitArray[i] = true;
          } else if (playlist.type == 2) {
            this.automatedArray[i] = true;
            this.circuitArray[i] = false;
          }
        }
        this.newWorkout.collapsed = true;
        this.athletesSelected = response["athletes"];
        this.editing = true;
      },
      (error) => {
        if (error.status === 400) {
          this.router.navigate(["/save-workout"]);
        }
        this.notificationService.error(error);
      }
    );
  }

  addPlaylist() {
    this.newWorkout.playlists.push({
      name: "",
      type: 0,
      restTime: this.possibleRestTime[0],
      circuitSets: 1,
      exerciseList: [],
      collapsed: false
    });
  }

  beginAddingExercises(index: number) {
    this.playlistIndex = index;
    this.addExercisesSelected = [];
    this.searchAddExercise = "";
    this.exerciseList = [];
    this.getExerciseData();
    const config = new TemplateModalConfig<string, string, string>(this.exerciseModalTemplate);
    config.mustScroll = true;
    this.modalService.open(config);
    this.getCategories();
  }

  getCategories() {
    this.sharedExerciseService.getCategories().subscribe(
      (data) => {
        this.exerciseCategories = data;
        this.exercises = [];
        this.exerciseCategories.forEach((value, index) => {
          this.getExerciseByCategory(value, index);
        });
      },
      (error) => {
        this.notificationService.error(error);
      }
    );
  }

  getExerciseByCategory(category, index: number) {
    const exerciseForCategory = this.exercises[category.id];
    const page = exerciseForCategory && exerciseForCategory.length > 0 ? Math.floor(exerciseForCategory.length / 20) : 0;
    const size = 20;

    this.exerciseService.findAllByCategory(category.id, page, size, true, "0").subscribe(
      (response: any) => {
        this.exercises[category.id] =
          exerciseForCategory && exerciseForCategory.length > 0 ? this.exercises[category.id].concat(response) : response;
        const ind = this.exerciseCategories.indexOf(category);

        if (this.exercises[category.id].length === 0) {
          this.exerciseCategories.splice(ind, 1);
        }
      },
      (error) => this.notificationService.error(error)
    );

    if (index == this.exerciseCategories.length - 1) {
      this.loadingExercises = false;
    }
  }

  addExercises() {
    for (const exercise of this.addExercisesSelected) {
      this.newWorkout.playlists[this.playlistIndex].exerciseList.push(exercise);
    }
    this.playlistIndex = null;
  }

  // onClickerrr() {
  //     console.log(this.newWorkout)
  // }

  saveWorkout() {
    this.loading = true;
    if (this.newWorkout.creationDate == null) {
      this.newWorkout.creationDate = Date.now();
    }
    for (let i = 0; i < this.newWorkout.playlists.length; i++) {
      if (this.newWorkout.playlists[i].exerciseList.length === 0) {
        this.newWorkout.playlists.splice(i, 1);
        i--;
        continue;
      }
      this.addRequiredData(this.newWorkout.playlists[i]);
      this.newWorkout.playlists[i].index = i;
    }
    this.saveWorkoutService
      .saveWorkout({
        athletes: this.athletesSelected,
        workout: this.newWorkout
      })
      .subscribe(
        () => {
          this.loading = false;
          this.router.navigate(["/workouts"]);
          this.notificationService.success("Workout saved successfully.");
        },
        (error) => {
          this.notificationService.error(error);
          this.loading = false;
        }
      );
  }

  addRequiredData(playlist) {
    if (playlist.type === 1) {
      const exerciseList = [];
      for (const exerciseItem of playlist.exerciseList) {
        exerciseList.push({
          exerciseId: exerciseItem.exerciseId,
          exerciseUUID: exerciseItem.exerciseUUID,
          name: exerciseItem.name,
          paired: exerciseItem.paired,
          sets: playlist.circuitSets
        });
      }
      playlist.exerciseList = exerciseList;
    }
  }

  selectAllAthletes() {
    for (const athlete of this.athleteList) {
      if (this.athletesSelected.indexOf(athlete.id) == -1) {
        this.athletesSelected.push(athlete.id);
      }
    }
  }

  removeAllSelected() {
    let index;
    for (const playlist of this.playlistsSelected) {
      index = this.newWorkout.playlists.indexOf(playlist);
      if (index > -1) {
        this.newWorkout.playlists.splice(index, 1);
      }
    }

    for (const exerciseToRemove of this.playlistExercisesSelected) {
      for (const playlist of this.newWorkout.playlists) {
        for (const exercise of playlist.exerciseList) {
          index = playlist.exerciseList.indexOf(exerciseToRemove);
          if (index > -1) {
            playlist.exerciseList.splice(index, 1);
          }
        }
      }
    }
  }

  removePlaylist(index: number) {
    this.newWorkout.playlists.splice(index, 1);
  }

  removeExercise(playlistIndex: number, exerciseIndex: number) {
    this.newWorkout.playlists[playlistIndex].exerciseList.splice(exerciseIndex, 1);
  }

  filterExerciseList(value) {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(() => {
      if (value.length === 0) {
        this.filteredExerciseList = Object.assign([], this.exerciseList);
        const length = this.exerciseList.length;
        this.canLoadExerciseList = length % 20 === 0 && length !== 0;
        return;
      }
      if (value.length < 2) {
        return;
      }
      this.searching = true;
      this.sharedPlaylistService
        .getExerciseDetails(
          0,
          20,
          this.newWorkout.playlists[this.playlistIndex].exerciseList.map((value) => value.exerciseId),
          value
        )
        .subscribe(
          (response: any) => {
            this.filteredExerciseList = response;
            const length = response.length;
            this.canLoadExerciseList = length % 20 === 0 && length !== 0;
            this.searching = false;
          },
          (error) => {
            this.notificationService.error(error);
            this.searching = false;
          }
        );
    }, 350);
  }

  isAlreadyAdded(exercise: any): boolean {
    for (const addedExercise of this.addExercisesSelected) {
      if (addedExercise.id === exercise.id) {
        return true;
      }
    }
    return false;
  }

  // isAlreadyAdded(exercise: any): boolean {
  //     for (const addedExercise of this.addExercisesSelected) {
  //         if (addedExercise.exerciseId === exercise.exerciseId) {
  //             return true;
  //         }
  //     }
  //     return false;
  // }

  cannotSaveWorkout(): boolean {
    if (this.newWorkout.name == "" || this.newWorkout.playlists.length == 0) {
      return true;
    }
    let number = 0;
    for (const playlist of this.newWorkout.playlists) {
      if (playlist.exerciseList.length == 0 || playlist.name === "") {
        number++;
      }
    }
    return number === this.newWorkout.playlists.length;
  }

  openBackModal() {
    const config = new TemplateModalConfig<string, string, string>(this.confirmBackModal);
    config.mustScroll = true;
    config.size = "small";
    this.modalService.open(config);
  }

  openEditModal() {
    const config = new TemplateModalConfig<string, string, string>(this.confirmEditModal);
    config.mustScroll = true;
    config.size = "small";
    this.modalService.open(config);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.globalSub.unsubscribe();
    this.dragulaService.destroy("playlistBag");
    this.dragulaService.destroy("exerciseBag");
  }

  // onClicker(p) {
  //     console.log(this.automatedArray);
  //     console.log(p);
  // }

  toggleAutomated(newPlaylist, i) {
    if (this.automatedArray[i]) {
      newPlaylist.type = 2;
      // this.automated = !this.automated;
      // this.automatedArray[i] = false;
    } else {
      newPlaylist.type = 0;
      // this.automated = !this.automated;
      // this.automatedArray[i] = true;
    }
  }

  toggleCircuit(newPlaylist, i) {
    if (this.circuitArray[i]) {
      newPlaylist.type = 1;
      // this.circuitArray[i] = false;
    } else if (!this.circuitArray[i] && this.automatedArray[i]) {
      newPlaylist.type = 2;
    } else {
      newPlaylist.type = 0;
      // this.circuitArray[i] = true;
    }
  }
}
