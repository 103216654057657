import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {JwtHelperService} from "@auth0/angular-jwt";
import {AuthService} from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class ClinicAdminGuard implements CanActivate {

    jwtHelper;

    constructor(private router: Router) {
        this.jwtHelper = new JwtHelperService();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const localToken = AuthService.getOauthRefreshTokenFromStorage();
        if (localToken !== null) {
            const token = this.jwtHelper.decodeToken(localToken);
            if (this.jwtHelper.isTokenExpired(localToken, 0)) {
                AuthService.clearStorageData();
                return this.refuse();
            }
            if (token.authorities != null) {
                if (token.authorities.includes('ROLE_CLINIC') || token.authorities.includes('ROLE_MULTI_PERFORMANCE')) {
                    return true;
                } else
                    return false;
            }
        }
        return this.refuse();
    }

    refuse(): boolean {
        this.router.navigate(['']);
        return false;
    }

}
