
import {MatDatepickerModule} from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MatExpansionModule, MatIconModule, MatRadioModule } from '@angular/material';
import {MatRangeDatepickerModule, MatRangeNativeDateModule} from 'mat-range-datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        MatMenuModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        MatRangeDatepickerModule,
        MatRangeNativeDateModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatIconModule,
        MatRadioModule,
        MatTooltipModule,
        MatSelectModule,
        MatProgressSpinnerModule
    ],
    exports: [

        MatDatepickerModule,
        MatMenuModule,
        MatInputModule,
        MatNativeDateModule,
        MatRangeDatepickerModule,
        MatRangeNativeDateModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatIconModule,
        MatRadioModule,
        MatTooltipModule,
        MatSelectModule,
        MatProgressSpinnerModule
    ]
})
export class MaterialModule {

}