import { OnInit, Component, OnDestroy, ViewChild } from "@angular/core";
import { SaveExerciseService } from "../../services/save-exercise.service";
import { SharedExerciseService } from "../../services/shared-exercise.service";
import { ModalTemplate, SuiModalService, TemplateModalConfig } from "ng2-semantic-ui";
import { delayArray, flashArray } from "../../helpers/exercise-data";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FileUploader } from "ng2-file-upload";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "../../services/notification.service";
import { MixpanelService } from "../../services/mixpanel.service";
import { SaveExerciseComponent } from "../save-exercise.component";


@Component({
    selector: 'app-save-paired-exercise',
    templateUrl: './save-paired-exercise.component.html',
    styleUrls: ['../save-exercise.component.scss', '../../styles.common.css'],
    providers: [SaveExerciseService, SharedExerciseService]
})
export class SavePairedExerciseComponent implements OnInit {

    @ViewChild('confirmModalTemplate')
    confirmModalTemplate: ModalTemplate<string, string, string>;
    @ViewChild('confirmBackModal') confirmBackModal: ModalTemplate<string, string, string>;
    id;
    uuid;
    private sub;

    pairedExerciseName: string = null;

    categoryMode2 = 'select';
    categoryInput2 = '';
    reactSensors2 = [];
    sequenceSensors2 = [];
    agilitySensors2 = [];
    exerciseCategories2;
    categoryOptions2;
    delayArray2 = delayArray;
    arrowDelayArray2 = this.delayArray2.slice(1, this.delayArray2.length);
    flashArray2 = flashArray;
    react2 = [];
    dontReact2 = [];
    mainform2: FormGroup;
    countForm2: FormGroup;
    reactForm2: FormGroup;
    sequenceForm2: FormGroup;
    agilityForm2: FormGroup;
    additionalValues = {
        boardType: 0,
        favorited: false,
        paired: true,
        creationDate: null
    };
    selectedSettings2: any = {};
    videoUploader2: FileUploader;
    video2 = null;
    loading = false;
    selectedCategoryIsUserCreated2;
    poppedUp2 = false;
    userCreated2 = true;
    countdownSeconds2 = new Array(60).fill(0,0,60).map((x,i)=>i);
    countdownSecondsDelay2 = new Array(60).fill(0,0,60).map((x,i)=>i);
    secondsDelayDropdownModel2 ;
    secondsDropdownModel2 ;


    categoryMode = 'select';
    categoryInput = '';
    reactSensors = [];
    sequenceSensors = [];
    agilitySensors = [];
    exerciseCategories;
    categoryOptions;
    delayArray = delayArray;
    arrowDelayArray = this.delayArray.slice(1, this.delayArray.length);
    flashArray = flashArray;
    react = [];
    dontReact = [];
    mainForm: FormGroup;
    countForm: FormGroup;
    reactForm: FormGroup;
    sequenceForm: FormGroup;
    agilityForm: FormGroup;

    selectedSettings: any = {};
    videoUploader: FileUploader;
    video = null;
    selectedCategoryIsUserCreated;
    poppedUp = false;
    userCreated = true;
    countdownSeconds = new Array(60).fill(0,0,60).map((x,i)=>i);
    countdownSecondsDelay = new Array(60).fill(0,0,60).map((x,i)=>i);
    secondsDelayDropdownModel ;
    secondsDropdownModel ;
    toggle = true;

    constructor(private route: ActivatedRoute, private saveExerciseService: SaveExerciseService, private router: Router,
                private sharedExerciseService: SharedExerciseService, private notificationService: NotificationService,
                private formBuilder: FormBuilder, private modalService: SuiModalService, private mixpanelService: MixpanelService) {
    }

    static startValueValidation() {
        return (group: FormGroup) => {
            if (group.controls['startType'].value === 1 && !group.controls['startValue'].value) {
                group.controls['startValue'].setErrors({startValueRequired: true});
            }
        };
    }

    static neurocognitiveValidation(react, dontReact, inactiveSensors) {
        return (group: FormGroup) => {
            switch (group.controls['cognitiveReact'].value) {
                case 1:
                    const sensorsLength = 5 - inactiveSensors.length - 1;
                    if (react.length !== 1 || dontReact.length !== (sensorsLength || 1)) {
                        group.controls['cognitiveReact'].setErrors({invalidNeurocognitive: true});
                    }
                    break;
                case 2:
                    const reactLength = react.length;
                    const dontReactLength = dontReact.length;
                    if (reactLength < 1 || dontReactLength < 1) {
                        group.controls['cognitiveReact'].setErrors({invalidNeurocognitive: true});
                    }
                    break;
            }
        };
    }

    static activeSensorValidation(reactSensors) {
        return (group: FormGroup) => {
            let maximumSensors = 4;
            if (group.controls['cognitiveReact'].value === 1) {
                maximumSensors = 3;
            }
            if (group.controls['isolatedReact'].value === 1 && reactSensors.length > maximumSensors) {
                group.controls['isolatedReact'].setErrors({invalidSensors: true});
            }
        };
    }

    static sequenceValidation(sequenceSensors) {
        return (group: FormGroup) => {
            const length = sequenceSensors.length;
            if (length < 2 || length > 20) {
                group.controls['delay'].setErrors({invalidSequence: true});
            }
        };
    }

    static arrowValidation(agilitySensors) {
        return (group: FormGroup) => {
            const length = agilitySensors.length;
            if (group.controls['agilityCustomType'].value === 0) {
                if (length < 2 || length > 20) {
                    group.controls['agilityCustomType'].setErrors({invalidArrows: true});
                }
            } else {
                if (length === 0) {
                    group.controls['agilityCustomType'].setErrors({invalidActiveArrows: true});
                }
            }
        };
    }

    ngOnInit() {
        this.mixpanelService.track('exercise_save_screen', {});


        this.mainForm = this.formBuilder.group({
            active: [true, Validators.required],
            title: ['', [Validators.required]],
            category: ['', Validators.required],
            type: [0, Validators.required],
            startType: [1, Validators.required],
            startValue: [''],
            endType: [2, Validators.required],
            endValue: ['', Validators.required],
            videoUrl: ['']
        }, {validator: SaveExerciseComponent.startValueValidation()});
        this.countForm = this.formBuilder.group({
            countType: [0, Validators.required]
        });
        this.reactForm = this.formBuilder.group({
            delay: [0.1, Validators.required],
            flashTime: [0, Validators.required],
            cognitiveReact: [0, Validators.required],
            isolatedReact: [0, Validators.required]
        }, {
            validator: [
                SaveExerciseComponent.neurocognitiveValidation(this.react, this.dontReact, this.reactSensors),
                SaveExerciseComponent.activeSensorValidation(this.reactSensors)]
        });
        this.sequenceForm = this.formBuilder.group({
            delay: [0.1, Validators.required],
            flashTime: [0, Validators.required]
        }, {validator: SaveExerciseComponent.sequenceValidation(this.sequenceSensors)});
        this.agilityForm = this.formBuilder.group({
            delay: [0.1, Validators.required],
            agilityCustomType: [0, Validators.required]
        }, {validator: SaveExerciseComponent.arrowValidation(this.agilitySensors)});

        this.mainform2 = this.formBuilder.group({
            active: [true, Validators.required],
            title: ['', [Validators.required]],
            category: ['', Validators.required],
            type: [0, Validators.required],
            startType: [1, Validators.required],
            startValue: [''],
            endType: [2, Validators.required],
            endValue: ['', Validators.required],
            videoUrl: ['']
        }, {validator: SaveExerciseComponent.startValueValidation()});
        this.countForm2 = this.formBuilder.group({
            countType: [0, Validators.required]
        });
        this.reactForm2 = this.formBuilder.group({
            delay: [0.1, Validators.required],
            flashTime: [0, Validators.required],
            cognitiveReact: [0, Validators.required],
            isolatedReact: [0, Validators.required]
        }, {
            validator: [
                SaveExerciseComponent.neurocognitiveValidation(this.react2, this.dontReact2, this.reactSensors2),
                SaveExerciseComponent.activeSensorValidation(this.reactSensors2)]
        });
        this.sequenceForm2 = this.formBuilder.group({
            delay: [0.1, Validators.required],
            flashTime: [0, Validators.required]
        }, {validator: SaveExerciseComponent.sequenceValidation(this.sequenceSensors2)});
        this.agilityForm2 = this.formBuilder.group({
            delay: [0.1, Validators.required],
            agilityCustomType: [0, Validators.required]
        }, {validator: SaveExerciseComponent.arrowValidation(this.agilitySensors2)});

        this.getCategories2();
        this.getCategories();

        // this.sub = this.route.params.subscribe(params => {
        //     this.id = params['id'];
        //     this.id !== undefined ?
        //         this.retrieveExercise() :
        //         this.getCategories2();
        // }, error => this.notificationService.error(error));

        this.videoUploader = new FileUploader({
            queueLimit: 1
        });
        this.videoUploader.onAfterAddingFile = file => {
            if (file.file.type.indexOf('video') >= 0 && file.file.size <= 200000000) {
                this.video = file.file;
            } else {
                this.notificationService.warn("Invalid video type or size submitted!");
                this.video = null;
            }
        };

        this.videoUploader2 = new FileUploader({
            queueLimit: 1
        });
        this.videoUploader2.onAfterAddingFile = file => {
            if (file.file.type.indexOf('video') >= 0 && file.file.size <= 200000000) {
                this.video2 = file.file;
            } else {
                this.notificationService.warn("Invalid video type or size submitted!");
                this.video2= null;
            }
        };

    }

    retrieveExercise() {
        this.saveExerciseService.getExercise(this.id).subscribe(response => {
            const exercise = response.exercise;
            this.additionalValues = {
                boardType: exercise.boardType,
                favorited: exercise.favorited,
                paired: exercise.paired,
                creationDate: exercise.creationDate
            };
            this.userCreated2 = exercise.userCreated;
            const exerciseType = exercise.exerciseType;
            this.mainform2.controls['type'].setValue(exerciseType);
            this.mainform2.controls['videoUrl'].setValue(exercise.videoUrl);
            this.video2 = exercise.videoUrl && exercise.videoUrl.length > 0 ?
                {name: exercise.videoUrl.slice(exercise.videoUrl.lastIndexOf('/') + 1, exercise.videoUrl.length)} : null;
            this.mainform2.controls['active'].setValue(exercise.active);
            this.mainform2.controls['title'].setValue(exercise.name);
            if (!this.userCreated2) {
                this.mainform2.controls['title'].disable();
            }
            this.getAndUpdateCategories(exercise.categoryId);

            this.getAndUpdateCategories2(exercise.categoryId);


            this.selectedSettings2 = response.exerciseSettings;
            this.mainform2.controls['startType'].setValue(this.selectedSettings2.startType);
            this.mainform2.controls['startValue'].setValue(this.selectedSettings2.startValue);
            this.mainform2.controls['endType'].setValue(this.selectedSettings2.endType);
            this.mainform2.controls['endValue'].setValue(this.selectedSettings2.endValue);

            switch (exerciseType) {
                case 0:
                    this.countForm2.controls['countType'].setValue(this.selectedSettings2.countType);
                    break;
                case 1:
                    this.reactForm2.controls['delay'].setValue(this.selectedSettings2.delay);
                    if (this.selectedSettings2.promptType == 0)
                        this.reactForm2.controls['flashTime'].setValue(0);
                    else
                        this.reactForm2.controls['flashTime'].setValue(this.selectedSettings2.flashTime);
                    this.reactForm2.controls['cognitiveReact'].setValue(this.selectedSettings2.cognitiveReact);
                    if (this.selectedSettings2.inactiveSensors === null) {
                        this.reactForm2.controls['isolatedReact'].setValue(0);
                    } else {
                        this.reactForm2.controls['isolatedReact'].setValue(1);
                        Object.assign(this.reactSensors2, this.selectedSettings2.inactiveSensors);
                    }
                    Object.assign(this.dontReact2, this.selectedSettings2.dontReactTo);
                    Object.assign(this.react2, this.selectedSettings2.reactTo);
                    break;
                case 2:
                    this.sequenceForm2.controls['delay'].setValue(this.selectedSettings2.delay);
                    if (this.selectedSettings2.promptType == 0)
                        this.sequenceForm2.controls['flashTime'].setValue(0);
                    else
                        this.sequenceForm2.controls['flashTime'].setValue(this.selectedSettings2.flashTime);
                    Object.assign(this.sequenceSensors2, this.selectedSettings2.sequence);
                    break;
                case 3:
                    this.agilityForm2.controls['delay'].setValue(this.selectedSettings2.delay);
                    this.agilityForm2.controls['agilityCustomType'].setValue(this.selectedSettings2.agilityCustomType);
                    Object.assign(this.agilitySensors2, this.selectedSettings2.sequence);
                    break;
                default:
                    this.notificationService.error('Invalid exercise type.');
                    this.mainform2.controls['type'].setValue(0);
                    break;
            }
        }, error => this.notificationService.error(error));
    }

    currentForm() {
        switch (this.mainForm.controls['type'].value) {
            case 0:
                return this.countForm;
            case 1:
                return this.reactForm;
            case 2:
                return this.sequenceForm;
            case 3:
                return this.agilityForm;
            default:
                this.notificationService.error('Invalid exercise type.');
                this.mainForm.controls['type'].setValue(0);
                return this.countForm;
        }
    }

    currentForm2() {
        switch (this.mainform2.controls['type'].value) {
            case 0:
                return this.countForm2;
            case 1:
                return this.reactForm2;
            case 2:
                return this.sequenceForm2;
            case 3:
                return this.agilityForm2;
            default:
                this.notificationService.error('Invalid exercise type.');
                this.mainform2.controls['type'].setValue(0);
                return this.countForm2;
        }
    }

    savePairedExercise() {
        this.loading = true;
        const data = new FormData();
        this.video && this.video.rawFile != null ?
        data.append('file1', this.video.rawFile) :
        data.append('file1', new Blob());

        const selectedCategory = this.mainForm.controls['category'].value;
        let categoryId, categoryUUID;
        for (const c of this.exerciseCategories) {
            if (c.name === selectedCategory) {
                categoryId = c.id;
                categoryUUID = c.uuid;
                break;
            }
        }

        const exerciseType = this.mainForm.controls['type'].value;
        this.getExerciseSettings(exerciseType);
        this.selectedSettings.startType = this.mainForm.controls['startType'].value;
        this.selectedSettings.startValue = this.mainForm.controls['startValue'].value;
        this.selectedSettings.endType = this.mainForm.controls['endType'].value;
        this.selectedSettings.endValue = this.mainForm.controls['endValue'].value;

        if (this.selectedSettings.flashTime) {
            this.selectedSettings.promptType =1;
        } else {
            this.selectedSettings.promptType = 0;
        }

        data.append('exercise1', new Blob([JSON.stringify({
            id: this.id,
            categoryId: categoryId,
            categoryUUID: categoryUUID,
            name: this.mainForm.controls['title'].value,
            active: this.mainForm.controls['active'].value,
            exerciseType: exerciseType,
            videoUrl: this.mainForm.controls['videoUrl'].value,
            creationDate: this.additionalValues.creationDate,
            paired: this.additionalValues.paired,
            favorited: this.additionalValues.favorited,
            boardType: this.additionalValues.boardType,
            exerciseSettings: this.selectedSettings
        })], {type: 'application/json'}));

        this.video2 && this.video2.rawFile != null ?
            data.append('file2', this.video2.rawFile) :
            data.append('file2', new Blob());

        const selectedCategory2 = this.mainform2.controls['category'].value;
        let categoryId2, categoryUUID2;
        for (const category of this.exerciseCategories2) {
            if (category.name === selectedCategory2) {
                categoryId2 = category.id;
                categoryUUID2 = category.uuid;
                break;
            }
        }

        const exerciseType2 = this.mainform2.controls['type'].value;
        this.getExerciseSettings2(exerciseType2);
        this.selectedSettings2.startType = this.mainform2.controls['startType'].value;
        this.selectedSettings2.startValue = this.mainform2.controls['startValue'].value;
        this.selectedSettings2.endType = this.mainform2.controls['endType'].value;
        this.selectedSettings2.endValue = this.mainform2.controls['endValue'].value;

        
        if (this.selectedSettings2.flashTime) {
            this.selectedSettings2.promptType = 1;
        } else {
            this.selectedSettings2.promptType = 0;
        }

        data.append('exercise2', new Blob([JSON.stringify({
            id: this.id,
            categoryId: categoryId2,
            categoryUUID: categoryUUID2,
            name: this.mainform2.controls['title'].value,
            active: this.mainform2.controls['active'].value,
            exerciseType: exerciseType2,
            videoUrl: this.mainform2.controls['videoUrl'].value,
            creationDate: this.additionalValues.creationDate,
            paired: this.additionalValues.paired,
            favorited: this.additionalValues.favorited,
            boardType: this.additionalValues.boardType,
            exerciseSettings: this.selectedSettings2
        })], {type: 'application/json'}));

        data.append('pairedName', this.pairedExerciseName);

        this.saveExerciseService.savePairedExercise(data).subscribe(data => {
            this.loading = false;
            this.router.navigate(['/exercises']);
            this.notificationService.success("Paired Exercises saved successfully.")
        }, error => {
            this.notificationService.error(error);
            this.loading = false;
        })

    }

    saveExercise() {
        this.loading = true;
        const data = new FormData();
        this.video2 && this.video2.rawFile != null ?
            data.append('file', this.video2.rawFile) :
            data.append('file', new Blob());

        const selectedCategory = this.mainform2.controls['category'].value;
        let categoryId, categoryUUID;
        for (const category of this.exerciseCategories2) {
            if (category.name === selectedCategory) {
                categoryId = category.id;
                categoryUUID = category.uuid;
                break;
            }
        }

        const exerciseType = this.mainform2.controls['type'].value;
        this.getExerciseSettings2(exerciseType);
        this.selectedSettings2.startType = this.mainform2.controls['startType'].value;
        this.selectedSettings2.startValue = this.mainform2.controls['startValue'].value;
        this.selectedSettings2.endType = this.mainform2.controls['endType'].value;
        this.selectedSettings2.endValue = this.mainform2.controls['endValue'].value;
        if (!this.selectedSettings2.prompType)
            this.selectedSettings2.promptType = 0;
        data.append('exercise', new Blob([JSON.stringify({
            id: this.id,
            categoryId: categoryId,
            categoryUUID: categoryUUID,
            name: this.mainform2.controls['title'].value,
            active: this.mainform2.controls['active'].value,
            exerciseType: exerciseType,
            videoUrl: this.mainform2.controls['videoUrl'].value,
            creationDate: this.additionalValues.creationDate,
            paired: this.additionalValues.paired,
            favorited: this.additionalValues.favorited,
            boardType: this.additionalValues.boardType,
            exerciseSettings: this.selectedSettings2
        })], {type: 'application/json'}));

        this.saveExerciseService.saveExercise(data).subscribe(() => {
            this.loading = false;
            this.router.navigate(['/exercises']);
            this.notificationService.success("Exercise saved successfully.");
        }, error => {
            this.notificationService.error(error);
            this.loading = false;
        });
    }

    getExerciseSettings(exerciseType: number) {
        switch (exerciseType) {
            case 0:
                this.selectedSettings.countType = this.countForm.controls['countType'].value;
                break;
            case 1:
                this.selectedSettings.delay = this.reactForm.controls['delay'].value;
                this.selectedSettings.flashTime = this.reactForm.controls['flashTime'].value;
                this.selectedSettings.cognitiveReact = this.reactForm.controls['cognitiveReact'].value;
                this.selectedSettings.dontReactTo = this.dontReact;
                this.selectedSettings.inactiveSensors = this.reactForm.controls['isolatedReact'].value === 1 ? this.reactSensors : null;
                this.selectedSettings.reactTo = this.react;
                break;
            case 2:
                this.selectedSettings.delay = this.sequenceForm.controls['delay'].value;
                this.selectedSettings.flashTime = this.sequenceForm.controls['flashTime'].value;
                this.selectedSettings.sequence = this.sequenceSensors;
                break;
            case 3:
                this.selectedSettings.delay = this.agilityForm.controls['delay'].value;
                this.selectedSettings.agilityCustomType = this.agilityForm.controls['agilityCustomType'].value;
                this.selectedSettings.sequence = this.agilitySensors;
                break;
            default:
                this.notificationService.error('Invalid exercise type.');
                this.mainForm.controls['type'].setValue(0);
                break;
        }
    }

    getExerciseSettings2(exerciseType: number) {
        switch (exerciseType) {
            case 0:
                this.selectedSettings2.countType = this.countForm2.controls['countType'].value;
                break;
            case 1:
                this.selectedSettings2.delay = this.reactForm2.controls['delay'].value;
                this.selectedSettings2.flashTime = this.reactForm2.controls['flashTime'].value;
                this.selectedSettings2.cognitiveReact = this.reactForm2.controls['cognitiveReact'].value;
                this.selectedSettings2.dontReactTo = this.dontReact2;
                this.selectedSettings2.inactiveSensors = this.reactForm2.controls['isolatedReact'].value === 1 ? this.reactSensors2 : null;
                this.selectedSettings2.reactTo = this.react2;
                break;
            case 2:
                this.selectedSettings2.delay = this.sequenceForm2.controls['delay'].value;
                this.selectedSettings2.flashTime = this.sequenceForm2.controls['flashTime'].value;
                this.selectedSettings2.sequence = this.sequenceSensors2;
                break;
            case 3:
                this.selectedSettings2.delay = this.agilityForm2.controls['delay'].value;
                this.selectedSettings2.agilityCustomType = this.agilityForm2.controls['agilityCustomType'].value;
                this.selectedSettings2.sequence = this.agilitySensors2;
                break;
            default:
                this.notificationService.error('Invalid exercise type.');
                this.mainform2.controls['type'].setValue(0);
                break;
        }
    }

    getAndUpdateCategories(categoryId: string) {
        this.sharedExerciseService.getCategories().subscribe(response => {
            this.exerciseCategories = response;
            this.categoryOptions = this.exerciseCategories.map(category => category.name);
            for (const category of this.exerciseCategories) {
                if (category.id === categoryId) {
                    this.mainForm.controls['category'].setValue(category.name);
                    this.categoryInput = category.name;
                    break;
                }
            }
        }, error => this.notificationService.error(error));
    }

    getAndUpdateCategories2(categoryId: string) {
        this.sharedExerciseService.getCategories().subscribe(response => {

            this.exerciseCategories2 = response;
            this.categoryOptions2 = this.exerciseCategories2.map(category => category.name);
            for (const category of this.exerciseCategories2) {
                if (category.id === categoryId) {
                    this.mainform2.controls['category'].setValue(category.name);
                    this.categoryInput2 = category.name;
                    break;
                }
            }
        }, error => this.notificationService.error(error));
    }

    getCategories() {
        this.sharedExerciseService.getCategories().subscribe(response => {
            this.exerciseCategories = response;
            this.categoryOptions = this.exerciseCategories.map(category => category.name);
        }, error => this.notificationService.error(error));
    }

    getCategories2() {

        this.sharedExerciseService.getCategories().subscribe(response => {

            this.exerciseCategories2 = response;
            this.categoryOptions2 = this.exerciseCategories2.map(category => category.name);
        }, error => this.notificationService.error(error));
    }

    getCategoryId(categoryName: string) {
        for (const category of this.exerciseCategories) {
            if (category.name === categoryName) {
                return category.id;
            }
        }
        this.notificationService.error('Could not find category.');
        return null;
    }

    getCategoryId2(categoryName: string) {
        for (const category of this.exerciseCategories2) {
            if (category.name === categoryName) {
                return category.id;
            }
        }
        this.notificationService.error('Could not find category.');
        return null;
    }

    addExerciseCategory() {
        let found = false;
        for (const category of this.exerciseCategories) {
            if (category.name.toLowerCase().trim() === this.categoryInput.toLowerCase().trim()) {
                found = true;
                break;
            }
        }
        found ?
            this.notificationService.error('This category already exists.') :
            this.saveExerciseService.saveCategory({
                name: this.categoryInput,
                creationDate: Date.now()
            }).subscribe(response => {
                this.getCategories();
                this.mainForm.controls['category'].setValue(this.categoryInput);
                this.notificationService.success("Exercise category added successfully.");
            }, error => this.notificationService.error(error));
    }

    addExerciseCategory2() {
        let found = false;
        for (const category of this.exerciseCategories2) {
            if (category.name.toLowerCase().trim() === this.categoryInput2.toLowerCase().trim()) {
                found = true;
                break;
            }
        }
        found ?
            this.notificationService.error('This category already exists.') :
            this.saveExerciseService.saveCategory({
                name: this.categoryInput2,
                creationDate: Date.now()
            }).subscribe(response => {
                this.getCategories2();
                this.mainform2.controls['category'].setValue(this.categoryInput2);
                this.notificationService.success("Exercise category added successfully.");
            }, error => this.notificationService.error(error));
    }

    updateExerciseCategory() {
        this.saveExerciseService.saveCategory({
            id: this.getCategoryId(this.mainForm.controls['category'].value),
            name: this.categoryInput,
            active: true
        }).subscribe(response => {
            this.getCategories();
            this.mainForm.controls['category'].setValue(this.categoryInput);
            this.notificationService.success("Exercise category updated successfully.");
        }, error => this.notificationService.error(error));
    }

    updateExerciseCategory2() {
        this.saveExerciseService.saveCategory({
            id: this.getCategoryId2(this.mainform2.controls['category'].value),
            name: this.categoryInput2,
            active: true
        }).subscribe(response => {
            this.getCategories2();
            this.mainform2.controls['category'].setValue(this.categoryInput2);
            this.notificationService.success("Exercise category updated successfully.");
        }, error => this.notificationService.error(error));
    }

    deleteExerciseCategory() {
        this.saveExerciseService.deleteCategory(this.getCategoryId(this.mainForm.controls['category'].value)).subscribe(() => {
            this.getCategories();
            this.mainForm.controls['category'].setValue('');
            this.notificationService.success("Exercise category deleted successfully.");
        }, error => error.status === 409 ? this.notificationService.warn(error) : this.notificationService.error(error));
    }

    deleteExerciseCategory2() {
        this.saveExerciseService.deleteCategory(this.getCategoryId2(this.mainform2.controls['category'].value)).subscribe(() => {
            this.getCategories2();
            this.mainform2.controls['category'].setValue('');
            this.notificationService.success("Exercise category deleted successfully.");
        }, error => error.status === 409 ? this.notificationService.warn(error) : this.notificationService.error(error));
    }

    setExerciseType(type: number) {
        if (this.id === undefined) {
            this.mainForm.controls['endType'].setValue(2);
            this.mainForm.controls['type'].setValue(type);
        }
    }

    setExerciseType2(type: number) {
        if (this.id === undefined) {
            this.mainform2.controls['endType'].setValue(2);
            this.mainform2.controls['type'].setValue(type);
        }
    }

    
    goToAgilitySequence() {
        if (this.agilityForm.controls['agilityCustomType'].value == 0) {
            this.emptyAgilitySensors();
            Object.assign(this.agilitySensors, [1, 2, 3, 4, 5, 6, 7, 8, 9]);
            this.agilityForm.controls['agilityCustomType'].setValue(1);
        }
        this.poppedUp = true;
    }

    goToAgilitySequence2() {
        if (this.agilityForm2.controls['agilityCustomType'].value == 0) {
            this.emptyAgilitySensors2();
            Object.assign(this.agilitySensors2, [1, 2, 3, 4, 5, 6, 7, 8, 9]);
            this.agilityForm2.controls['agilityCustomType'].setValue(1);
        }
        this.poppedUp2 = true;
    }

    goToAgilityReact() {
        if (this.agilityForm.controls['agilityCustomType'].value == 1) {
            this.emptyAgilitySensors();
            this.agilityForm.controls['agilityCustomType'].setValue(0);
        }
        this.poppedUp = true;
    }

    goToAgilityReact2() {
        if (this.agilityForm2.controls['agilityCustomType'].value == 1) {
            this.emptyAgilitySensors2();
            this.agilityForm2.controls['agilityCustomType'].setValue(0);
        }
        this.poppedUp2 = true;
    }

    resetAgilitySensors(type: number) {
        this.emptyAgilitySensors();
        if (type === 1) {
            Object.assign(this.agilitySensors, [1, 2, 3, 4, 5, 6, 7, 8, 9]);
        }
    }

    resetAgilitySensors2(type: number) {
        this.emptyAgilitySensors2();
        if (type === 1) {
            Object.assign(this.agilitySensors2, [1, 2, 3, 4, 5, 6, 7, 8, 9]);
        }
    }

    emptyAgilitySensors() {
        while (this.agilitySensors.length != 0) {
            this.agilitySensors.pop();
        }
    }

    emptyAgilitySensors2() {
        while (this.agilitySensors2.length != 0) {
            this.agilitySensors2.pop();
        }
    }

    resetReact() {
        this.reactSensors.splice(0, this.reactSensors.length);
        this.reactForm.controls['isolatedReact'].updateValueAndValidity();
    }

    resetReact2() {
        this.reactSensors2.splice(0, this.reactSensors2.length);
        this.reactForm2.controls['isolatedReact'].updateValueAndValidity();
    }

    setReact(color: number, react: boolean) {
        let array;
        let otherArray;

        if (react) {
            array = this.react;
            otherArray = this.dontReact;
        } else {
            array = this.dontReact;
            otherArray = this.react;
        }

        let index = array.indexOf(color);
        index > -1 ?
            array.splice(index, 1) :
            array.push(color);

        index = otherArray.indexOf(color);
        if (index > -1) {
            otherArray.splice(index, 1);
        }
        this.reactForm.controls['cognitiveReact'].updateValueAndValidity();
    }

    setReact2(color: number, react: boolean) {
        let array;
        let otherArray;

        if (react) {
            array = this.react2;
            otherArray = this.dontReact2;
        } else {
            array = this.dontReact2;
            otherArray = this.react2;
        }

        let index = array.indexOf(color);
        index > -1 ?
            array.splice(index, 1) :
            array.push(color);

        index = otherArray.indexOf(color);
        if (index > -1) {
            otherArray.splice(index, 1);
        }
        this.reactForm2.controls['cognitiveReact'].updateValueAndValidity();
    }

    setSensor(sensor: number) {
        const index = this.reactSensors.indexOf(sensor);
        index > -1 ?
            this.reactSensors.splice(index, 1) :
            this.reactSensors.push(sensor);

        this.reactForm.controls['isolatedReact'].updateValueAndValidity();
    }

    setSensor2(sensor: number) {
        const index = this.reactSensors2.indexOf(sensor);
        index > -1 ?
            this.reactSensors2.splice(index, 1) :
            this.reactSensors2.push(sensor);

        this.reactForm2.controls['isolatedReact'].updateValueAndValidity();
    }

    resetSequence() {
        this.sequenceSensors.splice(0, this.sequenceSensors.length);
        this.sequenceForm.controls['delay'].updateValueAndValidity();
    }

    resetSequence2() {
        this.sequenceSensors2.splice(0, this.sequenceSensors2.length);
        this.sequenceForm2.controls['delay'].updateValueAndValidity();
    }

    addSequence(sensor: number) {
        const length = this.sequenceSensors.length;
        if (length < 26) {
            this.sequenceSensors.push(sensor);
            this.sequenceForm.controls['delay'].updateValueAndValidity();
        }
    }

    addSequence2(sensor: number) {
        const length = this.sequenceSensors2.length;
        if (length < 26) {
            this.sequenceSensors2.push(sensor);
            this.sequenceForm2.controls['delay'].updateValueAndValidity();
        }
    }

    addArrows(arrow: number) {
        if (this.agilityForm.controls['agilityCustomType'].value === 0) {
            const length = this.agilitySensors.length;
            if (length < 26) {
                this.agilitySensors.push(arrow);
                this.agilityForm.controls['agilityCustomType'].updateValueAndValidity();
            }
        } else {
            const index = this.agilitySensors.indexOf(arrow);
            index > -1 ?
                this.agilitySensors.splice(index, 1) :
                this.agilitySensors.push(arrow);

            this.agilityForm.controls['agilityCustomType'].updateValueAndValidity();
        }
    }

    addArrows2(arrow: number) {
        if (this.agilityForm2.controls['agilityCustomType'].value === 0) {
            const length = this.agilitySensors2.length;
            if (length < 26) {
                this.agilitySensors2.push(arrow);
                this.agilityForm2.controls['agilityCustomType'].updateValueAndValidity();
            }
        } else {
            const index = this.agilitySensors2.indexOf(arrow);
            index > -1 ?
                this.agilitySensors2.splice(index, 1) :
                this.agilitySensors2.push(arrow);

            this.agilityForm2.controls['agilityCustomType'].updateValueAndValidity();
        }
    }

    arrowsColorCondition(arrow: number) {
        return this.agilityForm.controls['agilityCustomType'].value === 0 || this.agilitySensors.indexOf(arrow) > -1;
    }

    arrowsColorCondition2(arrow: number) {
        return this.agilityForm2.controls['agilityCustomType'].value === 0 || this.agilitySensors2.indexOf(arrow) > -1;
    }

    removeAgilitySensor(index: number) {
        this.agilitySensors.splice(index, 1);
    }

    removeAgilitySensor2(index: number) {
        this.agilitySensors2.splice(index, 1);
    }

    removeSequenceSensor(index: number) {
        this.sequenceSensors.splice(index, 1);
    }

    removeSequenceSensor2(index: number) {
        this.sequenceSensors2.splice(index, 1);
    }

    openConfirmModal() {
        this.modalService.open(new TemplateModalConfig<string, string, string>(this.confirmModalTemplate));
    }


    checkPopUp() {
        if (this.reactForm.controls['isolatedReact'].value == 1) {
            this.poppedUp = true;
        }
    }

    checkPopUp2() {
        if (this.reactForm2.controls['isolatedReact'].value == 1) {
            this.poppedUp2 = true;
        }
    }

    

    openConfirmModal2() {
        this.modalService.open(new TemplateModalConfig<string, string, string>(this.confirmModalTemplate));
    }

    

    selectExerciseCategory(name: string) {
        this.categoryInput = name;
        for (const category of this.exerciseCategories) {
            if (category.name === name) {
                this.selectedCategoryIsUserCreated = category.userCreated;
                break;
            }
        }
    }

    selectExerciseCategory2(name: string) {
        this.categoryInput2 = name;
        for (const category of this.exerciseCategories2) {
            if (category.name === name) {
                this.selectedCategoryIsUserCreated2 = category.userCreated;
                break;
            }
        }
    }


    openBackModal() {
        const config = new TemplateModalConfig<string, string, string>(this.confirmBackModal);
        config.mustScroll = true;
        config.size = 'small';
        this.modalService.open(config);
    }

    
}