import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs/Rx";
import {EndpointService} from './endpoint.service';

@Injectable()
export class CountryService {

  constructor(private http: HttpClient) {
  }

  getAllCountries(): Observable<any> {
    return this.http.get(EndpointService.countryUrl);
  }

  getStatesByCountry(countryId: string): Observable<any> {
    return this.http.get(EndpointService.stateUrl + `?country=${countryId}`);
  }

  getCitiesByState(stateId: string): Observable<any> {
    return this.http.get(EndpointService.cityUrl + `?state=${stateId}`);
  }

}
