import { Component, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "../services/login.service";
import { codeValidator } from "../helpers/regex-data";
import { NotificationService } from "../services/notification.service";
import { NotifyParentService } from "../services/notify-parent.service";
import { MixpanelService } from "../services/mixpanel.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss", "../styles.common.scss"],
  providers: [LoginService]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  codeForm: FormGroup;
  tfa = null;
  codeTry = "";

  get loggedIn() {
    return this.authService.loggedIn();
  }

  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private notifyParent: NotifyParentService,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.tfa = this.authService.checkTokenAccess("PRE_AUTH") || this.authService.checkTokenAccess("ADMIN_PRE_AUTH");
    this.loginForm = this.formBuilder.group({
      loginUsername: ["", [Validators.required]],
      loginPassword: ["", [Validators.required]],
      rememberMe: [false]
    });
    this.codeForm = this.formBuilder.group({
      code: ["", [Validators.required, codeValidator]]
    });
  }

  login() {
    this.mixpanelService.track("login", { username: this.loginForm.controls["loginUsername"].value });
    this.loginService
      .login(this.loginForm.controls["loginUsername"].value, this.loginForm.controls["loginPassword"].value)
      .then((response) => {
        this.loginService.setTokens(this.loginForm.controls["rememberMe"].value, response["access_token"], response["refresh_token"]);
        this.tfa = this.authService.checkTokenAccess("PRE_AUTH") || this.authService.checkTokenAccess("ADMIN_PRE_AUTH");
        this.mixpanelService.track("login_success", { username: this.loginForm.controls["loginUsername"].value });
        if (!this.tfa) {
          this.router.navigate(["/"]);
          this.notifyParent.sendData("fullUpdate");
        }
      })
      .catch((error) => {
        console.log("error", error);

        AuthService.removeOauthTokenFromStorage();
        this.notificationService.error("Username or password is incorrect");
      });
  }

  login2FA() {
    this.loginService
      .login2FA(this.loginForm.controls["loginUsername"].value, this.loginForm.controls["loginPassword"].value, this.codeTry)
      .then((response) => {
        this.loginService.setTokens(this.loginForm.controls["rememberMe"].value, response["access_token"], response["refresh_token"]);
        this.router.navigate(["/"]);
        this.notifyParent.sendData("fullUpdate");
      })
      .catch((error) => this.notificationService.error(error));
  }

  logout() {
    this.mixpanelService.track("logout", {});
    this.authService.logout();
  }
}
